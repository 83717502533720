import { put } from "../put";
import { post } from "../post";
import { promotionApi } from "../urlconfig";
// 推广页问卷调查
export function postPromoteQuestionnaire_api(params: any, path?: string) {
  return post(
    `${path ? path : "/"}${promotionApi}/operation/promote/questionnaire`,
    params
  );
}
// 推广页统计
export function putPromoClick_api(params: any, path?: string) {
  return put(
    `${path ? path : "/"}${promotionApi}/promo/click?promoCode=${
      params.promoCode
    }`,
    params
  );
}
// 落地页统计
export function postPromoVisit_api(params: any, path?: string) {
  return post(
    `${path ? path : "/"}${promotionApi}/operation/promote/visit?promoteId=${
      params.promoteId
    }`,
    params
  );
}
