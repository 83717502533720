export default {
  title: "Privacy Policy",
  time: "Create time: 2021-05-20",
  content: [
    {
      title: {
        "en-US": "I. General",
        "zh-CN": "一、总则",
        "zh-HK": "一、總則",
        "vi-VN": "I. General",
        "fr-FR": "I. General",
      },
      text: {
        "en-US": [
          [
            "This Privacy Policy (“Policy”) refers to {platformName} and you, the user of this site.",
            "●Us, We, Our - {platformName} is the publisher and operator of the web site {origin}, and is referred to as “{host}”. “us”, “we”, “our”, “ours”, etc. refer to {platformName}. “The SITE” or “SITE” refers to {origin}.",
            "●You, the User - This Policy will refer to the user as “you” or “yours”, etc.",
            "This Policy describes how we use information received about you when you visit our SITE or when you subscribe to, or otherwise use our online services. This Policy does not cover any information that we may receive from or about you through channels other than through the use of the SITE.",
            "Users who reside in the EEA are invited to read our GDPR Privacy Notice.",
          ],
          "CHILDRENS ONLINE PRIVACY PROTECTION ACT Compliance and Non-Applicability Statement.",
          "The Children’s Online Privacy Protection Act applies to the online collection of personal information from children under the age of 13. {host} does not collect any personal information about children under the age of 13. Our SITE is not designed for children under 16, and children under the age of 16 are not authorized to access or use our services. If we discover that a child under the age of 16 has provided us with personal information, we will endeavor to purge any such information as soon as possible.",
        ],
        "zh-CN": [
          [
            "本隐私政策（“政策”）适用于 {platformName}和您，即本网站的用户。",
            "●我们，我们，我们的- {platformName}是网站{origin}的发布者和运营商， 简称为“{host}”。“我们”、“我们”、“我们的”、“我们的”等是指 {platformName}。“站点”或“站点”是指 {origin}。",
            "●您，用户 - 本政策将用户称为“您”或“您的”等。",
            "本政策描述了我们如何使用在您访问我们的网站或订阅或以其他方式使用我们的在线服务时收到的有关您的信息。本政策不涵盖我们可能通过使用本网站以外的其他渠道从您那里收到或关于您的任何信息。",
            "我们邀请居住在 EEA 的用户阅读我们的GDPR 隐私声明。",
          ],
          "儿童在线隐私保护法案合规性和非适用性声明。",
          "《儿童在线隐私保护法》适用于在线收集13岁以下儿童的个人信息。{host}不收集有关13岁以下儿童的任何个人信息。我们的网站不是为16岁以下儿童设计的，并且16岁以下的儿童无权访问或使用我们的服务。如果我们发现16岁以下的儿童向我们提供了个人信息，我们将努力尽快清除任何此类信息。",
        ],
        "zh-HK": [
          [
            "本隱私政策（“政策”）適用於 {platformName}和您，即本網站的用戶。",
            "●我們，我們，我們的- {platformName}是網站{origin}的發布者和運營商， 簡稱為“{host}”。“我們”、“我們”、“我們的”、“我們的”等是指 {platformName}。“站點”或“站點”是指 {origin}。",
            "●您，用戶 - 本政策將用戶稱為“您”或“您的”等。",
            "本政策描述了我們如何使用在您訪問我們的網站或訂閱或以其他方式使用我們的在線服務時收到的有關您的信息。本政策不涵蓋我們可能通過使用本網站以外的其他渠道從您那裡收到或關於您的任何信息。",
            "我們邀請居住在 EEA 的用戶閱讀我們的GDPR 隱私聲明。",
          ],
          "兒童在線隱私保護法案合規性和非適用性聲明。",
          "《兒童在線隱私保護法》適用於在線收集 13 歲以下兒童的個人信息。{host} 不收集有關 13 歲以下兒童的任何個人信息。我們的網站不是為 16 歲以下兒童設計的，並且16 歲以下的兒童無權訪問或使用我們的服務。如果我們發現 16 歲以下的兒童向我們提供了個人信息，我們將努力盡快清除任何此類信息。",
        ],
        "vi-VN": [
          [
            "This Privacy Policy (“Policy”) refers to {platformName} and you, the user of this site.",
            "●Us, We, Our - {platformName} is the publisher and operator of the web site {origin}, and is referred to as “{host}”. “us”, “we”, “our”, “ours”, etc. refer to {platformName}. “The SITE” or “SITE” refers to {origin}.",
            "●You, the User - This Policy will refer to the user as “you” or “yours”, etc.",
            "This Policy describes how we use information received about you when you visit our SITE or when you subscribe to, or otherwise use our online services. This Policy does not cover any information that we may receive from or about you through channels other than through the use of the SITE.",
            "Users who reside in the EEA are invited to read our GDPR Privacy Notice.",
          ],
          "CHILDRENS ONLINE PRIVACY PROTECTION ACT Compliance and Non-Applicability Statement.",
          "The Children’s Online Privacy Protection Act applies to the online collection of personal information from children under the age of 13. {host} does not collect any personal information about children under the age of 13. Our SITE is not designed for children under 16, and children under the age of 16 are not authorized to access or use our services. If we discover that a child under the age of 16 has provided us with personal information, we will endeavor to purge any such information as soon as possible.",
        ],
        "fr-FR": [
          [
            "This Privacy Policy (“Policy”) refers to {platformName} and you, the user of this site.",
            "●Us, We, Our - {platformName} is the publisher and operator of the web site {origin}, and is referred to as “{host}”. “us”, “we”, “our”, “ours”, etc. refer to {platformName}. “The SITE” or “SITE” refers to {origin}.",
            "●You, the User - This Policy will refer to the user as “you” or “yours”, etc.",
            "This Policy describes how we use information received about you when you visit our SITE or when you subscribe to, or otherwise use our online services. This Policy does not cover any information that we may receive from or about you through channels other than through the use of the SITE.",
            "Users who reside in the EEA are invited to read our GDPR Privacy Notice.",
          ],
          "CHILDRENS ONLINE PRIVACY PROTECTION ACT Compliance and Non-Applicability Statement.",
          "The Children’s Online Privacy Protection Act applies to the online collection of personal information from children under the age of 13. {host} does not collect any personal information about children under the age of 13. Our SITE is not designed for children under 16, and children under the age of 16 are not authorized to access or use our services. If we discover that a child under the age of 16 has provided us with personal information, we will endeavor to purge any such information as soon as possible.",
        ],
      },
    },
    {
      title: {
        "en-US": "II. Revisions to this Policy",
        "zh-CN": "二、本政策的修订",
        "zh-HK": "二、本政策的修訂",
        "vi-VN": "II. Revisions to this Policy",
        "fr-FR": "II. Revisions to this Policy",
      },
      text: {
        "en-US": [
          "This Policy is current as of the Last Modified set forth above. We reserve the right to revise, amend, or modify this Policy and our other policies and agreements at any time and in any manner. We will post any updates to this Policy on our SITE. You should periodically check for any modifications of this Policy by re-visiting this web page. If we make any changes to this Policy that materially affect our practices with regard to the personal information we have previously collected from you, we will endeavor to provide you with notice in advance of such change by highlighting the change on our SITE.",
        ],
        "zh-CN": [
          "本政策自上述最后修改时生效。我们保留随时以任何方式修订、修正或修改本政策以及我们的其他政策和协议的权利。我们将在我们的网站上发布对本政策的任何更新。您应该通过重新访问此网页来定期检查对本政策的任何修改。如果我们对本政策做出任何更改，从而对我们之前从您那里收集的个人信息的做法产生重大影响，我们将努力通过在我们的网站上突出显示更改来提前通知您此类更改。",
        ],
        "zh-HK": [
          "本政策自上述最後修改時生效。我們保留隨時以任何方式修訂、修正或修改本政策以及我們的其他政策和協議的權利。我們將在我們的網站上發布對本政策的任何更新。您應該通過重新訪問此網頁來定期檢查對本政策的任何修改。如果我們對本政策做出任何更改，從而對我們之前從您那裡收集的個人信息的做法產生重大影響，我們將努力通過在我們的網站上突出顯示更改來提前通知您此類更改。",
        ],
        "vi-VN": [
          "This Policy is current as of the Last Modified set forth above. We reserve the right to revise, amend, or modify this Policy and our other policies and agreements at any time and in any manner. We will post any updates to this Policy on our SITE. You should periodically check for any modifications of this Policy by re-visiting this web page. If we make any changes to this Policy that materially affect our practices with regard to the personal information we have previously collected from you, we will endeavor to provide you with notice in advance of such change by highlighting the change on our SITE.",
        ],
        "fr-FR": [
          "This Policy is current as of the Last Modified set forth above. We reserve the right to revise, amend, or modify this Policy and our other policies and agreements at any time and in any manner. We will post any updates to this Policy on our SITE. You should periodically check for any modifications of this Policy by re-visiting this web page. If we make any changes to this Policy that materially affect our practices with regard to the personal information we have previously collected from you, we will endeavor to provide you with notice in advance of such change by highlighting the change on our SITE.",
        ],
      },
    },
    {
      title: {
        "en-US": "III. Information that we collect",
        "zh-CN": "三、我们收集的信息",
        "zh-HK": "三、我們收集的信息",
        "vi-VN": "III. Information that we collect",
        "fr-FR": "III. Information that we collect",
      },
      text: {
        "en-US": [
          "Visitors to our SITE and services will have multiple manners of interacting with us. Depending upon your level of interaction, we will collect different information about you. We collect information about you directly from you and from third parties, as well as automatically through your use of our SITE. The type of information collected is as follows:",
          [
            "Members - Members are those users who subscribe to our services will be prompted to provide their name, their address, their age, their phone number, and any other necessary billing information.",
            "Posters - Posters are visitors to our SITE who choose to register to post information in our public forums. We collect your email address and the password you create for your account when you register, and we also collect information about your posts to our forums. Any information entered voluntarily by the Poster on one of our forums should be considered public information or information that is publicly available. If you do not wish for information to be made public, you should not enter it into your user profile when registering as a Poster.",
            "All SITE users and visitors - We automatically collect the following information from all users and visitors to our SITE through cookies, web beacons, and other technologies: your domain name; your browser type and operating system; web pages you view; links you click; your IP address; the length of time you visit or use our SITE; and the referring URL, or the webpage that led you to our SITE. We may combine this information with other information that we have collected about you, including, where applicable, your user name, name, and other personal information. Please see the section “Cookies and Other Tracking Mechanisms” below for more information.",
          ],
        ],
        "zh-CN": [
          "我们网站和服务的访问者将有多种方式与我们互动。根据您的互动程度，我们将收集有关您的不同信息。我们直接从您和第三方收集有关您的信息，以及通过您使用我们的网站自动收集有关您的信息。收集的信息类型如下：",
          [
            "会员- 会员是订阅我们服务的用户，系统会提示他们提供姓名、地址、年龄、电话号码和任何其他必要的账单信息。",
            "海报- 海报是我们网站的访问者，他们选择注册以在我们的公共论坛上发布信息。我们会收集您在注册时为帐户创建的电子邮件地址和密码，我们还会收集有关您在我们论坛上发帖的信息。海报在我们的论坛之一上自愿输入的任何信息都应被视为公共信息或公开可用的信息。如果您不希望公开信息，则在注册为海报时不应将其输入到您的用户配置文件中。",
            "所有站点用户和访问者- 我们通过 cookie、网络信标和其他技术自动从我们站点的所有用户和访问者那里收集以下信息：您的域名；您的浏览器类型和操作系统；您查看的网页；您点击的链接；您的 IP 地址；您访问或使用我们网站的时间长度；以及引荐 URL 或将您引导至我们网站的网页。我们可能会将这些信息与我们收集到的有关您的其他信息结合起来，包括（如适用）您的用户名、姓名和其他个人信息。请参阅下面的“Cookie 和其他跟踪机制”部分了解更多信息。",
          ],
        ],
        "zh-HK": [
          "我們網站和服務的訪問者將有多種方式與我們互動。根據您的互動程度，我們將收集有關您的不同信息。我們直接從您和第三方收集有關您的信息，以及通過您使用我們的網站自動收集信息。收集的信息類型如下：",
          [
            "會員- 會員是訂閱我們服務的用戶，系統會提示他們提供姓名、地址、年齡、電話號碼和任何其他必要的賬單信息。",
            "海報- 海報是我們網站的訪問者，他們選擇註冊以在我們的公共論壇上發布信息。我們會收集您在註冊時為帳戶創建的電子郵件地址和密碼，我們還會收集有關您在我們論壇上發帖的信息。海報在我們的論壇之一上自願輸入的任何信息都應被視為公共信息或公開可用的信息。如果您不希望公開信息，則在註冊為海報時不應將其輸入到您的用戶配置文件中。",
            "所有站點用戶和訪問者- 我們通過 cookie、網絡信標和其他技術自動從我們站點的所有用戶和訪問者那裡收集以下信息：您的域名；您的瀏覽器類型和操作系統；您查看的網頁；您點擊的鏈接；您的 IP 地址；您訪問或使用我們網站的時間長度；以及引薦 URL 或將您引導至我們網站的網頁。我們可能會將這些信息與我們收集到的有關您的其他信息結合起來，包括（如適用）您的用戶名、姓名和其他個人信息。請參閱下面的“Cookie 和其他跟踪機制”部分了解更多信息。",
          ],
        ],
        "vi-VN": [
          "Visitors to our SITE and services will have multiple manners of interacting with us. Depending upon your level of interaction, we will collect different information about you. We collect information about you directly from you and from third parties, as well as automatically through your use of our SITE. The type of information collected is as follows:",
          [
            "Members - Members are those users who subscribe to our services will be prompted to provide their name, their address, their age, their phone number, and any other necessary billing information.",
            "Posters - Posters are visitors to our SITE who choose to register to post information in our public forums. We collect your email address and the password you create for your account when you register, and we also collect information about your posts to our forums. Any information entered voluntarily by the Poster on one of our forums should be considered public information or information that is publicly available. If you do not wish for information to be made public, you should not enter it into your user profile when registering as a Poster.",
            "All SITE users and visitors - We automatically collect the following information from all users and visitors to our SITE through cookies, web beacons, and other technologies: your domain name; your browser type and operating system; web pages you view; links you click; your IP address; the length of time you visit or use our SITE; and the referring URL, or the webpage that led you to our SITE. We may combine this information with other information that we have collected about you, including, where applicable, your user name, name, and other personal information. Please see the section “Cookies and Other Tracking Mechanisms” below for more information.",
          ],
        ],
        "fr-FR": [
          "Visitors to our SITE and services will have multiple manners of interacting with us. Depending upon your level of interaction, we will collect different information about you. We collect information about you directly from you and from third parties, as well as automatically through your use of our SITE. The type of information collected is as follows:",
          [
            "Members - Members are those users who subscribe to our services will be prompted to provide their name, their address, their age, their phone number, and any other necessary billing information.",
            "Posters - Posters are visitors to our SITE who choose to register to post information in our public forums. We collect your email address and the password you create for your account when you register, and we also collect information about your posts to our forums. Any information entered voluntarily by the Poster on one of our forums should be considered public information or information that is publicly available. If you do not wish for information to be made public, you should not enter it into your user profile when registering as a Poster.",
            "All SITE users and visitors - We automatically collect the following information from all users and visitors to our SITE through cookies, web beacons, and other technologies: your domain name; your browser type and operating system; web pages you view; links you click; your IP address; the length of time you visit or use our SITE; and the referring URL, or the webpage that led you to our SITE. We may combine this information with other information that we have collected about you, including, where applicable, your user name, name, and other personal information. Please see the section “Cookies and Other Tracking Mechanisms” below for more information.",
          ],
        ],
      },
    },
    {
      title: {
        "en-US": "IV. Our Use of Cookies and Other Tracking Mechanisms",
        "zh-CN": "四、我们对 Cookie 和其他跟踪机制的使用",
        "zh-HK": "四、我們對 Cookie 和其他跟踪機制的使用",
        "vi-VN": "IV. Our Use of Cookies and Other Tracking Mechanisms",
        "fr-FR": "IV. Our Use of Cookies and Other Tracking Mechanisms",
      },
      text: {
        "en-US": [
          "We and our third party service providers use cookies and other tracking mechanisms, including those designed for mobile applications, to track information about your use of our SITE. We may combine this information with other personal information we collect from you (and our third party service providers may do so on our behalf).",
          [
            "Cookies. Cookies are alphanumeric identifiers that we transfer to your device’s hard drive through your web browser for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our SITE, while others are used to enable a faster log-in process or to allow us to track your activities at our SITE and service, including for online advertising purposes.",
            "Disabling Cookies. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Visitors to our SITE who disable cookies will be able to browse certain areas of the SITE, but some features may not function.",
            "Clear GIFs, pixel tags and other technologies. Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your device’s hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in connection with our SITE to, among other things, track the activities of SITE visitors and users, help us manage content, and compile statistics about SITE usage. We and our third party service providers also use clear GIFs in HTML emails to our customers, to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.",
            "Third Party Analytics. We use automated devices and applications, such as Google Analytics, to evaluate usage of our SITE. We also may use other analytic means to evaluate our SITE. We use these tools to help us improve our services, performance, and user experiences. These entities may use cookies and other tracking technologies to perform their services. To learn more about Google’s privacy practices, please review the Google Policy at https://www.google.com/policies/privacy/. You can also download the Google Analytics Opt-out Browser Add-on to prevent their data from being used by Google Analytics at https://tools.google.com/dlpage/gaoptout.",
            "Do-Not-Track. Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed in this section (e.g., by disabling cookies); you also may opt-out of targeted advertising by following the instructions in the Third Party Ad Network section.",
            "Third-Party Ad Networks. We use third parties such as network advertisers to serve advertisements on third-party websites or other media (e.g., social networking platforms). This enables us and these third parties to target advertisements to you for products and services in which you might be interested. Third-party ad network providers, advertisers, sponsors and/or traffic measurement services may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you. These third-party cookies and other technologies are governed by each third party’s specific privacy policy, not this one. We may provide these third-party advertisers with information, including personal information, about you.",
            "Users in the United States may opt out of many third-party ad networks. For example, you may go to the Digital Advertising Alliance (“DAA”) Consumer Choice Page for information about opting out of interest-based advertising and their choices regarding having information used by DAA companies. You may also go to the Network Advertising Initiative (“NAI”) Consumer Opt-Out Page for information about opting out of interest-based advertising and their choices regarding having information used by NAI members.",
            "Opting out from one or more companies listed on the DAA Consumer Choice Page or the NAI Consumer Opt-Out Page will opt you out from those companies’ delivery of interest-based content or ads to you, but it does not mean you will no longer receive any advertising through our SITE or on other websites. You may continue to receive advertisements, for example, based on the particular website that you are viewing (i.e., contextually based ads). Also, if your browsers are configured to reject cookies when you opt out on the DAA or NAI websites, your opt out may not be effective. Additional information is available on the DAA’s website at www.aboutads.info or the NAI’s website at www.networkadvertising.org.",
          ],
        ],
        "zh-CN": [
          "我们和我们的第三方服务提供商使用 cookie 和其他跟踪机制（包括为移动应用程序设计的机制）来跟踪有关您使用我们网站的信息。我们可能会将这些信息与我们从您那里收集的其他个人信息结合起来（我们的第三方服务提供商可能会代表我们这样做）。",
          [
            "Cookie 是字母数字标识符，我们通过您的网络浏览器将其传输到您设备的硬盘上，用于记录保存。一些 cookie 使我们能够让您更轻松地浏览我们的站点，而其他 cookie 用于实现更快的登录过程或允许我们跟踪您在我们的站点和服务中的活动，包括用于在线广告目的。",
            "禁用 Cookie。大多数网络浏览器会自动接受 cookie，但如果您愿意，您可以编辑浏览器选项以在将来阻止它们。大多数浏览器工具栏的“帮助”部分会告诉您如何防止计算机接受新的 cookie、如何在收到新 cookie 时让浏览器通知您，或者如何完全禁用 cookie。禁用 cookie 的我们站点的访问者将能够浏览站点的某些区域，但某些功能可能无法运行。",
            "清晰的 GIF、像素标签和其他技术。清晰的 GIF 是具有唯一标识符的微小图形，其功能类似于 cookie。与存储在设备硬盘驱动器上的 cookie 不同，清晰的 GIF 嵌入在网页上不可见。我们可能会使用清晰的 GIF（又名网络信标、网络错误或像素标签）与我们的站点相关联，以跟踪站点访问者和用户的活动，帮助我们管理内容，并编制有关站点使用情况的统计数据。我们和我们的第三方服务提供商还在发送给我们客户的 HTML 电子邮件中使用清晰的 GIF，以帮助我们跟踪电子邮件回复率、识别我们的电子邮件何时被查看以及跟踪我们的电子邮件是否被转发。",
            "第三方分析。我们使用自动化设备和应用程序（例如 Google Analytics）来评估我们网站的使用情况。我们也可能使用其他分析方法来评估我们的站点。我们使用这些工具来帮助我们改进我们的服务、性能和用户体验。这些实体可能会使用 cookie 和其他跟踪技术来执行其服务。要了解有关 Google 隐私惯例的更多信息，请查看https://www.google.com/policies/privacy/ 上的 Google 政策 。您还可以在https://tools.google.com/dlpage/gaoptout下载 Google Analytics Opt-out Browser Add-on 以防止 Google Analytics 使用其数据。",
            "不跟踪。目前，我们的系统无法识别浏览器“请勿跟踪”请求。但是，您可以禁用本节中讨论的某些跟踪（例如，通过禁用 cookie）；您也可以按照第三方广告网络部分中的说明选择退出定向广告。",
            "第三方广告网络。我们使用第三方（例如网络广告商）在第三方网站或其他媒体（例如社交网络平台）上投放广告。这使我们和这些第三方能够针对您可能感兴趣的产品和服务向您投放广告。第三方广告网络提供商、广告商、赞助商和/或流量测量服务可能会使用 cookie、JavaScript、网络信标（包括清晰的 GIF）、Flash LSO 和其他跟踪技术来衡量其广告的有效性并为您提供个性化的广告内容. 这些第三方 cookie 和其他技术受每个第三方的特定隐私政策管辖，而不是本政策。我们可能会向这些第三方广告商提供有关您的信息，包括个人信息。",
            "美国的用户可能会选择退出许多第三方广告网络。例如，您可以访问数字广告联盟 (“DAA”)消费者选择页面，了解有关选择退出基于兴趣的广告以及他们选择让DAA 公司使用信息的信息。您还可以访问网络广告促进会 (“NAI”)消费者选择退出页面，了解有关选择退出基于兴趣的广告以及他们选择让NAI 成员使用信息的信息。",
            "选择退出 DAA消费者选择页面或 NAI消费者选择退出页面上列出的一家或多家公司将使您退出这些公司向您提供基于兴趣的内容或广告，但这并不意味着您将不再通过我们的网站或其他网站接收任何广告。您可能会继续收到广告，例如，基于您正在查看的特定网站（即基于上下文的广告）。此外，如果您的浏览器配置为在您选择退出 DAA 或 NAI 网站时拒绝 cookie，则您的选择退出可能无效。更多信息可在 DAA 的网站www.aboutads.info或 NAI 的网站www.networkadvertising.org 上获得。",
          ],
        ],
        "zh-HK": [
          "我們和我們的第三方服務提供商使用 cookie 和其他跟踪機制（包括為移動應用程序設計的機制）來跟踪有關您使用我們網站的信息。我們可能會將這些信息與我們從您那裡收集的其他個人信息結合起來（我們的第三方服務提供商可能會代表我們這樣做）。",
          [
            "Cookie 是字母數字標識符，我們通過您的網絡瀏覽器將其傳輸到您設備的硬盤上，用於記錄保存。一些 cookie 使我們能夠讓您更輕鬆地瀏覽我們的站點，而其他 cookie 用於實現更快的登錄過程或允許我們跟踪您在我們的站點和服務中的活動，包括用於在線廣告目的。",
            "禁用 Cookie。大多數網絡瀏覽器會自動接受 cookie，但如果您願意，您可以編輯瀏覽器選項以在將來阻止它們。大多數瀏覽器工具欄的“幫助”部分會告訴您如何防止計算機接受新的 cookie、如何在收到新 cookie 時讓瀏覽器通知您，或者如何完全禁用 cookie。禁用 cookie 的我們站點的訪問者將能夠瀏覽站點的某些區域，但某些功能可能無法運行。",
            "清晰的 GIF、像素標籤和其他技術。清晰的 GIF 是具有唯一標識符的微小圖形，其功能類似於 cookie。與存儲在設備硬盤驅動器上的 cookie 不同，清晰的 GIF 嵌入在網頁上不可見。我們可能會使用清晰的 GIF（又名網絡信標、網絡錯誤或像素標籤）與我們的站點相關聯，以跟踪站點訪問者和用戶的活動，幫助我們管理內容，並編制有關站點使用情況的統計數據。我們和我們的第三方服務提供商還在發送給我們客戶的 HTML 電子郵件中使用清晰的 GIF，以幫助我們跟踪電子郵件回复率、識別我們的電子郵件何時被查看以及跟踪我們的電子郵件是否被轉發。",
            "第三方分析。我們使用自動化設備和應用程序（例如 Google Analytics）來評估我們網站的使用情況。我們也可能使用其他分析方法來評估我們的站點。我們使用這些工具來幫助我們改進我們的服務、性能和用戶體驗。這些實體可能會使用 cookie 和其他跟踪技術來執行其服務。要了解有關 Google 隱私慣例的更多信息，請查看https://www.google.com/policies/privacy/ 上的 Google 政策 。您還可以在https://tools.google.com/dlpage/gaoptout下載 Google Analytics Opt-out Browser Add-on 以防止 Google Analytics 使用其數據。",
            "不跟踪。目前，我們的系統無法識別瀏覽器的“請勿跟踪”請求。但是，您可以禁用本節中討論的某些跟踪（例如，通過禁用 cookie）；您也可以按照第三方廣告網絡部分中的說明選擇退出定向廣告。",
            "第三方廣告網絡。我們使用第三方（例如網絡廣告商）在第三方網站或其他媒體（例如社交網絡平台）上投放廣告。這使我們和這些第三方能夠針對您可能感興趣的產品和服務向您投放廣告。第三方廣告網絡提供商、廣告商、贊助商和/或流量測量服務可能會使用 cookie、JavaScript、網絡信標（包括清晰的 GIF）、Flash LSO 和其他跟踪技術來衡量其廣告的有效性並為您提供個性化的廣告內容. 這些第三方 cookie 和其他技術受每個第三方的特定隱私政策管轄，而不是本政策。我們可能會向這些第三方廣告商提供有關您的信息，包括個人信息。",
            "美國的用戶可能會選擇退出許多第三方廣告網絡。例如，您可以訪問數字廣告聯盟 (“DAA”)消費者選擇頁面，了解有關選擇退出基於興趣的廣告以及他們選擇讓DAA 公司使用信息的信息。您還可以訪問網絡廣告促進會 (“NAI”)消費者選擇退出頁面，了解有關選擇退出基於興趣的廣告以及他們選擇讓NAI 成員使用信息的信息。",
            "選擇退出 DAA消費者選擇頁面或 NAI消費者選擇退出頁面上列出的一家或多家公司將使您退出這些公司向您提供基於興趣的內容或廣告，但這並不意味著您將不再通過我們的網站或其他網站接收任何廣告。您可能會繼續收到廣告，例如，基於您正在查看的特定網站（即基於上下文的廣告）。此外，如果您的瀏覽器配置為在您選擇退出 DAA 或 NAI 網站時拒絕 cookie，則您的選擇退出可能無效。更多信息可在 DAA 網站www.aboutads.info或 NAI 網站www.networkadvertising.org 上獲得。",
          ],
        ],
        "vi-VN": [
          "We and our third party service providers use cookies and other tracking mechanisms, including those designed for mobile applications, to track information about your use of our SITE. We may combine this information with other personal information we collect from you (and our third party service providers may do so on our behalf).",
          [
            "Cookies. Cookies are alphanumeric identifiers that we transfer to your device’s hard drive through your web browser for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our SITE, while others are used to enable a faster log-in process or to allow us to track your activities at our SITE and service, including for online advertising purposes.",
            "Disabling Cookies. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Visitors to our SITE who disable cookies will be able to browse certain areas of the SITE, but some features may not function.",
            "Clear GIFs, pixel tags and other technologies. Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your device’s hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in connection with our SITE to, among other things, track the activities of SITE visitors and users, help us manage content, and compile statistics about SITE usage. We and our third party service providers also use clear GIFs in HTML emails to our customers, to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.",
            "Third Party Analytics. We use automated devices and applications, such as Google Analytics, to evaluate usage of our SITE. We also may use other analytic means to evaluate our SITE. We use these tools to help us improve our services, performance, and user experiences. These entities may use cookies and other tracking technologies to perform their services. To learn more about Google’s privacy practices, please review the Google Policy at https://www.google.com/policies/privacy/. You can also download the Google Analytics Opt-out Browser Add-on to prevent their data from being used by Google Analytics at https://tools.google.com/dlpage/gaoptout.",
            "Do-Not-Track. Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed in this section (e.g., by disabling cookies); you also may opt-out of targeted advertising by following the instructions in the Third Party Ad Network section.",
            "Third-Party Ad Networks. We use third parties such as network advertisers to serve advertisements on third-party websites or other media (e.g., social networking platforms). This enables us and these third parties to target advertisements to you for products and services in which you might be interested. Third-party ad network providers, advertisers, sponsors and/or traffic measurement services may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you. These third-party cookies and other technologies are governed by each third party’s specific privacy policy, not this one. We may provide these third-party advertisers with information, including personal information, about you.",
            "Users in the United States may opt out of many third-party ad networks. For example, you may go to the Digital Advertising Alliance (“DAA”) Consumer Choice Page for information about opting out of interest-based advertising and their choices regarding having information used by DAA companies. You may also go to the Network Advertising Initiative (“NAI”) Consumer Opt-Out Page for information about opting out of interest-based advertising and their choices regarding having information used by NAI members.",
            "Opting out from one or more companies listed on the DAA Consumer Choice Page or the NAI Consumer Opt-Out Page will opt you out from those companies’ delivery of interest-based content or ads to you, but it does not mean you will no longer receive any advertising through our SITE or on other websites. You may continue to receive advertisements, for example, based on the particular website that you are viewing (i.e., contextually based ads). Also, if your browsers are configured to reject cookies when you opt out on the DAA or NAI websites, your opt out may not be effective. Additional information is available on the DAA’s website at www.aboutads.info or the NAI’s website at www.networkadvertising.org.",
          ],
        ],
        "fr-FR": [
          "We and our third party service providers use cookies and other tracking mechanisms, including those designed for mobile applications, to track information about your use of our SITE. We may combine this information with other personal information we collect from you (and our third party service providers may do so on our behalf).",
          [
            "Cookies. Cookies are alphanumeric identifiers that we transfer to your device’s hard drive through your web browser for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our SITE, while others are used to enable a faster log-in process or to allow us to track your activities at our SITE and service, including for online advertising purposes.",
            "Disabling Cookies. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Visitors to our SITE who disable cookies will be able to browse certain areas of the SITE, but some features may not function.",
            "Clear GIFs, pixel tags and other technologies. Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your device’s hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in connection with our SITE to, among other things, track the activities of SITE visitors and users, help us manage content, and compile statistics about SITE usage. We and our third party service providers also use clear GIFs in HTML emails to our customers, to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.",
            "Third Party Analytics. We use automated devices and applications, such as Google Analytics, to evaluate usage of our SITE. We also may use other analytic means to evaluate our SITE. We use these tools to help us improve our services, performance, and user experiences. These entities may use cookies and other tracking technologies to perform their services. To learn more about Google’s privacy practices, please review the Google Policy at https://www.google.com/policies/privacy/. You can also download the Google Analytics Opt-out Browser Add-on to prevent their data from being used by Google Analytics at https://tools.google.com/dlpage/gaoptout.",
            "Do-Not-Track. Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed in this section (e.g., by disabling cookies); you also may opt-out of targeted advertising by following the instructions in the Third Party Ad Network section.",
            "Third-Party Ad Networks. We use third parties such as network advertisers to serve advertisements on third-party websites or other media (e.g., social networking platforms). This enables us and these third parties to target advertisements to you for products and services in which you might be interested. Third-party ad network providers, advertisers, sponsors and/or traffic measurement services may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you. These third-party cookies and other technologies are governed by each third party’s specific privacy policy, not this one. We may provide these third-party advertisers with information, including personal information, about you.",
            "Users in the United States may opt out of many third-party ad networks. For example, you may go to the Digital Advertising Alliance (“DAA”) Consumer Choice Page for information about opting out of interest-based advertising and their choices regarding having information used by DAA companies. You may also go to the Network Advertising Initiative (“NAI”) Consumer Opt-Out Page for information about opting out of interest-based advertising and their choices regarding having information used by NAI members.",
            "Opting out from one or more companies listed on the DAA Consumer Choice Page or the NAI Consumer Opt-Out Page will opt you out from those companies’ delivery of interest-based content or ads to you, but it does not mean you will no longer receive any advertising through our SITE or on other websites. You may continue to receive advertisements, for example, based on the particular website that you are viewing (i.e., contextually based ads). Also, if your browsers are configured to reject cookies when you opt out on the DAA or NAI websites, your opt out may not be effective. Additional information is available on the DAA’s website at www.aboutads.info or the NAI’s website at www.networkadvertising.org.",
          ],
        ],
      },
    },
    {
      title: {
        "en-US": "V. How we use your information",
        "zh-CN": "五、我们如何使用您的信息",
        "zh-HK": "五、我們如何使用您的信息",
        "vi-VN": "V. How we use your information",
        "fr-FR": "V. How we use your information",
      },
      text: {
        "en-US": [
          "We use your information, including your personal information, for the following purposes:",
          [
            "To provide our services to you, to communicate with you about your use of our services, to respond to your inquiries, to fulfill your orders, and for other customer service purposes.",
            "To tailor the content and information that we may send or display to you, such as displaying information on your use of our cloud services, to offer location customization, and personalized help and instructions, and to otherwise personalize your experiences while using the SITE.",
            "For marketing and promotional purposes. For example, we may use your information, such as your email address, to send you news and newsletters, special offers, and promotions, or to otherwise contact you about products or information we think may interest you. We also may use the information that we learn about you to assist us in advertising our services on third party websites.",
            "To better understand how users access and use our SITE, both on an aggregated and individualized basis, in order to improve our SITE and services and respond to user desires and preferences, and for other research and analytical purposes.",
            "To administer surveys and questionnaires.",
            "To comply with applicable legal or regulatory obligations, including as part of a judicial proceeding; to respond to a subpoena, warrant, court order, or other legal process; or as part of an investigation or request, whether formal or informal, from law enforcement or a governmental authority.",
            "To protect the safety, rights, property, or security of {platformName}, our services, any third party, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop activity that {platformName}, in its sole discretion, may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation; to conduct audits; and to enforce this Policy, our Acceptable Use Policy, and our Terms of Service.",
          ],
        ],
        "zh-CN": [
          "我们将您的信息（包括您的个人信息）用于以下目的：",
          [
            "向您提供我们的服务，就您对我们服务的使用与您沟通，回复您的询问，完成您的订单，以及用于其他客户服务目的。",
            "定制我们可能向您发送或显示的内容和信息，例如显示有关您使用我们的云服务的信息、提供位置定制、个性化帮助和说明，以及以其他方式个性化您在使用网站时的体验。",
            "用于营销和促销目的。例如，我们可能会使用您的信息（例如您的电子邮件地址）向您发送新闻和时事通讯、特别优惠和促销活动，或以其他方式就我们认为您可能感兴趣的产品或信息与您联系。我们还可能使用我们了解的有关您的信息来帮助我们在第三方网站上宣传我们的服务。",
            "为了更好地了解用户如何在汇总和个性化的基础上访问和使用我们的网站，以改进我们的网站和服务并响应用户的愿望和偏好，以及用于其他研究和分析目的。",
            "管理调查和问卷。",
            "遵守适用的法律或监管义务，包括作为司法程序的一部分；回应传票、逮捕令、法院命令或其他法律程序；或作为正式或非正式的调查或请求的一部分，来自执法部门或政府机构。",
            "保护 {platformName}、我们的服务、任何第三方或公众的安全、权利、财产或保障；检测、预防或以其他方式解决欺诈、安全或技术问题；阻止或停止 {platformName} 自行决定可能认为是非法、不道德或可依法采取行动的活动或构成风险的活动；在诉讼中作为证据使用；进行审计；并执行本政策、我们的可接受使用政策和我们的服务条款。",
          ],
        ],
        "zh-HK": [
          "我們將您的信息（包括您的個人信息）用於以下目的：",
          [
            "向您提供我們的服務，就您對我們服務的使用與您溝通，回复您的詢問，完成您的訂單，以及用於其他客戶服務目的。",
            "定制我們可能向您發送或顯示的內容和信息，例如顯示有關您使用我們的雲服務的信息、提供位置定制、個性化幫助和說明，以及以其他方式個性化您在使用站點時的體驗。",
            "用於營銷和促銷目的。例如，我們可能會使用您的信息（例如您的電子郵件地址）向您發送新聞和時事通訊、特別優惠和促銷活動，或以其他方式就我們認為您可能感興趣的產品或信息與您聯繫。我們還可能使用我們了解的有關您的信息來幫助我們在第三方網站上宣傳我們的服務。",
            "為了更好地了解用戶如何在匯總和個性化的基礎上訪問和使用我們的網站，以改進我們的網站和服務並響應用戶的願望和偏好，以及用於其他研究和分析目的。",
            "管理調查和問卷。",
            "遵守適用的法律或監管義務，包括作為司法程序的一部分；回應傳票、逮捕令、法院命令或其他法律程序；或作為正式或非正式的調查或請求的一部分，來自執法部門或政府機構。",
            "保護 {platformName}、我們的服務、任何第三方或公眾的安全、權利、財產或保障；檢測、預防或以其他方式解決欺詐、安全或技術問題；阻止或停止 {platformName} 自行決定可能認為是非法、不道德或可依法採取行動的活動或構成風險的活動；在訴訟中作為證據使用；進行審計；並執行本政策、我們的可接受使用政策和我們的服務條款。",
          ],
        ],
        "vi-VN": [
          "We use your information, including your personal information, for the following purposes:",
          [
            "To provide our services to you, to communicate with you about your use of our services, to respond to your inquiries, to fulfill your orders, and for other customer service purposes.",
            "To tailor the content and information that we may send or display to you, such as displaying information on your use of our cloud services, to offer location customization, and personalized help and instructions, and to otherwise personalize your experiences while using the SITE.",
            "For marketing and promotional purposes. For example, we may use your information, such as your email address, to send you news and newsletters, special offers, and promotions, or to otherwise contact you about products or information we think may interest you. We also may use the information that we learn about you to assist us in advertising our services on third party websites.",
            "To better understand how users access and use our SITE, both on an aggregated and individualized basis, in order to improve our SITE and services and respond to user desires and preferences, and for other research and analytical purposes.",
            "To administer surveys and questionnaires.",
            "To comply with applicable legal or regulatory obligations, including as part of a judicial proceeding; to respond to a subpoena, warrant, court order, or other legal process; or as part of an investigation or request, whether formal or informal, from law enforcement or a governmental authority.",
            "To protect the safety, rights, property, or security of {platformName}, our services, any third party, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop activity that {platformName}, in its sole discretion, may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation; to conduct audits; and to enforce this Policy, our Acceptable Use Policy, and our Terms of Service.",
          ],
        ],
        "fr-FR": [
          "We use your information, including your personal information, for the following purposes:",
          [
            "To provide our services to you, to communicate with you about your use of our services, to respond to your inquiries, to fulfill your orders, and for other customer service purposes.",
            "To tailor the content and information that we may send or display to you, such as displaying information on your use of our cloud services, to offer location customization, and personalized help and instructions, and to otherwise personalize your experiences while using the SITE.",
            "For marketing and promotional purposes. For example, we may use your information, such as your email address, to send you news and newsletters, special offers, and promotions, or to otherwise contact you about products or information we think may interest you. We also may use the information that we learn about you to assist us in advertising our services on third party websites.",
            "To better understand how users access and use our SITE, both on an aggregated and individualized basis, in order to improve our SITE and services and respond to user desires and preferences, and for other research and analytical purposes.",
            "To administer surveys and questionnaires.",
            "To comply with applicable legal or regulatory obligations, including as part of a judicial proceeding; to respond to a subpoena, warrant, court order, or other legal process; or as part of an investigation or request, whether formal or informal, from law enforcement or a governmental authority.",
            "To protect the safety, rights, property, or security of {platformName}, our services, any third party, or the general public; to detect, prevent, or otherwise address fraud, security, or technical issues; to prevent or stop activity that {platformName}, in its sole discretion, may consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity; to use as evidence in litigation; to conduct audits; and to enforce this Policy, our Acceptable Use Policy, and our Terms of Service.",
          ],
        ],
      },
    },
    {
      title: {
        "en-US": "VI. How We Disclose Your Information",
        "zh-CN": "六、我们如何披露您的信息",
        "zh-HK": "六、我們如何披露您的信息",
        "vi-VN": "VI. How We Disclose Your Information",
        "fr-FR": "VI. How We Disclose Your Information",
      },
      text: {
        "en-US": [
          "We may share your information, including personal information, as follows:",
          [
            "Forum Users. If you create an account to use one of our forums, your forum profile, {platformName} directory information, and any information you post on one of our forums will be available to other forum users and all SITE visitors.",
            "Affiliates. We may disclose the information we collect from you to our affiliates or subsidiaries; however, if we do so, their use and disclosure of your personal information will be subject to this Policy.",
            "Service Providers. We may disclose the information we collect from you to third party vendors, digital wallet companies, service providers, contractors or agents who perform functions on our behalf.",
          ],
          "We also disclose information in the following circumstances:",
          [
            "Business Transfers. If we are or may be acquired by or merged with another company, if any of our assets are transferred to another company, or as part of a bankruptcy proceeding, we may transfer the information we have collected from you to the other company.",
            "In Response to Legal Process. We also may disclose the information we collect from you in order to comply with the law, a judicial proceeding, court order, or other legal process, such as in response to a court order or a subpoena.",
            "To Protect Us and Others. We also may disclose the information we collect from you where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms of Service, Acceptable Use Policy, or this Policy, or as evidence in litigation in which {platformName} is involved.",
            "Aggregate and De-Identified Information. We may share aggregate or de-identified information about users with third parties for marketing, advertising, research or similar purposes.",
          ],
        ],
        "zh-CN": [
          "我们可能会共享您的信息，包括个人信息，如下所示：",
          [
            "论坛用户。如果您创建帐户以使用我们的论坛之一，您的论坛资料、{platformName} 目录信息以及您在我们的论坛之一上发布的任何信息都将提供给其他论坛用户和所有网站访问者。",
            "附属公司。我们可能会向我们的附属公司或子公司披露我们从您那里收集的信息；但是，如果我们这样做，他们对您的个人信息的使用和披露将受本政策的约束。",
            "服务供应商。我们可能会向代表我们履行职能的第三方供应商、数字钱包公司、服务提供商、承包商或代理商披露我们从您那里收集的信息。",
          ],
          "我们还会在以下情况下披露信息：",
          [
            "业务转移。如果我们被或可能被另一家公司收购或合并，如果我们的任何资产被转让给另一家公司，或者作为破产程序的一部分，我们可能会将我们从您那里收集到的信息转让给另一家公司。",
            "回应法律程序。我们也可能会披露我们从您那里收集的信息，以遵守法律、司法程序、法院命令或其他法律程序，例如响应法院命令或传票。",
            "保护我们和他人。如果我们认为有必要对非法活动、涉嫌欺诈、涉及对任何人的安全构成潜在威胁的情况、违反我们的服务条款、可接受的情况进行调查、预防或采取行动，我们也可能会披露从您那里收集的信息使用政策或本政策，或作为涉及 {platformName} 的诉讼的证据。",
            "聚合和去标识化信息。我们可能会出于营销、广告、研究或类似目的与第三方共享有关用户的汇总或去标识化信息。",
          ],
        ],
        "zh-HK": [
          "我們可能會共享您的信息，包括個人信息，如下所示：",
          [
            "論壇用戶。如果您創建帳戶以使用我們的論壇之一，您的論壇資料、{platformName} 目錄信息以及您在我們的論壇之一上發布的任何信息都將提供給其他論壇用戶和所有網站訪問者。",
            "附屬公司。我們可能會向我們的附屬公司或子公司披露我們從您那裡收集的信息；但是，如果我們這樣做，他們對您的個人信息的使用和披露將受本政策的約束。",
            "服務供應商。我們可能會向代表我們履行職能的第三方供應商、數字錢包公司、服務提供商、承包商或代理商披露我們從您那裡收集的信息。",
          ],
          "我們還會在以下情況下披露信息：",
          [
            "業務轉移。如果我們被或可能被另一家公司收購或合併，如果我們的任何資產被轉讓給另一家公司，或者作為破產程序的一部分，我們可能會將我們從您那裡收集的信息轉移給另一家公司。",
            "回應法律程序。我們也可能會披露我們從您那裡收集的信息，以遵守法律、司法程序、法院命令或其他法律程序，例如響應法院命令或傳票。",
            "保護我們和他人。如果我們認為有必要對非法活動、涉嫌欺詐、涉及對任何人的安全構成潛在威脅的情況、違反我們的服務條款、可接受的情況進行調查、預防或採取行動，我們也可能會披露從您那裡收集的信息使用政策或本政策，或作為涉及 {platformName} 的訴訟的證據。",
            "聚合和去標識化信息。我們可能會出於營銷、廣告、研究或類似目的與第三方共享有關用戶的匯總或去標識化信息。",
          ],
        ],
        "vi-VN": [
          "We may share your information, including personal information, as follows:",
          [
            "Forum Users. If you create an account to use one of our forums, your forum profile, {platformName} directory information, and any information you post on one of our forums will be available to other forum users and all SITE visitors.",
            "Affiliates. We may disclose the information we collect from you to our affiliates or subsidiaries; however, if we do so, their use and disclosure of your personal information will be subject to this Policy.",
            "Service Providers. We may disclose the information we collect from you to third party vendors, digital wallet companies, service providers, contractors or agents who perform functions on our behalf.",
          ],
          "We also disclose information in the following circumstances:",
          [
            "Business Transfers. If we are or may be acquired by or merged with another company, if any of our assets are transferred to another company, or as part of a bankruptcy proceeding, we may transfer the information we have collected from you to the other company.",
            "In Response to Legal Process. We also may disclose the information we collect from you in order to comply with the law, a judicial proceeding, court order, or other legal process, such as in response to a court order or a subpoena.",
            "To Protect Us and Others. We also may disclose the information we collect from you where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms of Service, Acceptable Use Policy, or this Policy, or as evidence in litigation in which {platformName} is involved.",
            "Aggregate and De-Identified Information. We may share aggregate or de-identified information about users with third parties for marketing, advertising, research or similar purposes.",
          ],
        ],
        "fr-FR": [
          "We may share your information, including personal information, as follows:",
          [
            "Forum Users. If you create an account to use one of our forums, your forum profile, {platformName} directory information, and any information you post on one of our forums will be available to other forum users and all SITE visitors.",
            "Affiliates. We may disclose the information we collect from you to our affiliates or subsidiaries; however, if we do so, their use and disclosure of your personal information will be subject to this Policy.",
            "Service Providers. We may disclose the information we collect from you to third party vendors, digital wallet companies, service providers, contractors or agents who perform functions on our behalf.",
          ],
          "We also disclose information in the following circumstances:",
          [
            "Business Transfers. If we are or may be acquired by or merged with another company, if any of our assets are transferred to another company, or as part of a bankruptcy proceeding, we may transfer the information we have collected from you to the other company.",
            "In Response to Legal Process. We also may disclose the information we collect from you in order to comply with the law, a judicial proceeding, court order, or other legal process, such as in response to a court order or a subpoena.",
            "To Protect Us and Others. We also may disclose the information we collect from you where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms of Service, Acceptable Use Policy, or this Policy, or as evidence in litigation in which {platformName} is involved.",
            "Aggregate and De-Identified Information. We may share aggregate or de-identified information about users with third parties for marketing, advertising, research or similar purposes.",
          ],
        ],
      },
    },
    {
      title: {
        "en-US": "VII. Transmittals from Us",
        "zh-CN": "七、来自我们的传送",
        "zh-HK": "七、來自我們的傳送",
        "vi-VN": "VII. Transmittals from Us",
        "fr-FR": "VII. Transmittals from Us",
      },
      text: {
        "en-US": [
          "We may send you periodic announcements including the details of our existing and new programs. You may opt out of these announcements by contacting support@lightnode.com . If you opt out of these marketing emails, you may still receive system notices and other information that is specifically related to your account.",
        ],
        "zh-CN": [
          "我们可能会定期向您发送公告，包括我们现有和新计划的详细信息。您可以通过联系support@lightnode.com 退出这些公告 。如果您选择退出这些营销电子邮件，您可能仍会收到系统通知和与您的帐户特别相关的其他信息。",
        ],
        "zh-HK": [
          "我們可能會定期向您發送公告，包括我們現有和新計劃的詳細信息。您可以通過聯繫support@lightnode.com 這些公告 。如果您選擇退出這些營銷電子郵件，您可能仍會收到與您的帳戶特別相關的系統通知和其他信息。",
        ],
        "vi-VN": [
          "We may send you periodic announcements including the details of our existing and new programs. You may opt out of these announcements by contacting support@lightnode.com . If you opt out of these marketing emails, you may still receive system notices and other information that is specifically related to your account.",
        ],
        "fr-FR": [
          "We may send you periodic announcements including the details of our existing and new programs. You may opt out of these announcements by contacting support@lightnode.com . If you opt out of these marketing emails, you may still receive system notices and other information that is specifically related to your account.",
        ],
      },
    },
    {
      title: {
        "en-US":
          "VIII. Public Forums, Member Directories, and Internal Messaging:",
        "zh-CN": "八、公共论坛、会员名录和内部消息：",
        "zh-HK": "八、公共論壇、會員名錄和內部消息：",
        "vi-VN":
          "VIII. Public Forums, Member Directories, and Internal Messaging:",
        "fr-FR":
          "VIII. Public Forums, Member Directories, and Internal Messaging:",
      },
      text: {
        "en-US": [
          [
            "Public Forums - Any information posted to a Forum should immediately be considered to be public information.",
            "Member Directories - Any information provided in a user profile, Member directory, or other related service should be considered to be public information. We do not, can not, and will not act to maintain the privacy of any information that you provide in any such forum or medium.",
            "Internal Messaging - We provide an internal messaging system for use between Members of our SITE. Any information that you send via this internal messaging system is not secure and is not considered to be private information. You should use caution when sending any personal information over the internal messaging system, and you should not transmit any information that you would not wish to see disclosed to the general public.",
          ],
        ],
        "zh-CN": [
          [
            "公共论坛- 发布到论坛的任何信息都应立即被视为公共信息。",
            "会员名录- 在用户档案、会员名录或其他相关服务中提供的任何信息都应被视为公开信息。我们不会、不能也不会采取行动维护您在任何此类论坛或媒体中提供的任何信息的隐私。",
            "内部消息传递- 我们提供内部消息传递系统，供我们网站成员之间使用。您通过此内部消息传递系统发送的任何信息都不安全，不被视为私人信息。在通过内部消息系统发送任何个人信息时，您应该谨慎行事，并且您不应传输任何您不希望看到向公众披露的信息。",
          ],
        ],
        "zh-HK": [
          [
            "公共論壇- 發佈到論壇的任何信息都應立即被視為公共信息。",
            "會員名錄- 在用戶檔案、會員名錄或其他相關服務中提供的任何信息都應被視為公開信息。我們不會、不能也不會採取行動維護您在任何此類論壇或媒體中提供的任何信息的隱私。",
            "內部消息傳遞- 我們提供內部消息傳遞系統，供我們網站成員之間使用。您通過此內部消息傳遞系統發送的任何信息都不安全，不被視為私人信息。在通過內部消息系統發送任何個人信息時，您應該謹慎行事，並且您不應傳輸任何您不希望看到向公眾披露的信息。",
          ],
        ],
        "vi-VN": [
          [
            "Public Forums - Any information posted to a Forum should immediately be considered to be public information.",
            "Member Directories - Any information provided in a user profile, Member directory, or other related service should be considered to be public information. We do not, can not, and will not act to maintain the privacy of any information that you provide in any such forum or medium.",
            "Internal Messaging - We provide an internal messaging system for use between Members of our SITE. Any information that you send via this internal messaging system is not secure and is not considered to be private information. You should use caution when sending any personal information over the internal messaging system, and you should not transmit any information that you would not wish to see disclosed to the general public.",
          ],
        ],
        "fr-FR": [
          [
            "Public Forums - Any information posted to a Forum should immediately be considered to be public information.",
            "Member Directories - Any information provided in a user profile, Member directory, or other related service should be considered to be public information. We do not, can not, and will not act to maintain the privacy of any information that you provide in any such forum or medium.",
            "Internal Messaging - We provide an internal messaging system for use between Members of our SITE. Any information that you send via this internal messaging system is not secure and is not considered to be private information. You should use caution when sending any personal information over the internal messaging system, and you should not transmit any information that you would not wish to see disclosed to the general public.",
          ],
        ],
      },
    },
    {
      title: {
        "en-US": "IX. Security of Your Information",
        "zh-CN": "九、您的信息安全",
        "zh-HK": "九、您的信息安全",
        "vi-VN": "IX. Security of Your Information",
        "fr-FR": "IX. Security of Your Information",
      },
      text: {
        "en-US": [
          "We have implemented reasonable precautions to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. For example, we take measures, including data encryption, to protect the transmission of sensitive end-user information. Nevertheless, we cannot guarantee that our security measures will prevent third-party “hackers” from illegally obtaining this information. We take reasonable measures to prevent such breaches of security, but given the resourcefulness of cyber-criminals we are unable to guarantee that our security is 100% breach-proof. You assume the risk of such breaches to the extent that they occur despite our reasonable security measures.",
        ],
        "zh-CN": [
          "我们已采取合理的预防措施来保护我们收集的信息免遭丢失、误用和未经授权的访问、披露、更改和破坏。例如，我们采取措施，包括数据加密，以保护敏感的最终用户信息的传输。尽管如此，我们不能保证我们的安全措施会阻止第三方“黑客”非法获取这些信息。我们采取合理措施来防止此类安全漏洞，但鉴于网络犯罪分子的机智，我们无法保证我们的安全是 100% 防漏洞的。尽管我们采取了合理的安全措施，但您仍承担发生此类违规行为的风险。",
        ],
        "zh-HK": [
          "我們已採取合理的預防措施來保護我們收集的信息免遭丟失、誤用和未經授權的訪問、披露、更改和破壞。例如，我們採取措施，包括數據加密，以保護敏感的最終用戶信息的傳輸。儘管如此，我們不能保證我們的安全措施會阻止第三方“黑客”非法獲取這些信息。我們採取合理措施來防止此類安全漏洞，但鑑於網絡犯罪分子的機智，我們無法保證我們的安全是 100% 防漏洞的。儘管我們採取了合理的安全措施，但您仍承擔發生此類違規行為的風險。",
        ],
        "vi-VN": [
          "We have implemented reasonable precautions to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. For example, we take measures, including data encryption, to protect the transmission of sensitive end-user information. Nevertheless, we cannot guarantee that our security measures will prevent third-party “hackers” from illegally obtaining this information. We take reasonable measures to prevent such breaches of security, but given the resourcefulness of cyber-criminals we are unable to guarantee that our security is 100% breach-proof. You assume the risk of such breaches to the extent that they occur despite our reasonable security measures.",
        ],
        "fr-FR": [
          "We have implemented reasonable precautions to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. For example, we take measures, including data encryption, to protect the transmission of sensitive end-user information. Nevertheless, we cannot guarantee that our security measures will prevent third-party “hackers” from illegally obtaining this information. We take reasonable measures to prevent such breaches of security, but given the resourcefulness of cyber-criminals we are unable to guarantee that our security is 100% breach-proof. You assume the risk of such breaches to the extent that they occur despite our reasonable security measures.",
        ],
      },
    },
    {
      title: {
        "en-US": "X. How to Access or Modify Your Information.",
        "zh-CN": "十、如何访问或修改您的信息",
        "zh-HK": "十、 如何訪問或修改您的信息",
        "vi-VN": "X. How to Access or Modify Your Information.",
        "fr-FR": "X. How to Access or Modify Your Information.",
      },
      text: {
        "en-US": [
          "We offer our Members the opportunity to access or modify information provided during registration. To access or modify such information, contact our Customer Service Department at {origin}/.",
        ],
        "zh-CN": [
          "我们为会员提供访问或修改注册期间提供的信息的机会。要访问或修改此类信息，请通过{origin}/联系我们的客户服务部门。",
        ],
        "zh-HK": [
          "我們為會員提供訪問或修改註冊期間提供的信息的機會。要訪問或修改此類信息，請通過{origin}/聯繫我們的客戶服務部門。",
        ],
        "vi-VN": [
          "We offer our Members the opportunity to access or modify information provided during registration. To access or modify such information, contact our Customer Service Department at {origin}/.",
        ],
        "fr-FR": [
          "We offer our Members the opportunity to access or modify information provided during registration. To access or modify such information, contact our Customer Service Department at {origin}/.",
        ],
      },
    },
    {
      title: {
        "en-US": "XI. Where to Direct Questions About Our Privacy Policy",
        "zh-CN": "十一、关于我们的隐私政策的问题在哪里直接咨询",
        "zh-HK": "十一、關於我們的隱私政策的問題在哪裡直接諮詢",
        "vi-VN": "XI. Where to Direct Questions About Our Privacy Policy",
        "fr-FR": "XI. Where to Direct Questions About Our Privacy Policy",
      },
      text: {
        "en-US": [
          "If you have any questions about this Policy or the practices described herein, you may contact us at {origin}/.",
        ],
        "zh-CN": [
          "如果您对本政策或此处描述的做法有任何疑问，您可以通过{origin}/联系我们。",
        ],
        "zh-HK": [
          "如果您對本政策或此處描述的做法有任何疑問，您可以通過{origin}/聯繫我們。",
        ],
        "vi-VN": [
          "If you have any questions about this Policy or the practices described herein, you may contact us at {origin}/.",
        ],
        "fr-FR": [
          "If you have any questions about this Policy or the practices described herein, you may contact us at {origin}/.",
        ],
      },
    },
  ],
};
