import type { ModalProps } from "antd";
import { Modal, Space, Button } from "antd";
import { useIntl } from "react-intl";
import "./style.less";

type ConfirmModalProps = ModalProps;

const ConfirmModal: React.FC<
  ConfirmModalProps & {
    titleCenter?: boolean;
    footerAlign?: "left" | "right" | "center";
  }
> = ({
  title,
  children,
  footer,
  okButtonProps,
  okText,
  onOk,
  cancelButtonProps,
  cancelText,
  onCancel,
  titleCenter = true,
  footerAlign = "center",
  className,
  ...props
}) => {
  const intl = useIntl();
  return (
    <Modal
      centered
      className={`k-confirm-modal ${className}`}
      {...props}
      onCancel={onCancel}
      title={false}
      footer={false}
    >
      {title && (
        <div
          className="k-confirm-modal-title"
          style={{ textAlign: titleCenter ? "center" : "left" }}
        >
          {title}
        </div>
      )}
      {children}
      <div style={{ textAlign: footerAlign }}>
        {footer || (
          <Space size={10} style={{ paddingTop: 20, paddingBottom: 10 }}>
            <Button
              style={{ minWidth: 100 }}
              {...cancelButtonProps}
              onClick={onCancel}
            >
              <span>
                {cancelText ||
                  intl.formatMessage({ id: "component.modal.cancelText" })}
              </span>
            </Button>
            <Button
              style={{ minWidth: 100 }}
              {...okButtonProps}
              onClick={onOk}
              type="primary"
            >
              <span>
                {okText || intl.formatMessage({ id: "component.modal.okText" })}
              </span>
            </Button>
          </Space>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmModal;
