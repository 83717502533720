import { useRequest } from "ahooks";
import { useState } from "react";
import { getAccount_api } from "@/api/useApi";
// 得到用户信息
const useAccountInfo = () => {
  const [user, setUser] = useState<null | { email: ""; userId: 0 }>(null);

  const { run } = useRequest(getAccount_api, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.success) {
        setUser(res?.data || {});
      } else {
        setUser(null);
      }
    },
  });

  return {
    user,
    getAccount: run,
  };
};
export { useAccountInfo };
