import Link from "next/link";
import { useIntl } from "react-intl";
import Img from "@/components/Img";
import { useState, useEffect } from "react";
import { Drawer } from "antd";
import { fetchRecharge } from "@/api/useApi";
import { useAccountInfo } from "@/hooks/useModel";
import HeadeMenu from "./menu";
import Btn from "./btn";
import SetLocale from "../setLocale";
import SetPartner from "../setPartner";
import Email from "./email";
import MatomoPath from "@/components/MatomoPath";
import "./style.less";

import toolbar from "@/static/images/header-toolbar.png";

interface P {
  activity?: string; //'home' | 'product' | 'aboutUs' | 'news' | 'docs'
}
const Header = () => {
  const intl = useIntl();
  const [active, setActive] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  // const [newId, setNewId] = useState<number>(0);
  const [userEmail, setUserEmail] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const { user, getAccount } = useAccountInfo();
  useEffect(() => {
    getAccount();
    window.addEventListener("scroll", handleScroll, true);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setUserEmail(user?.email || "");
  }, [user]);

  useEffect(() => {
    if (userEmail) {
      fetchRecharge({ status: "SUCCESS" }).then((res: any) => {
        setTotal(res?.total || 0);
      });
    }
  }, [userEmail]);

  const handleScroll = () => {
    const top: number =
      document.body.scrollTop + document.documentElement.scrollTop;
    const left: number =
      document.body.scrollLeft + document.documentElement.scrollLeft;
    const fixedBox: any = document.getElementsByClassName("m-header-fixed");
    const header: any = document.getElementById("header");
    const bannerHeight: number =
      document.getElementById("m-banner")?.clientHeight || 500;
    // 滚动到banner下方显示导航栏
    const a = top > bannerHeight;
    setActive(a);
    // ipad显示端(>768&<1024)，固定导航栏滚动
    if (fixedBox?.length && top > bannerHeight) {
      fixedBox[0].style.left = -left + "px";
      if (document.body.clientWidth < 1024 && document.body.clientWidth > 768) {
        fixedBox[0].style.width = "980px";
      } else {
        if (header) {
          header.style.width = "100%";
        }
      }
    } else {
      if (header) {
        header.style.width = "100%";
      }
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <header
        className={active ? "m-header m-header-fixed" : "m-header"}
        id="header"
      >
        <div className="m-boxModel">
          <div className="header-logo animated bounceInDown">
            <Link href={"/"} title={global.__webInfo?.platformName}>
              <Img
                src={global.__webInfo?.logoUrl}
                alt={intl.formatMessage(
                  { id: "components.header.logo.alt" },
                  { platformName: global.__webInfo?.platformName }
                )}
              />
            </Link>
          </div>
          {/* pc端展示 */}
          <div className="header-right">
            <HeadeMenu />
            <SetPartner isMobile={false} />
            <SetLocale isMobile={false} />
            {userEmail ? (
              <Email
                isMobile={false}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                getAccount={getAccount}
              />
            ) : null}
            <Btn userEmail={userEmail} />
          </div>
          <div className="header-toolbar">
            {userEmail ? <Btn userEmail={userEmail} /> : null}
            <Img
              boxStyle={{ marginLeft: 10 }}
              src={toolbar}
              onClick={showDrawer}
              alt={intl.formatMessage(
                { id: "components.header.logo.alt" },
                { platformName: global.__webInfo?.platformName }
              )}
            />
          </div>
          {/* 手机端 */}
          <Drawer
            title={
              <Img
                src={global.__webInfo?.logoUrl}
                alt={intl.formatMessage(
                  { id: "components.header.toolbar.alt" },
                  { platformName: global.__webInfo?.platformName }
                )}
              />
            }
            placement="left"
            onClose={onClose}
            visible={visible}
            className="m-header-drawer"
            width="84%"
          >
            <HeadeMenu isMobile={true} onClose={onClose} />
            <SetPartner isMobile={true} />
            <SetLocale isMobile={true} />
            {userEmail ? (
              <Email
                isMobile={true}
                userEmail={userEmail}
                getAccount={getAccount}
                setUserEmail={setUserEmail}
                onClose={onClose}
              />
            ) : null}
            <div style={{ width: "100%", marginBottom: "12rem" }}></div>
            {!userEmail ? <Btn userEmail={userEmail} total={total} /> : null}
          </Drawer>
        </div>
      </header>
      <MatomoPath />
    </>
  );
};
export default Header;
