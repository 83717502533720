import footer from "./Footer/en-US.json";
import header from "./Header/en-US.json";
import customer from "./customer/en-US.json";
import support from "./Support/en-US.json";
import bottom from "./Bottom/en-US.json";
import nodeMap from "./NodeMap/en-US.json";
import Accordion from "./Accordion/en-US.json";
export default {
  ...footer,
  ...header,
  ...customer,
  ...support,
  ...bottom,
  ...nodeMap,
  ...Accordion,
};
