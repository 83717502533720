import CryptoJS from "crypto-js";
import { mobilePattern } from "./reg";
interface Itype {
  text: string;
  value: string;
}
//表格枚举显示
export function renderTypeName(
  typeArr: Itype[] = [],
  value = "",
  isShowSpace = false
) {
  if (!typeArr.length || !value) {
    return isShowSpace ? "" : "--";
  }
  const currentArr = typeArr.filter((item) => {
    return item.value === value;
  });
  if (!currentArr.length) {
    return isShowSpace ? "" : "--";
  }
  return currentArr[0].text;
}

export function renderTypeValue(typeArr: any[] = [], text = "") {
  if (!typeArr.length || !text) {
    return "--";
  }
  const currentArr: any = typeArr.filter((item: any) => {
    return item.text === text;
  });
  if (!currentArr.length) {
    return "--";
  }
  return currentArr[0].value;
}

// 判断是否移动端
export function isMobile() {
  const userAgner = window.navigator.userAgent;
  const isMobileOS = mobilePattern.test(userAgner);
  return isMobileOS;
}
// 按照屏幕分辨率判断移动端展示
// export const isMobile = () => {
//   if (typeof window !== "undefined") {
//     if (window.innerWidth <= 768) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// };

// 获取当前时间戳
export const getTimestamp = () =>
  window.parseInt(new Date().getTime() as any, 10);

/**
 * 随机数(盐)
 * */
export function randomString() {
  const len = 32;
  const char = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = char.length;
  let ranString = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    ranString += char.charAt(Math.floor(Math.random() * maxPos));
  }
  return ranString;
}

// 盐值加密
export function cryptSaltPsw(salt: string, pwd: string) {
  const md5_pwd = CryptoJS.algo.MD5.create();
  md5_pwd.update(pwd);
  const md5 = CryptoJS.algo.MD5.create();
  md5.update(salt);
  md5.update(md5_pwd.finalize());
  return md5.finalize().toString();
}
// MD5加密
export function cryptPsw(pwd: string) {
  const md5_pwd = CryptoJS.algo.MD5.create();
  md5_pwd.update(pwd);
  return md5_pwd.finalize().toString();
}
// 横杆-转下划线_
export const toUnderline = (s: string) => {
  return s.replace(/-/g, "_");
};
// 判断是测试环境还是生产环境
export const getProdEnvironment = () => {
  if (typeof location !== "undefined") {
    return location.host.includes("kpy.cloud") ? false : true;
  } else {
    return true;
  }
};
// 得到环境的域名地址
export const getEnvironmentUrl = (
  headers: any = {},
  isSlash: boolean = true
) => {
  const _proto =
    headers?.["x-request-scheme"] ||
    headers?.["x-scheme"] ||
    headers?.["x-forwarded-proto"];
  const _host = headers?.["host"];
  const _isSlash = isSlash ? "/" : "";
  /*
    服务端：
      本地和开发: 
      headers?.["x-forwarded-proto"] = 'http'，没有headers?.["x-request-scheme"]
      生产：
      headers?.["x-forwarded-proto"] = 'http, http'，headers?.["x-request-scheme"] = 'https'
    客户端：
      ctx.req为undefined
  */
  if (_proto) {
    return `${_proto}://${_host}${_isSlash}`;
  } else {
    // 客户端
    return "/";
    // return `http://website.lightnode-dev.kpy.cloud${_isSlash}`;
  }
};
