import footer from "./Footer/vi-VN.json";
import header from "./Header/vi-VN.json";
import customer from "./customer/vi-VN.json";
import support from "./Support/vi-VN.json";
import bottom from "./Bottom/vi-VN.json";
import nodeMap from "./NodeMap/vi-VN.json";
import Accordion from "./Accordion/vi-VN.json";
export default {
  ...footer,
  ...header,
  ...customer,
  ...support,
  ...bottom,
  ...nodeMap,
  ...Accordion,
};
