import component from "./components/fr-FR";
import home from "./home/fr-FR.json";
import aboutUs from "./aboutUs/fr-FR.json";
import termsService from "./termsService/fr-FR.json";
import product from "./Product/fr-FR.json";
import privacyPolicy from "./privacyPolicy/fr-FR.json";
import content from "./content/fr-FR.json";
import contentDetail from "./contentDetail/fr-FR.json";
import advert from "./advert/fr-FR.json";
import advertNew from "./advertNew/fr-FR.json";
import speed from "./speed/fr-FR.json";
import southeastaisa from "./southeastasia/fr-FR.json";
import cocreate from "./cocreate/fr-FR.json";
import contentData1 from "@/models/privacyPolicy/data";
import contentData2 from "@/models/termsService/data";
import { nodeMapData } from "@/utils/nodeData";
const privacyPolicyData: any = {};
const termsServiceData: any = {};
const nodeData: any = {};
contentData1?.content?.forEach((v: any, i: number) => {
  privacyPolicyData[`pages.privacyPolicy.content.title${i + 1}`] =
    v.title?.["fr-FR"];
  v?.text?.["fr-FR"]?.forEach((m: any, j: number) => {
    if (typeof m === "string") {
      privacyPolicyData[`pages.privacyPolicy.content.text${i + 1}-${j + 1}`] =
        m;
    } else {
      m.forEach((n: string, k: number) => {
        privacyPolicyData[
          `pages.privacyPolicy.content.text${i + 1}-${j + 1}-${k + 1}`
        ] = n.indexOf("●") === -1 ? n : n.slice(1, n.length);
      });
    }
  });
});
contentData2?.content?.forEach((v: any, i: number) => {
  termsServiceData[`pages.termsService.content.title${i + 1}`] =
    v.title?.["fr-FR"];
  v?.text?.["fr-FR"]?.forEach((m: any, j: number) => {
    termsServiceData[`pages.termsService.content.text${i + 1}-${j + 1}`] =
      m.text;
  });
});
nodeMapData?.forEach((v: any) => {
  v?.introc?.["fr-FR"]?.forEach((m: any, j: number) => {
    nodeData[`pages.speed.${v.text}.introc${j}`] = m;
  });
});
export default {
  "hello.title": "Bienvenue",
  "hello.message": "Bonjour, le monde !",
  "404.subTitle": "Désolé, le site demandé n'existe pas.",
  "404.Back": "Retour à la page d'accueil",
  "404.BackCocreate": "Retour à Programme de co-création",
  "500.subTitle": "Désolé, quelque chose s'est mal passé.",
  ...component,
  ...home,
  ...aboutUs,
  ...termsService,
  ...product,
  ...privacyPolicy,
  ...content,
  ...contentDetail,
  ...advert,
  ...advertNew,
  ...speed,
  ...southeastaisa,
  ...cocreate,
  ...privacyPolicyData,
  ...termsServiceData,
  ...nodeData,
};
