// 国旗
import country3 from "@/static/images/country/3.svg";
import country9 from "@/static/images/country/9.svg";
// import country10 from '@/static/images/country/10.svg';
import country2 from "@/static/images/country/2.svg";
import country11 from "@/static/images/country/11.svg";
import countryDubai from "@/static/images/country/Dubai.png";
import country1 from "@/static/images/country/1.svg";
import country4 from "@/static/images/country/4.svg";
import country8 from "@/static/images/country/8.svg";
import country6 from "@/static/images/country/6.svg";
import country7 from "@/static/images/country/7.svg";
import country13 from "@/static/images/country/13.svg";
import country16 from "@/static/images/country/16.svg";
import country17 from "@/static/images/country/17.svg";
import country18 from "@/static/images/country/18.svg";
import country19 from "@/static/images/country/19.svg";
import countryTokyo from "@/static/images/country/Tokyo.svg";
import countryCairo from "@/static/images/country/Cairo.png";
import countryBahrain from "@/static/images/country/Bahrain.png";
import countrySofia from "@/static/images/country/Sofia.png";
import countryAthens from "@/static/images/country/Athens.png";
import countryLumpur from "@/static/images/country/Lumpur.png";
import countryLondon from "@/static/images/country/London.png";
import countryMuscat from "@/static/images/country/Muscat.png";
import countryKuwait from "@/static/images/country/Kuwait.png";
import countryMarseille from "@/static/images/country/Marseille.png";
import countryKarachi from "@/static/images/country/Karachi.png";
import countryNepal from "@/static/images/country/Nepal.png";
import countryRussia from "@/static/images/country/Russia.png";
import countryArgentina from "@/static/images/country/Argentina.png";
import countryJakarta from "@/static/images/country/Jakarta.png";
import countryMexico from "@/static/images/country/Mexico.png";
import countryChile from "@/static/images/country/Chile.webp";
import countryMyanmar from "@/static/images/country/Myanmar.png";
import conuntryIraq from "@/static/images/country/Iraq.png";
import conuntryColombia from "@/static/images/country/Colombia.png";

// banner
import Bnagkok from "@/static/images/regionBanner/bangkok.jpg";
import Dhaka from "@/static/images/regionBanner/Dhaka.jpg";
// import DUbai from '@/static/images/regionBanner/DUbai.jpg';
// import frankfurt from '@/static/images/regionBanner/frankfurt.jpg';
import hanoi from "@/static/images/regionBanner/hanoi.jpg";
import hochiminhcity from "@/static/images/regionBanner/hochiminhcity.jpg";
import hongkong from "@/static/images/regionBanner/hongkong.jpg";
import istanbul from "@/static/images/regionBanner/istanbul.jpg";
import frankfurt from "@/static/images/regionBanner/frankfurt.jpg";
import Johannesburg from "@/static/images/regionBanner/Johannesburg.jpg";
import PhnomPenh from "@/static/images/regionBanner/PhnomPenh.jpg";
import riyadh from "@/static/images/regionBanner/riyadh.jpg";
import DUbai from "@/static/images/regionBanner/DUbai.jpg";
import seoul from "@/static/images/regionBanner/seoul.jpg";
import SiliconValley from "@/static/images/regionBanner/SiliconValley.jpg";
import taipeichina from "@/static/images/regionBanner/taipeichina.jpg";
import washington from "@/static/images/regionBanner/washington.jpg";
import Manila from "@/static/images/regionBanner/Manila.jpg";
import Singapore from "@/static/images/regionBanner/Singapore.jpg";
import Saopaulo from "@/static/images/regionBanner/saopaulo.jpg";
import Jeddah from "@/static/images/regionBanner/jeddah.jpg";
import Tokyo from "@/static/images/regionBanner/Tokyo.jpg";
import Cairo from "@/static/images/regionBanner/Cairo.jpg";
import Bahrain from "@/static/images/regionBanner/Bahrain.jpg";
import Sofia from "@/static/images/regionBanner/Sofia.jpg";
import Athens from "@/static/images/regionBanner/Athens.jpg";
import Lumpur from "@/static/images/regionBanner/Lumpur.jpg";
import London from "@/static/images/regionBanner/london.jpg";
import Muscat from "@/static/images/regionBanner/Muscat.jpg";
import Kuwait from "@/static/images/regionBanner/Kuwait.jpg";
import Marseille from "@/static/images/regionBanner/Marseille.jpg";
import Karachi from "@/static/images/regionBanner/Karachi.jpg";
import Nepal from "@/static/images/regionBanner/Nepal.jpg";
import Moscow from "@/static/images/regionBanner/Moscow.jpg";
import BuenosAires from "@/static/images/regionBanner/BuenosAires.jpg";
import Indonesia from "@/static/images/regionBanner/Indonesia.jpg";
import MexicoCity from "@/static/images/regionBanner/MexicoCity.jpg";
import Santiago from "@/static/images/regionBanner/Santiago.jpg";
import Yangon from "@/static/images/regionBanner/Yangon.jpg";
import Baghdad from "@/static/images/regionBanner/Baghdad.jpg";
import Bogota from "@/static/images/regionBanner/Bogota.jpg";
import Doha from "@/static/images/regionBanner/Doha.jpg";
import Peru from "@/static/images/regionBanner/Peru.png";

export interface nodeType {
  text: string; //节点名称（英文）
  continent?: string; //所属洲
  url?: string; //跳转https://go.lightnode.com/url
  value?: string; //节点唯一值，按顺序增加即可
  tab: string; //所属平台（LightNode | VU&DO | LightNode VU&DO）
  banner?: any; //测速页面的banner图片
  // https://github.com/lipis/flag-icons
  countryImgCode?: string; //在https://flagicons.lipis.dev/中搜索国家获取国旗标识码
  countryImg?: any; //国家国旗(现已统一使用countryImgCode)
  position: {
    //所在地图位置
    top: number;
    left: number;
  };
  // city: any; //多语言城市名
  // country: any; //多语言国家名
  // introc?: any; //多语言介绍
  regionCode?: string; //测速code
  oldRegionCode?: string; //旧的测速code
  lineType?: any; //线路
  tagLink?: any; //标签跳转
}
export const nodeMapData: nodeType[] = [
  {
    text: "Silicon Valley",
    continent: "North America",
    url: "the-united-states-vps",
    value: "1",
    tab: "LightNode",
    countryImg: country3,
    countryImgCode: "us",
    banner: SiliconValley,
    position: {
      top: 208,
      left: 134,
    },
    regionCode: "us-siliconvalley-1",
    tagLink: "the-united-states-vps",
  },
  {
    text: "Washington",
    continent: "North America",
    url: "washington-vps",
    value: "2",
    tab: "LightNode",
    countryImgCode: "us",
    // countryImg: country3,
    banner: washington,
    position: {
      top: 194,
      left: 256,
    },
    regionCode: "us-washington-1",
    tagLink: "the-united-states-vps",
  },
  {
    text: "Frankfurt",
    continent: "Europe",
    url: "germany-vps",
    value: "3",
    tab: "LightNode",
    countryImg: country9,
    countryImgCode: "de",
    banner: frankfurt,
    position: {
      top: 162,
      left: 512,
    },
    regionCode: "ger-frankfurt-1",
    tagLink: "germany-vps",
  },
  {
    text: "Istanbul",
    continent: "Asia",
    url: "turkey-vps",
    value: "4",
    tab: "LightNode",
    countryImg: country2,
    countryImgCode: "tr",
    banner: istanbul,
    position: {
      top: 200,
      left: 582,
    },
    regionCode: "tur-istanbul-3",
    tagLink: "turkey-vps",
  },
  {
    text: "Riyadh",
    continent: "Asia",
    url: "saudi-arabia-vps",
    value: "5",
    tab: "LightNode",
    countryImg: country11,
    countryImgCode: "sa",
    banner: riyadh,
    position: {
      top: 265,
      left: 618,
    },
    regionCode: "sau-riyadh-1",
    tagLink: "saudi-arabia-vps",
  },
  {
    text: "Dubai",
    continent: "Asia",
    url: "the-united-arab-emirates-vps",
    value: "6",
    tab: "LightNode",
    countryImg: countryDubai,
    countryImgCode: "ae",
    banner: DUbai,
    position: {
      top: 248,
      left: 634,
    },
    regionCode: "uae-dubai-1",
    tagLink: "the-united-arab-emirates-vps",
  },
  {
    text: "Bangkok",
    continent: "Asia",
    url: "thailand-vps",
    value: "7",
    tab: "LightNode",
    countryImg: country1,
    countryImgCode: "th",
    banner: Bnagkok,
    position: {
      top: 275,
      left: 775,
    },
    regionCode: "tha-bangkok-1",
    tagLink: "thailand-vps",
  },
  {
    text: "Hanoi",
    continent: "Asia",
    url: "hanoi-vps",
    value: "8",
    tab: "LightNode",
    countryImg: country8,
    countryImgCode: "vn",
    banner: hanoi,
    position: {
      top: 258,
      left: 786,
    },
    regionCode: "vn-henei-1",
    tagLink: "vietnam-vps",
  },
  {
    text: "Phnom Penh",
    continent: "Asia",
    url: "cambodia-vps",
    value: "9",
    tab: "LightNode",
    countryImg: country4,
    countryImgCode: "kh",
    banner: PhnomPenh,
    position: {
      top: 285,
      left: 786,
    },
    regionCode: "khn-phnompenh-1",
    tagLink: "cambodia-vps",
  },
  {
    text: "Ho Chi Minh",
    continent: "Asia",
    url: "vietnam-vps",
    value: "10",
    tab: "LightNode",
    countryImg: country8,
    countryImgCode: "vn",
    banner: hochiminhcity,
    position: {
      top: 281,
      left: 799,
    },
    regionCode: "vn-hochiminh-1",
    tagLink: "vietnam-vps",
  },
  {
    text: "Hong Kong",
    continent: "Asia",
    url: "hong-kong-vps",
    value: "11",
    tab: "LightNode",
    countryImg: country6,
    countryImgCode: "cn",
    banner: hongkong,
    position: {
      top: 250,
      left: 812,
    },
    regionCode: "cn-hongkong-3-bgp",
    oldRegionCode: "cn-hongkong-3",
    lineType: [
      // {
      //   line: "CNCN",
      //   regionCode: "cn-hongkong-3-cn2",
      // },
      {
        line: "BGP",
        regionCode: "cn-hongkong-3-bgp",
      },
    ],
    tagLink: "hong-kong-vps",
  },
  {
    text: "Taipei",
    continent: "Asia",
    url: "taiwan-vps",
    value: "12",
    tab: "LightNode",
    countryImg: country6,
    countryImgCode: "cn",
    banner: taipeichina,
    position: {
      top: 243,
      left: 836,
    },
    regionCode: "cn-taiwan-2",
    tagLink: "taiwan-vps",
  },
  {
    text: "Seoul",
    continent: "Asia",
    url: "korea-vps",
    value: "13",
    tab: "LightNode VU&DO",
    countryImg: country7,
    countryImgCode: "kr",
    banner: seoul,
    position: {
      top: 209,
      left: 860,
    },
    regionCode: "kr-seoul-1",
    tagLink: "korea-vps",
  },
  {
    text: "Johannesburg",
    continent: "Africa",
    url: "south-africa-vps",
    value: "14",
    tab: "LightNode",
    countryImg: country13,
    countryImgCode: "za",
    banner: Johannesburg,
    position: {
      top: 370,
      left: 575,
    },
    regionCode: "sa-johannesburg-1",
    tagLink: "south-africa-vps",
  },
  {
    text: "Singapore",
    continent: "Asia",
    url: "singapore-vps",
    value: "15",
    tab: "LightNode VU&DO",
    countryImg: country16,
    countryImgCode: "sg",
    banner: Singapore,
    position: {
      top: 324,
      left: 786,
    },
    regionCode: "sgp-singapore-1",
    tagLink: "singapore-vps",
  },
  {
    text: "Manila",
    continent: "Asia",
    value: "16",
    tab: "LightNode",
    url: "philippines-vps",
    countryImg: country17,
    countryImgCode: "ph",
    banner: Manila,
    position: {
      top: 291,
      left: 851,
    },
    regionCode: "ph-manila-1",
    tagLink: "philippines-vps",
  },
  {
    text: "Dhaka",
    continent: "Asia",
    url: "bangladesh-vps",
    value: "17",
    tab: "LightNode",
    countryImg: country18,
    countryImgCode: "bd",
    banner: Dhaka,
    position: {
      top: 242,
      left: 748,
    },
    regionCode: "bg-dhaka-1",
    tagLink: "bangladesh-vps",
  },
  {
    text: "Sao Paulo",
    continent: "South America",
    url: "brazil-vps",
    value: "18",
    tab: "LightNode",
    countryImg: country19,
    countryImgCode: "br",
    banner: Saopaulo,
    position: {
      top: 366,
      left: 350,
    },
    regionCode: "br-saopaulo-1",
    tagLink: "brazil-vps",
  },
  {
    text: "Jeddah",
    continent: "Asia",
    url: "jeddah-vps",
    value: "19",
    tab: "LightNode",
    countryImg: country11,
    countryImgCode: "sa",
    banner: Jeddah,
    position: {
      top: 263,
      left: 618,
    },
    regionCode: "sau-jeddah-1",
    tagLink: "saudi-arabia-vps",
  },
  {
    text: "Tokyo",
    continent: "Asia",
    url: "japan-vps",
    value: "20",
    tab: "LightNode",
    countryImg: countryTokyo,
    countryImgCode: "jp",
    banner: Tokyo,
    position: {
      top: 225,
      left: 894,
    },
    regionCode: "jp-tokyo-1",
    tagLink: "japan-vps",
  },
  {
    text: "Cairo",
    continent: "Africa",
    url: "egypt-vps",
    value: "21",
    tab: "LightNode",
    countryImg: countryCairo,
    countryImgCode: "eg",
    banner: Cairo,
    position: {
      top: 243,
      left: 570,
    },
    regionCode: "eg-cairo-1",
    tagLink: "egypt-vps",
  },
  {
    text: "Bahrain",
    continent: "Asia",
    url: "bahrain-vps",
    value: "22",
    tab: "LightNode",
    countryImg: countryBahrain,
    countryImgCode: "bh",
    banner: Bahrain,
    position: {
      top: 246,
      left: 592,
    },
    regionCode: "bh-askar-1",
    tagLink: "bahrain-vps",
  },
  {
    text: "Sofia",
    continent: "Europe",
    url: "bulgaria-vps",
    value: "23",
    tab: "LightNode",
    countryImg: countrySofia,
    countryImgCode: "bg",
    banner: Sofia,
    position: {
      top: 186,
      left: 556,
    },
    regionCode: "bg-sofia-1",
    tagLink: "bulgaria-vps",
  },
  {
    text: "Athens",
    continent: "Europe",
    url: "greece-vps",
    value: "24",
    tab: "LightNode",
    countryImg: countryAthens,
    countryImgCode: "gr",
    banner: Athens,
    position: {
      top: 207,
      left: 538,
    },
    regionCode: "gr-athens-1",
    tagLink: "greece-vps",
  },
  {
    text: "Kuala Lumpur",
    continent: "Asia",
    url: "malaysia-vps",
    value: "25",
    tab: "LightNode",
    countryImg: countryLumpur,
    countryImgCode: "my",
    banner: Lumpur,
    position: {
      top: 310,
      left: 776,
    },
    regionCode: "my-kualalumpur-1",
    tagLink: "malaysia-vps",
  },
  {
    text: "London",
    continent: "Europe",
    url: "london-vps",
    value: "26",
    tab: "LightNode",
    countryImg: countryLondon,
    countryImgCode: "gb",
    banner: London,
    position: {
      top: 148,
      left: 478,
    },
    regionCode: "gb-london-1",
    tagLink: "london-vps",
  },
  {
    text: "Muscat",
    continent: "Asia",
    url: "oman-vps",
    value: "27",
    tab: "LightNode",
    countryImg: countryMuscat,
    countryImgCode: "om",
    banner: Muscat,
    position: {
      top: 258,
      left: 650,
    },
    regionCode: "om-muscat-1",
    tagLink: "oman-vps",
  },
  {
    text: "Kuwait City",
    continent: "Asia",
    url: "kuwait-vps",
    value: "28",
    tab: "LightNode",
    countryImg: countryKuwait,
    countryImgCode: "kw",
    banner: Kuwait,
    position: {
      top: 250,
      left: 620,
    },
    regionCode: "kw-kuwait-1",
    tagLink: "kuwait-vps",
  },
  {
    text: "Marseille",
    continent: "Europe",
    url: "marseille-vps",
    value: "29",
    tab: "LightNode",
    countryImg: countryMarseille,
    countryImgCode: "fr",
    banner: Marseille,
    position: {
      top: 200,
      left: 496,
    },
    regionCode: "fr-marseille-1",
    tagLink: "france-vps",
  },
  {
    text: "Karachi",
    continent: "Asia",
    url: "pakistan-vps",
    value: "30",
    tab: "LightNode",
    countryImg: countryKarachi,
    countryImgCode: "pk",
    banner: Karachi,
    position: {
      top: 240,
      left: 688,
    },
    regionCode: "pk-karachi-1",
    tagLink: "pakistan-vps",
  },
  {
    text: "kathmandu",
    continent: "Asia",
    url: "nepal-vps",
    value: "31",
    tab: "LightNode",
    countryImg: countryNepal,
    countryImgCode: "np",
    banner: Nepal,
    position: {
      top: 230,
      left: 728,
    },
    regionCode: "np-kathmandu-1",
    tagLink: "nepal-vps",
  },
  {
    text: "Moscow",
    continent: "Europe",
    url: "russia-vps",
    value: "32",
    tab: "LightNode",
    countryImg: countryRussia,
    countryImgCode: "ru",
    banner: Moscow,
    position: {
      top: 153,
      left: 586,
    },
    regionCode: "ru-moscow-1",
    tagLink: "russia-vps",
  },
  {
    text: "Buenos Aires",
    continent: "South America",
    url: "argentina-vps",
    value: "33",
    tab: "LightNode",
    countryImg: countryArgentina,
    countryImgCode: "ar",
    banner: BuenosAires,
    position: {
      top: 395,
      left: 335,
    },
    regionCode: "ar-buenosaires-1",
    tagLink: "argentina-vps",
  },
  {
    text: "Jakarta",
    continent: "Asia",
    url: "indonesia-vps",
    value: "34",
    tab: "LightNode",
    countryImg: countryJakarta,
    countryImgCode: "id",
    banner: Indonesia,
    position: {
      top: 342,
      left: 848,
    },
    regionCode: "id-jakarta-1",
    tagLink: "indonesia-vps",
  },
  {
    text: "Mexico City",
    continent: "North America",
    url: "mexico-vps",
    value: "35",
    tab: "LightNode",
    countryImg: countryMexico,
    countryImgCode: "mx",
    banner: MexicoCity,
    position: {
      top: 264,
      left: 188,
    },
    regionCode: "mx-mexico-1",
    tagLink: "mexico-vps",
  },
  {
    text: "Santiago",
    continent: "South America",
    url: "chile-vps",
    value: "36",
    tab: "LightNode",
    countryImg: countryChile,
    countryImgCode: "cl",
    banner: Santiago,
    position: {
      top: 413,
      left: 278,
    },
    regionCode: "cl-santiago-1",
    tagLink: "chile-vps",
  },
  {
    text: "Yangon",
    continent: "Asia",
    url: "myanmar-vps",
    value: "37",
    tab: "LightNode",
    countryImg: countryMyanmar,
    countryImgCode: "mm",
    banner: Yangon,
    position: {
      top: 260,
      left: 770,
    },
    regionCode: "mm-yangon-1",
    tagLink: "myanmar-vps",
  },
  {
    text: "Baghdad",
    continent: "Asia",
    url: "iraq-vps",
    value: "38",
    tab: "LightNode",
    countryImg: conuntryIraq,
    banner: Baghdad,
    position: {
      top: 214,
      left: 610,
    },
    regionCode: "iq-baghdad-1",
    countryImgCode: "iq",
    tagLink: "iraq-vps",
  },
  {
    text: "Bogota",
    continent: "South America",
    url: "colombia-vps",
    value: "39",
    tab: "LightNode",
    countryImg: conuntryColombia,
    countryImgCode: "co",
    banner: Bogota,
    position: {
      top: 320,
      left: 256,
    },
    regionCode: "co-bogota-2",
    tagLink: "colombia-vps",
  },
  {
    text: "Doha",
    continent: "Asia",
    value: "40",
    tab: "LightNode",
    banner: Doha,
    position: {
      top: 240,
      left: 650,
    },
    regionCode: "qa-doha-1",
    countryImgCode: "qa",
  },
  {
    text: "Lima",
    continent: "South America",
    value: "41",
    tab: "LightNode",
    banner: Peru,
    position: {
      top: 346,
      left: 256,
    },
    regionCode: "pe-lima-1",
    countryImgCode: "pe",
  },
  {
    text: "Japan",
    continent: "Asia",
    value: "999",
    tab: "VU&DO",
    countryImg: countryTokyo,
    countryImgCode: "jp",
    position: {
      top: 225,
      left: 894,
    },
  },
];
//
interface params {
  key: "continent" | "tab";
  value: string;
}
export const getParentData = (p: params[]) => {
  const arr: nodeType[] = [];
  nodeMapData.forEach((v: nodeType) => {
    if (Object.prototype.toString.call(p) === "[object Array]") {
      if (
        p?.every((m: params) => {
          return v?.[m.key]?.includes(m.value);
        })
      ) {
        arr.push(v);
      }
    }
  });
  return arr;
};
export const getLocation = () => {
  const areaList = [
    {
      text: "Asia",
      value: "Asia",
      children: [],
    },
    {
      text: "Europe",
      value: "Europe",
      children: [],
    },
    {
      text: "NorthAmerica",
      value: "North America",
      children: [],
    },
    {
      text: "Africa",
      value: "Africa",
      children: [],
    },
    {
      text: "SouthAmerica",
      value: "South America",
      children: [],
    },
  ];
  areaList?.map((v: any) => {
    return (v.children = getParentData([
      { key: "continent", value: v.value },
      { key: "tab", value: "LightNode" },
    ]));
  });
  return areaList;
};

// 过滤footer不展示相同国家的vps路径
export const filterCountryVPS = ["jeddah-vps", "hanoi-vps", "washington-vps"];
// 中国地区特殊展示
export const chinaAreaVPS = ["hong-kong-vps", "taiwan-vps"];
