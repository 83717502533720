import component from "./components/vi-VN";
import home from "./home/vi-VN.json";
import aboutUs from "./aboutUs/vi-VN.json";
import termsService from "./termsService/vi-VN.json";
import product from "./Product/vi-VN.json";
import privacyPolicy from "./privacyPolicy/vi-VN.json";
import content from "./content/vi-VN.json";
import contentDetail from "./contentDetail/vi-VN.json";
import advert from "./advert/vi-VN.json";
import advertNew from "./advertNew/vi-VN.json";
import speed from "./speed/vi-VN.json";
import southeastaisa from "./southeastasia/vi-VN.json";
import cocreate from "./cocreate/vi-VN.json";
import contentData1 from "@/models/privacyPolicy/data";
import contentData2 from "@/models/termsService/data";
import { nodeMapData } from "@/utils/nodeData";
const privacyPolicyData: any = {};
const termsServiceData: any = {};
const nodeData: any = {};
contentData1?.content?.forEach((v: any, i: number) => {
  privacyPolicyData[`pages.privacyPolicy.content.title${i + 1}`] =
    v.title?.["vi-VN"];
  v?.text?.["vi-VN"]?.forEach((m: any, j: number) => {
    if (typeof m === "string") {
      privacyPolicyData[`pages.privacyPolicy.content.text${i + 1}-${j + 1}`] =
        m;
    } else {
      m.forEach((n: string, k: number) => {
        privacyPolicyData[
          `pages.privacyPolicy.content.text${i + 1}-${j + 1}-${k + 1}`
        ] = n.indexOf("●") === -1 ? n : n.slice(1, n.length);
      });
    }
  });
});
contentData2?.content?.forEach((v: any, i: number) => {
  termsServiceData[`pages.termsService.content.title${i + 1}`] =
    v.title?.["vi-VN"];
  v?.text?.["vi-VN"]?.forEach((m: any, j: number) => {
    termsServiceData[`pages.termsService.content.text${i + 1}-${j + 1}`] =
      m.text;
  });
});
nodeMapData?.forEach((v: any) => {
  v?.introc?.["vi-VN"]?.forEach((m: any, j: number) => {
    nodeData[`pages.speed.${v.text}.introc${j}`] = m;
  });
});
export default {
  "hello.title": "Chào mừng bạn",
  "hello.message": "Chào thế giới!",
  "404.subTitle": "Rất tiếc, trang bạn truy cập không tồn tại.",
  "404.Back": "Quay lại trang chủ",
  "404.BackCocreate": "Quay lại chương trình cùng sáng tạo",
  "500.subTitle": "Xin lỗi, có lỗi xảy ra.",
  ...component,
  ...home,
  ...aboutUs,
  ...termsService,
  ...product,
  ...privacyPolicy,
  ...content,
  ...contentDetail,
  ...advert,
  ...advertNew,
  ...speed,
  ...southeastaisa,
  ...cocreate,
  ...privacyPolicyData,
  ...termsServiceData,
  ...nodeData,
};
