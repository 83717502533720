import axios from "axios";
import { notification } from "antd";

//notification配置
/*notification.config({
    duration: 300,
});*/
//后端抛出提示不显示
const thowErrorUrl: Array<string> = [
  "/userApi/portal/getLoginInfo",
  "/api/account/account/info.do",
  "/userApi/user/getUserInfoNew.do",
  "/api/cocreate/console/demand/save",
  "/userApi/portal/website/subscribe",
];

axios.defaults.withCredentials = true;

//拦截请求
axios.interceptors.request.use(
  (config: any) => {
    //添加固定参数如用户信息
    //config.params._r = Math.random();
    config.params = {
      _t: new Date().getTime(),
      ...config.params,
    };
    // console.log('config-url', config.url);
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

//拦截响应
axios.interceptors.response.use(
  (response: any) => {
    // console.log("success123=", response);
    if (
      response.status === 200 ||
      response.status === 202 ||
      response.status === 304
      // (response.data.success || response.data.statusText === 'OK' || )
    ) {
      // console.log('response123', response);
      if (typeof response.data === "string") {
        return response.data;
      } else {
        return {
          success: true,
          ...response.data,
        };
      }
    } else if (typeof response.data === "string") {
      //防止循环加载套娃数据，导致格式有问题
      return response.data;
    } else if (Array.isArray(response.data)) {
      // console.log('response456', response);
      return {
        success: true,
        ...response,
      };
    } else if (response.success) {
      //response会重复返回导致最后的值是return，所以需要返回response
      return response;
    } else {
      const msg = response.data?.message || "";
      openNotificationWithIcon("error", msg || "状态异常");
      return {
        code: response.data?.code || 1,
        success: false,
        msg,
      };
    }
  },
  (error: any) => {
    // console.log('error456=', error.response.config);
    if (!error.response) {
      return { success: false };
    }
    const flag = thowErrorUrl.find((item: any) =>
      error.response.config.url?.includes(item)
    );
    if (!flag) {
      if (
        error.response.status === 400 &&
        (error.response.data?.message?.trim() === "已退出，请重新登录" ||
          error.response.data?.message?.trim() === "无URL访问权限")
      ) {
        openNotificationWithIcon("error", "登录超时，请重新登录");
        // setTimeout(() => {
        //     window.open(`http://${window.location.host}/#/login`, '_self');
        // }, 50);
        return;
      }
      openNotificationWithIcon("error", error.response.data?.message || "");
    }
    const code = error.response.data?.code || 1;
    return {
      code,
      success: false,
      msg: error.response.data?.message || "",
    };
  }
);

//提示框
function openNotificationWithIcon(type: any, description: string) {
  if (typeof window !== "undefined") {
    //@ts-ignore
    notification[type]({
      message: description,
    });
  }
}
