const userRoot = "userApi";
const Root = "api";
//公共信息
export const commonApi = `${userRoot}/common`;
// 文章
export const operationApi = `${Root}/operation`;
// 推广
export const promotionApi = `${Root}/promotion`;
//sku
export const siteskuApi = `${Root}/site/sku`;
//account
export const accountApi = `${Root}/account`;
// user
export const userApi = `${userRoot}/portal`;
