import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Img from "@/components/Img";

import Gift from "@/static/images/login-gift.png";

const Child = (props: any) => {
  const { userEmail, total } = props;
  // const [total, setTotal] = useState<number>(0);

  const intl = useIntl();
  const router = useRouter();
  const locale = router.locale || "en-US";
  return (
    <div className="header-btn">
      {!userEmail ? (
        <label className="u-active">
          <a
            href={global.__webInfo?.consoleUrl}
            title="Sign In"
            target="_blank"
            rel="noreferrer"
            style={locale === "fr-FR" ? { padding: 0, fontSize: 12 } : {}}
          >
            {intl.formatMessage({ id: "components.header.Signin" })}
          </a>
        </label>
      ) : null}
      {!userEmail ? (
        <label className="u-hover">
          <a
            href={global.__webInfo?.registerUrl}
            title="Sign Up"
            target="_blank"
            rel="noreferrer"
          >
            <Img src={Gift} alt={""} boxStyle={{ display: "inline-flex" }} />
            {intl.formatMessage({ id: "components.header.Signup" })}
          </a>
        </label>
      ) : null}
      {userEmail ? (
        <label className="u-console">
          <a
            href={
              !total
                ? `${global.__webInfo?.proto}${global.__webInfo?.domainName}/product/instance/create?lang=${locale}`
                : `${global.__webInfo?.proto}${global.__webInfo?.domainName}/product/instance?lang=${locale}`
            }
            title="Sign In"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "components.header.console" })}
          </a>
        </label>
      ) : null}
    </div>
  );
};
export default Child;
