import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Img from "@/components/Img";
import React from "react";
import { Menu } from "antd";
const { SubMenu } = Menu;

const LocationSeo = ({ areaList = [], nodeMapData = [] }: any) => {
  const intl = useIntl();
  const router = useRouter();
  const locale = router.locale || "en-US";
  return (
    <Menu
      mode="inline" //Menu.Item在inline才能渲染dom
      multiple={false}
      openKeys={[]}
      forceSubMenuRender={true}
    >
      {areaList.map((item: any) => {
        return (
          <SubMenu
            key={item.value}
            title={intl.formatMessage({
              id: `components.header.${item.text}`,
            })}
          >
            {nodeMapData.map((v: any) => {
              if (v.continent === item.value && v?.tab?.includes("LightNode")) {
                const _goLocale =
                  locale === "zh-CN" || locale === "zh-HK" ? "zh/" : "";
                const htmlUrl = "https://go.lightnode.com/" + _goLocale + v.url;
                return (
                  <Menu.Item key={v.value}>
                    <a
                      href={htmlUrl}
                      target="_blank"
                      rel="noreferrer"
                      // title={`${v?.city?.[locale] || v?.city?.["en-US"]} VPS`}
                      title={
                        intl.formatMessage({
                          id: `components.NodeMap.city.${v.text}`,
                        }) + " VPS"
                      }
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {v?.countryImgCode ? (
                        <span
                          className={`fi fi-${v.countryImgCode} flag`}
                          style={{ marginRight: 5, width: 24, height: 18 }}
                        ></span>
                      ) : (
                        <Img
                          style={{ marginRight: 5, width: 24, height: 18 }}
                          src={v.countryImg}
                          alt={""}
                        />
                      )}
                      {/* {`${v?.city?.[locale] || v?.city?.["en-US"]} VPS`} */}
                      {intl.formatMessage({
                        id: `components.NodeMap.city.${v.text}`,
                      }) + " VPS"}
                    </a>
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        );
      })}
    </Menu>
  );
};
export default LocationSeo;
