import axios from "axios";
import Qs from "qs";
import { headersAuthToken } from "./authToken";
import { PAGE, PAGE_SIZE } from "../contant/params";

export function get(url: string, params?: any) {
  //增加防护参数
  axios.defaults.headers.common["Authorization"] = headersAuthToken();
  return axios.get(url, {
    params: { ...params },
    paramsSerializer: function (params: any) {
      return Qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}
const initParams = {
  page: PAGE,
  pageSize: PAGE_SIZE,
  filter: {},
};
export function getList(url: string, params = {}) {
  axios.defaults.headers.common["Authorization"] = headersAuthToken();
  return axios.get(url, {
    params: { ...initParams, ...params },
    //这个是引入了一个js的qs库，解决了表格额外参数是数组的情况下，有多出[]的情况
    paramsSerializer: function (params: any) {
      return Qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}
