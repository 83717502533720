/**
 * 为了快速替换img标签，做了此组件
 * type = label,避免和同级span样式冲突
 */
import Image from "next/image";
const Img = ({
  className = "",
  id = "",
  boxStyle = {},
  type = "",
  onClick,
  ...imgProps
}: any) => {
  return type === "label" ? (
    <label
      className={`img-box ${className}`}
      id={id}
      onClick={onClick}
      style={boxStyle}
    >
      <Image {...imgProps} />
    </label>
  ) : (
    <span
      className={`img-box ${className}`}
      id={id}
      onClick={onClick}
      style={boxStyle}
    >
      <Image {...imgProps} />
    </span>
  );
};
export default Img;
