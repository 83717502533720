import component from "./components/zh-CN";
import home from "./home/zh-CN.json";
import aboutUs from "./aboutUs/zh-CN.json";
import termsService from "./termsService/zh-CN.json";
import product from "./Product/zh-CN.json";
import privacyPolicy from "./privacyPolicy/zh-CN.json";
import content from "./content/zh-CN.json";
import contentDetail from "./contentDetail/zh-CN.json";
import advert from "./advert/zh-CN.json";
import advertNew from "./advertNew/zh-CN.json";
import speed from "./speed/zh-CN.json";
import southeastaisa from "./southeastasia/zh-CN.json";
import cocreate from "./cocreate/zh-CN.json";
import contentData1 from "@/models/privacyPolicy/data";
import contentData2 from "@/models/termsService/data";
import { nodeMapData } from "@/utils/nodeData";
const privacyPolicyData: any = {};
const termsServiceData: any = {};
const nodeData: any = {};
contentData1?.content?.forEach((v: any, i: number) => {
  privacyPolicyData[`pages.privacyPolicy.content.title${i + 1}`] =
    v.title?.["zh-CN"];
  v?.text?.["zh-CN"]?.forEach((m: any, j: number) => {
    if (typeof m === "string") {
      privacyPolicyData[`pages.privacyPolicy.content.text${i + 1}-${j + 1}`] =
        m;
    } else {
      m.forEach((n: string, k: number) => {
        privacyPolicyData[
          `pages.privacyPolicy.content.text${i + 1}-${j + 1}-${k + 1}`
        ] = n.indexOf("●") === -1 ? n : n.slice(1, n.length);
      });
    }
  });
});
contentData2?.content?.forEach((v: any, i: number) => {
  termsServiceData[`pages.termsService.content.title${i + 1}`] =
    v.title?.["zh-CN"];
  v?.text?.["zh-CN"]?.forEach((m: any, j: number) => {
    termsServiceData[`pages.termsService.content.text${i + 1}-${j + 1}`] =
      m.text;
  });
});
nodeMapData?.forEach((v: any) => {
  v?.introc?.["zh-CN"]?.forEach((m: any, j: number) => {
    nodeData[`pages.speed.${v.text}.introc${j}`] = m;
  });
});
export default {
  "hello.title": "欢迎",
  "hello.message": "你好, 世界!",
  "404.subTitle": "对不起，您访问的页面不存在。",
  "404.Back": "返回首页",
  "404.BackCocreate": "返回共创计划",
  "500.subTitle": "对不起，出了问题。",
  ...component,
  ...home,
  ...aboutUs,
  ...termsService,
  ...product,
  ...privacyPolicy,
  ...content,
  ...contentDetail,
  ...advert,
  ...advertNew,
  ...speed,
  ...southeastaisa,
  ...cocreate,
  ...privacyPolicyData,
  ...termsServiceData,
  ...nodeData,
};
