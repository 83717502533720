import { useIntl } from "react-intl";
import { Menu, Dropdown } from "antd";
import Img from "@/components/Img";
import { useState } from "react";
import imgMobileArrowDown from "@/static/images/down.png";
import logoBedge from "@/static/images/logo-lightCDN.png";
import "../setLocale/style.less";
const partnerList = [
  {
    text: "LightCND",
    img: logoBedge,
    value: "https://www.lightcdn.com/",
  },
];
const SetPartner = (props: any) => {
  const { isMobile } = props;
  const [isShow, setIsShow] = useState<boolean>(false);
  const intl = useIntl();
  const langChange = (url: string) => {
    window.open(url, "_blank");
  };
  const menu = (
    <Menu>
      {partnerList.map((item: any) => {
        return (
          <Menu.Item onClick={() => langChange(item.value)} key={item.text}>
            {item.img ? <Img src={item.img} alt={item.text} /> : item.text}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const handleShowSub = () => {
    const show = isShow;
    setIsShow(!show);
  };
  const handleClose = () => {
    props.onClose && props.onClose();
  };
  return !isMobile ? (
    <div className="k-setLocale">
      <Dropdown overlay={menu} overlayClassName="k-setPartnerStyle">
        <div className="k-setLocaleCont">
          <i className="icon-partner"></i>
          <span>{intl.formatMessage({ id: "components.footer.Partner" })}</span>
          <Img
            src={imgMobileArrowDown}
            alt={""}
            type="label"
            boxStyle={{ marginLeft: 10, width: 8 }}
          />
        </div>
      </Dropdown>
    </div>
  ) : (
    <ul className="header-setLocale">
      <li
        onClick={handleShowSub}
        className={[
          "k-setLocale-locale hasChildren",
          isShow ? "show" : "",
        ].join(" ")}
      >
        <span>{intl.formatMessage({ id: "components.footer.Partner" })}</span>
      </li>
      <ul>
        {partnerList.map((item: any) => {
          return isShow ? (
            <li
              onClick={() => {
                langChange(item.value);
                handleClose();
              }}
              key={item.value}
            >
              {item.img ? <Img src={item.img} alt={item.text} /> : item.text}
            </li>
          ) : null;
        })}
      </ul>
    </ul>
  );
};
export default SetPartner;
