import btoa from "btoa";
export function headersAuthToken() {
  //时间戳
  const timestamp = parseInt(new Date().getTime() as any, 10);
  //生成字母+数字随机整数，Math.random()：0-1随机数，toString(36)：36进制转换，substr(2)截取掉0.
  // const randomuuid = Math.random().toString(36).substr(2);
  const randomuuid = randomUUID(12, 32); //12位数，32进制转换
  //后端提供的id
  const appid = "zhavzhCN9yBMWF";
  //btoa是一个加密库
  const AUTH_TOKEN =
    "Basic " + btoa(timestamp + ":" + randomuuid + ":" + appid);
  return AUTH_TOKEN;
}
//随机字符串
export function randomUUID(len: number, radix: number) {
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  let i;
  radix = radix || chars.length;
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) {
      uuid[i] = chars[0 | (Math.random() * radix)];
    }
  } else {
    let r;
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
}
