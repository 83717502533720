/**
 * 第三方matomo统计页面路径上传组件
 */
import { useEffect } from "react";
import { init, push } from "@socialgouv/matomo-next";
const MATOMO_URL = "https://matomotech.com";
const MATOMO_SITE_ID = "3";
let isFirst = true;
const MatomoPath = () => {
  useEffect(() => {
    if (isFirst && location?.hostname?.includes("www.")) {
      init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });
      push(["setCustomUrl", location.pathname + location.search]);
      push(["setDocumentTitle", document.title]);
      push(["trackPageView"]);
      isFirst = false;
    }
    return () => {
      push(["HeatmapSessionRecording::disable"]);
    };
  }, []);
  return null;
};
export default MatomoPath;
