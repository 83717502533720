import { checkPwd } from "@/utils/reg";
import { Form, Input, notification, Alert } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Verify from "@/components/Verify";
import ConfirmModal from "@/components/ConfirmModal";
import { checkUsernameAndPassword, resetPassword } from "@/api/useApi";
import { cryptPsw, cryptSaltPsw, randomString } from "@/utils/utils";

const ResetPasswordModal = (props: any) => {
  const { setModalVisible, userEmail, onClose, handleLogout } = props;
  const intl = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [verifyVisible, setVerifyVisible] = useState(false);

  const handleSubmit = async (values: any) => {
    const salt = randomString();
    // 对称盐值加密
    const oldPwd = cryptSaltPsw(salt, values.oldPassword);
    // MD5
    const newPwd = cryptPsw(values.newPassword);
    setLoading(true);
    const res: any = await checkUsernameAndPassword({
      userName: userEmail as string,
      userPwd: newPwd,
    });
    if (res?.success) {
      resetPassword({
        salt,
        oldUserPwd: oldPwd,
        newUserPwd: newPwd,
      }).then((res: any) => {
        setLoading(false);
        if (res.success) {
          setModalVisible(false);
          notification.success({
            message: intl.formatMessage({
              id: "header.reset.notification.success",
            }),
          });
          handleLogout();
          onClose && onClose();
        } else {
          if (res.code === "NoUrlAuthority") {
            setModalVisible(false);
            handleLogout();
            onClose && onClose();
          }
        }
      });
    }
  };

  // 鼠标聚焦时验证框变化
  const handleFocusNewpwd = () => {
    setVerifyVisible(true);
  };
  // 鼠标离开时密码验证框消失
  const handleBlurNewpwd = () => {
    setVerifyVisible(false);
  };
  useEffect(() => {
    if (!props.visible) {
      form.resetFields();
    }
  }, [props.visible]);

  return (
    <ConfirmModal
      {...props}
      titleCenter
      title={intl.formatMessage({ id: "header.reset.title" })}
      width={600}
      okText={intl.formatMessage({ id: "header.reset.modal.okText" })}
      maskClosable={false}
      onOk={form.submit}
      okButtonProps={{ loading }}
      cancelButtonProps={{ loading }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Alert
        showIcon
        type="warning"
        message={intl.formatMessage({ id: "tip.reset.password" })}
        style={{ marginBottom: 16 }}
      />
      <Form
        labelAlign="left"
        labelCol={{ span: 9 }}
        onFinish={handleSubmit}
        form={form}
        target="_blank"
      >
        <Form.Item
          label={intl.formatMessage({
            id: "header.reset.field.label.oldPassword",
          })}
          rules={[
            {
              max: 30,
              min: 8,
              required: true,
              message: "",
            },
          ]}
          name="oldPassword"
        >
          <Input.Password
            placeholder={intl.formatMessage({
              id: "header.reset.field.label.oldPassword",
            })}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "header.reset.field.label.newPassword",
          })}
          rules={[
            {
              required: true,
              async validator(_, password) {
                if (!checkPwd(password)) {
                  return Promise.reject("");
                }
                return Promise.resolve();
              },
              message: "",
            },
          ]}
          name="newPassword"
        >
          <Input.Password
            placeholder={intl.formatMessage({
              id: "header.reset.field.label.newPassword",
            })}
            autoComplete="off"
            onFocus={handleFocusNewpwd}
            onBlur={handleBlurNewpwd}
            style={{ position: "relative" }}
            prefixCls="no-prefix-margin-right"
            prefix={
              <>
                <Form.Item noStyle dependencies={["newPassword"]}>
                  {({ getFieldValue }) => (
                    <Verify
                      visible={verifyVisible}
                      value={getFieldValue("newPassword")}
                      checkCount={4}
                      style={{ right: 10 }}
                      rules={[
                        {
                          reg: (v) => v.length >= 8 && v.length <= 30,
                          text: intl.formatMessage({
                            id: "register.from.password.checkLength",
                          }),
                          required: true,
                          errorReg: (v) =>
                            v.length > 0 && (v.length < 8 || v.length > 30),
                        },
                        {
                          reg: /[A-Z]+?/,
                          text: intl.formatMessage({
                            id: "register.from.password.checkUppercase",
                          }),
                          required: true,
                        },
                        {
                          reg: /[a-z]+?/,
                          text: intl.formatMessage({
                            id: "register.from.password.checkLowercase",
                          }),
                          required: true,
                        },
                        {
                          reg: /[0-9]+?/,
                          required: true,
                          text: intl.formatMessage({
                            id: "register.from.password.checkNumber",
                          }),
                        },
                        {
                          reg: /[()`~!@#$*-+={}[\]:;,.?]+?/,
                          text: intl.formatMessage({
                            id: "register.from.password.checkSpecial",
                          }),
                          errorText: intl.formatMessage({
                            id: "register.form.verify.checkSpecial.error",
                          }),
                          errorReg: (v) => {
                            let otherChars = false;
                            let blankChars = false;
                            const specStr = "()`~!@#$*-+={}[]:;,.?/";
                            const charAry = v.split("");
                            // eslint-disable-next-line no-plusplus
                            for (let i = 0; i < charAry.length; i++) {
                              // 包含小写字母
                              if (/^[a-z]$/.exec(charAry[i])) {
                                continue;
                              }
                              // 包含大写字母
                              if (/^[A-Z]$/.exec(charAry[i])) {
                                continue;
                              }
                              // 包含数字或特殊字符
                              if (
                                /^[0-9]$/.exec(charAry[i]) ||
                                specStr.indexOf(charAry[i]) > -1
                              ) {
                                continue;
                              }
                              otherChars = true;
                              if (charAry[i] === " ") {
                                blankChars = true;
                              }
                            }

                            if (otherChars || blankChars) {
                              return true;
                            }
                            return false;
                          },
                        },
                      ]}
                    />
                  )}
                </Form.Item>
              </>
            }
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "header.reset.field.label.confirmPassword",
          })}
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "header.reset.field.warn.confirmPassword",
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    intl.formatMessage({
                      id: "header.reset.field.warn.confirmPassword",
                    })
                  )
                );
              },
            }),
          ]}
          name="confirmPassword"
        >
          <Input.Password
            placeholder={intl.formatMessage({
              id: "header.reset.field.label.confirmPassword",
            })}
            autoComplete="off"
          />
        </Form.Item>
      </Form>
    </ConfirmModal>
  );
};

export default ResetPasswordModal;
