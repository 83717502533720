export default {
  title: "Terms of Service Agreement",
  // time: 'Create time: 2021-05-20',
  content: [
    {
      title: {
        "en-US": "Introduce",
        "zh-CN": "介绍",
        "zh-HK": "介紹",
        "vi-VN": "Introduce",
        "fr-FR": "Introduce",
      },
      text: {
        "en-US": [
          {
            type: "p",
            text: "Welcome to {platformName}'s Terms of Service! The first part of our Terms of Service is an introduction, which is structured like an FAQ and lists some concepts before diving into other terms.",
          },
        ],
        "zh-CN": [
          {
            type: "p",
            text: "欢迎使用{platformName}的服务条款！我们的服务条款的第一部分是介绍，其结构类似于常见问题解答，并在深入探讨其他术语之前列出了一些概念。",
          },
        ],
        "zh-HK": [
          {
            type: "p",
            text: "歡迎使用{platformName}的服務條款！ 我們的服務條款的第一部分是介紹，其結構類似於常見問題解答，並在深入探討其他術語之前列出了一些概念。",
          },
        ],
        "vi-VN": [
          {
            type: "p",
            text: "Welcome to {platformName}'s Terms of Service! The first part of our Terms of Service is an introduction, which is structured like an FAQ and lists some concepts before diving into other terms.",
          },
        ],
        "fr-FR": [
          {
            type: "p",
            text: "Welcome to {platformName}'s Terms of Service! The first part of our Terms of Service is an introduction, which is structured like an FAQ and lists some concepts before diving into other terms.",
          },
        ],
      },
    },
    {
      title: {
        "en-US": "What files does the TOS consist of?",
        "zh-CN": "TOS由哪些文件组成？",
        "zh-HK": "TOS由哪些檔案組成？",
        "vi-VN": "What files does the TOS consist of?",
        "fr-FR": "What files does the TOS consist of?",
      },
      text: {
        "en-US": [
          { type: "span", text: 'We refer to the Terms of Service as "TOS"' },
          {
            type: "p",
            text: "This page lists the core of our TOS, but we have other contract documents that you can consider additional terms on specific topics. Legally, these other documents are incorporated by reference as if they were written here and form part of the entire TOS. Therefore, you should also check out these other documents, which can be found at the following links:",
          },
          {
            type: "strong",
            text: "Privacy Policy, which outlines how we collect, use and share data on our website and services",
          },
        ],
        "zh-CN": [
          { type: "span", text: "我们将服务条款称为“TOS”" },
          {
            type: "p",
            text: "此页面列出了我们TOS的核心，但我们还有其他合同文件，您可以将其视为特定主题的附加条款。从法律上讲，这些其他文件以引用方式并入，就好像它们写在这里一样，并构成整个TOS的一部分。因此，您还应该查看这些其他文档，这些文档可以在以下链接中找到：",
          },
          {
            type: "strong",
            text: "隐私政策，概述了我们如何在我们的网站和服务中收集、使用和共享数据",
          },
        ],
        "zh-HK": [
          { type: "span", text: "我們將服務條款稱為“TOS”" },
          {
            type: "p",
            text: "此頁面列出了我們TOS的覈心，但我們還有其他契约檔案，您可以將其視為特定主題的附加條款。 從法律上講，這些其他檔案以引用管道併入，就好像它們寫在這裡一樣，並構成整個TOS的一部分。 囙此，您還應該查看這些其他檔案，這些檔案可以在以下連結中找到：",
          },
          {
            type: "strong",
            text: "隱私政策，概述了我們如何在我們的網站和服務中收集、使用和共亯數據",
          },
        ],
        "vi-VN": [
          { type: "span", text: 'We refer to the Terms of Service as "TOS"' },
          {
            type: "p",
            text: "This page lists the core of our TOS, but we have other contract documents that you can consider additional terms on specific topics. Legally, these other documents are incorporated by reference as if they were written here and form part of the entire TOS. Therefore, you should also check out these other documents, which can be found at the following links:",
          },
          {
            type: "strong",
            text: "Privacy Policy, which outlines how we collect, use and share data on our website and services",
          },
        ],
        "fr-FR": [
          { type: "span", text: 'We refer to the Terms of Service as "TOS"' },
          {
            type: "p",
            text: "This page lists the core of our TOS, but we have other contract documents that you can consider additional terms on specific topics. Legally, these other documents are incorporated by reference as if they were written here and form part of the entire TOS. Therefore, you should also check out these other documents, which can be found at the following links:",
          },
          {
            type: "strong",
            text: "Privacy Policy, which outlines how we collect, use and share data on our website and services",
          },
        ],
      },
    },
    {
      title: {
        "en-US": "Who is this TOS for?",
        "zh-CN": "本TOS适用于谁？",
        "zh-HK": "本TOS適用於誰？",
        "vi-VN": "Who is this TOS for?",
        "fr-FR": "Who is this TOS for?",
      },
      text: {
        "en-US": [
          {
            type: "p",
            text: 'When we refer to "{platformName}" or use pronouns such as "we," "us," or "our," we mean {platformName}, LLC and its parent, affiliates, and subsidiaries.',
          },
          {
            type: "p",
            text: 'When we refer to "User", we mean you, and we also use words such as "you" and "your" to refer to you. Who "you" is may become more complicated if you use our services on behalf of a company, organization or other entity, in which case you represent to us that you have the right to bind your company, organization, other entity to this TOS, and you undertake to represent that entity (and "User", "you" and "your" then refer to such entity). If you are not sure what this means, or whether you have the authority to bind your company, organization or entity to this contract, you should ask others in your organization for clarification of authority.',
          },
          { type: "span", text: "What are the terms of service?" },
          {
            type: "p",
            text: "This TOS is a binding contract between you and {platformName} covering all products, websites, products, services, features, content, applications and other content we provide to users like you.",
          },
          {
            type: "p",
            text: 'We will periodically distinguish between our websites, such as {host} (which we will collectively refer to as the "Site"), and all of our other services, such as our cloud infrastructure and other paid services (which we will collectively refer to as the "Services").',
          },
          {
            type: "p",
            text: "As you will see later in the TOS, Section 3 (Site Terms) is specific to your use of the Site and Section 4 (Terms of Service) is specific to your use of the Services.",
          },
          { type: "span", text: "When does this TOS apply to me?" },
          {
            type: "p",
            text: "Now that we have some common language, we want to make sure you understand when and how TOS applies.",
          },
          {
            type: "p",
            text: "First, you must understand that this is a binding legal contract necessary to use our website and services. Therefore, you may use our website and services only if you agree to have read, understood and agreed to be bound by this TOS.",
          },
          {
            type: "p",
            text: 'If you register for the service or create an account through our website, this TOS is effective when you tick "I agree to the Terms of service" or other feature that indicates your acceptance of its terms. If you purchase the Services through a separate written contract, this TOS will be included in that contract, whether or not stated. Regardless of these other methods, this TOS applies and is legally binding on you and your access and use when you access or use our website and services. These are common examples and this paragraph does not necessarily include all other ways in which you may be bound by this TOS.',
          },
          { type: "span", text: "How are disputes under this TOS handled?" },
          {
            type: "p",
            text: "A requirement of our TOS is that you must agree to resolve disputes arising under this TOS through direct binding arbitration between you and {platformName}. In arbitration, a neutral arbitrator or panel of arbitrators will hear the arguments of both parties and decide the dispute in a convenient and cost-effective manner. When these cases involve small clai",
          },
          {
            type: "p",
            text: "You should understand that this means neither you nor {platformName} have the opportunity to resolve certain disputes in court before a judge or jury. You will not be able to file a claim in a traditional trial, nor will you be able to participate in a court-based class action or similar process.",
          },
        ],
        "zh-CN": [
          {
            type: "p",
            text: "当我们提及“{platformName}”或使用“我们”、“我们”或“我们的”等代词时，我们指的是{platformName},LLC及其母公司、附属公司和子公司。",
          },
          {
            type: "p",
            text: "当我们提到“用户”时，我们指的是您，我们也会使用“您”和“您的”等词语来指代您。 如果您代表公司、组织或其他实体使用我们的服务，“您”是谁可能会变得更加复杂，在这种情况下，您向我们表示您有权将您的公司、组织、其他实体约束到本TOS，并且您承诺可以代表该实体（以及“用户”、“您”和“你的”然后指代这样的实体）。如果您不确定这意味着什么，或者您是否有权将您的公司、组织或实体约束到本合同，您应该要求您组织中的其他人获得有关权限的说明。",
          },
          { type: "span", text: "什么是服务条款？" },
          {
            type: "p",
            text: "本TOS是您与{platformName}之间具有约束力的合同，涵盖我们向像您这样的用户提供的所有产品、网站、产品、服务、功能、内容、应用程序和其他内容。",
          },
          {
            type: "p",
            text: "我们将定期区分我们的网站，例如{host}（我们将统称为“网站”）和我们的所有其他服务，例如我们的云基础设施和其他付费服务（我们统称为“服务”）。",
          },
          {
            type: "p",
            text: "正如您稍后将在TOS中看到的那样，第3节（网站条款）特定于您对网站的使用，第4节（服务条款）特定于您对服务的使用。",
          },
          { type: "span", text: "本TOS何时适用于我？" },
          {
            type: "p",
            text: "既然我们有了一些共同语言，我们希望确保您了解TOS何时以及如何适用。",
          },
          {
            type: "p",
            text: "首先，您必须了解这是使用我们的网站和服务所必需的具有约束力的法律合同。因此，只有在您同意已阅读、理解并同意受本TOS约束的情况下，您才能使用我们的网站和服务。",
          },
          {
            type: "p",
            text: "如果您通过我们的网站注册服务或创建帐户，则本TOS在您勾选“我同意Terms of service”或其他表明您接受其条款的功能时生效。如果您通过单独的书面合同购买服务，则本TOS将包含在该合同中，无论是否注明。无论这些其他方法如何，当您访问或使用我们的网站和服务时，本TOS均适用并对您以及您的访问和使用具有法律约束力。这些是常见示例，本段不一定包括您可以受此TOS约束的所有其他方式。",
          },
          { type: "span", text: "根据本TOS发生的争议如何处理？" },
          {
            type: "p",
            text: "我们的TOS的一项要求是，您必须同意通过您与{platformName}之间直接具有约束力的仲裁删除的内容:会发生什么来解决根据本TOS产生的争议。在仲裁中，中立的仲裁员或仲裁员小组将听取双方的论点， 并以方便且具有成本效益的方式对争议作出裁决。当这些案件涉及小额索赔时，您仍然可以在法庭上提起诉讼，但所有其他索赔将受到仲裁。",
          },
          {
            type: "p",
            text: "您应该明白，这意味着您和{platformName}都没有机会在法庭上在法官或陪审团面前解决某些争议。您将无法在传统审判中提出索赔，也将无法参与基于法院的集体诉讼或类似程序。",
          },
        ],
        "zh-HK": [
          {
            type: "p",
            text: "當我們提及“{platformName}”或使用“我們”、“我們”或“我們的”等代詞時，我們指的是{platformName}，LLC及其母公司、附屬公司和子公司。",
          },
          {
            type: "p",
            text: "當我們提到“用戶”時，我們指的是您，我們也會使用“您”和“您的”等詞語來指代您。 如果您代表公司、組織或其他實體使用我們的服務，“您”是誰可能會變得更加複雜，在這種情況下，您向我們表示您有權將您的公司、組織、其他實體約束到本TOS，並且您承諾可以代表該實體（以及“用戶”、“您”和“你的”然後指代這樣的實體）。 如果您不確定這意味著什麼，或者您是否有權將您的公司、組織或實體約束到本契约，您應該要求您組織中的其他人獲得有關許可權的說明。",
          },
          { type: "span", text: "什麼是服務條款？" },
          {
            type: "p",
            text: "本TOS是您與{platformName}之間具有約束力的契约，涵蓋我們向像您這樣的用戶提供的所有產品、網站、產品、服務、功能、內容、應用程序和其他內容。",
          },
          {
            type: "p",
            text: "我們將定期區分我們的網站，例如{host}（我們將統稱為“網站”）和我們的所有其他服務，例如我們的雲基礎設施和其他付費服務（我們統稱為“服務”）。",
          },
          {
            type: "p",
            text: "正如您稍後將在TOS中看到的那樣，第3節（網站條款）特定於您對網站的使用，第4節（服務條款）特定於您對服務的使用。",
          },
          { type: "span", text: "本TOS何時適用於我？" },
          {
            type: "p",
            text: "既然我們有了一些共同語言，我們希望確保您瞭解TOS何時以及如何適用。",
          },
          {
            type: "p",
            text: "首先，您必須瞭解這是使用我們的網站和服務所必需的具有約束力的法律契约。 囙此，只有在您同意已閱讀、理解並同意受本TOS約束的情况下，您才能使用我們的網站和服務。",
          },
          {
            type: "p",
            text: "如果您通過我們的網站註冊服務或創建帳戶，則本TOS在您勾選“我同意Terms of service”或其他表明您接受其條款的功能時生效。 如果您通過單獨的書面契约購買服務，則本TOS將包含在該契约中，無論是否注明。 無論這些其他方法如何，當您訪問或使用我們的網站和服務時，本TOS均適用並對您以及您的訪問和使用具有法律約束力。 這些是常見示例，本段不一定包括您可以受此TOS約束的所有其他管道。",
          },
          { type: "span", text: "根據本TOS發生的爭議如何處理？" },
          {
            type: "p",
            text: "我們的TOS的一項要求是，您必須同意通過您與{platformName}之間直接具有約束力的仲裁來解决根據本TOS產生的爭議。 在仲裁中，中立的仲裁員或仲裁員小組將聽取雙方的論點，並以方便且具有成本效益的管道對爭議作出裁决。 當這些案件涉及小額索賠時，您仍然可以在法庭上提起訴訟，但所有其他索賠將受到仲裁。",
          },
          {
            type: "p",
            text: "您應該明白，這意味著您和{platformName}都沒有機會在法庭上在法官或陪審團面前解决某些爭議。 您將無法在傳統審判中提出索賠，也將無法參與基於法院的集體訴訟或類似程式。",
          },
        ],
        "vi-VN": [
          {
            type: "p",
            text: 'When we refer to "{platformName}" or use pronouns such as "we," "us," or "our," we mean {platformName}, LLC and its parent, affiliates, and subsidiaries.',
          },
          {
            type: "p",
            text: 'When we refer to "User", we mean you, and we also use words such as "you" and "your" to refer to you. Who "you" is may become more complicated if you use our services on behalf of a company, organization or other entity, in which case you represent to us that you have the right to bind your company, organization, other entity to this TOS, and you undertake to represent that entity (and "User", "you" and "your" then refer to such entity). If you are not sure what this means, or whether you have the authority to bind your company, organization or entity to this contract, you should ask others in your organization for clarification of authority.',
          },
          { type: "span", text: "What are the terms of service?" },
          {
            type: "p",
            text: "This TOS is a binding contract between you and {platformName} covering all products, websites, products, services, features, content, applications and other content we provide to users like you.",
          },
          {
            type: "p",
            text: 'We will periodically distinguish between our websites, such as {host} (which we will collectively refer to as the "Site"), and all of our other services, such as our cloud infrastructure and other paid services (which we will collectively refer to as the "Services").',
          },
          {
            type: "p",
            text: "As you will see later in the TOS, Section 3 (Site Terms) is specific to your use of the Site and Section 4 (Terms of Service) is specific to your use of the Services.",
          },
          { type: "span", text: "When does this TOS apply to me?" },
          {
            type: "p",
            text: "Now that we have some common language, we want to make sure you understand when and how TOS applies.",
          },
          {
            type: "p",
            text: "First, you must understand that this is a binding legal contract necessary to use our website and services. Therefore, you may use our website and services only if you agree to have read, understood and agreed to be bound by this TOS.",
          },
          {
            type: "p",
            text: 'If you register for the service or create an account through our website, this TOS is effective when you tick "I agree to the Terms of service" or other feature that indicates your acceptance of its terms. If you purchase the Services through a separate written contract, this TOS will be included in that contract, whether or not stated. Regardless of these other methods, this TOS applies and is legally binding on you and your access and use when you access or use our website and services. These are common examples and this paragraph does not necessarily include all other ways in which you may be bound by this TOS.',
          },
          { type: "span", text: "How are disputes under this TOS handled?" },
          {
            type: "p",
            text: "A requirement of our TOS is that you must agree to resolve disputes arising under this TOS through direct binding arbitration between you and {platformName}. In arbitration, a neutral arbitrator or panel of arbitrators will hear the arguments of both parties and decide the dispute in a convenient and cost-effective manner. When these cases involve small clai",
          },
          {
            type: "p",
            text: "You should understand that this means neither you nor {platformName} have the opportunity to resolve certain disputes in court before a judge or jury. You will not be able to file a claim in a traditional trial, nor will you be able to participate in a court-based class action or similar process.",
          },
        ],
        "fr-FR": [
          {
            type: "p",
            text: 'When we refer to "{platformName}" or use pronouns such as "we," "us," or "our," we mean {platformName}, LLC and its parent, affiliates, and subsidiaries.',
          },
          {
            type: "p",
            text: 'When we refer to "User", we mean you, and we also use words such as "you" and "your" to refer to you. Who "you" is may become more complicated if you use our services on behalf of a company, organization or other entity, in which case you represent to us that you have the right to bind your company, organization, other entity to this TOS, and you undertake to represent that entity (and "User", "you" and "your" then refer to such entity). If you are not sure what this means, or whether you have the authority to bind your company, organization or entity to this contract, you should ask others in your organization for clarification of authority.',
          },
          { type: "span", text: "What are the terms of service?" },
          {
            type: "p",
            text: "This TOS is a binding contract between you and {platformName} covering all products, websites, products, services, features, content, applications and other content we provide to users like you.",
          },
          {
            type: "p",
            text: 'We will periodically distinguish between our websites, such as {host} (which we will collectively refer to as the "Site"), and all of our other services, such as our cloud infrastructure and other paid services (which we will collectively refer to as the "Services").',
          },
          {
            type: "p",
            text: "As you will see later in the TOS, Section 3 (Site Terms) is specific to your use of the Site and Section 4 (Terms of Service) is specific to your use of the Services.",
          },
          { type: "span", text: "When does this TOS apply to me?" },
          {
            type: "p",
            text: "Now that we have some common language, we want to make sure you understand when and how TOS applies.",
          },
          {
            type: "p",
            text: "First, you must understand that this is a binding legal contract necessary to use our website and services. Therefore, you may use our website and services only if you agree to have read, understood and agreed to be bound by this TOS.",
          },
          {
            type: "p",
            text: 'If you register for the service or create an account through our website, this TOS is effective when you tick "I agree to the Terms of service" or other feature that indicates your acceptance of its terms. If you purchase the Services through a separate written contract, this TOS will be included in that contract, whether or not stated. Regardless of these other methods, this TOS applies and is legally binding on you and your access and use when you access or use our website and services. These are common examples and this paragraph does not necessarily include all other ways in which you may be bound by this TOS.',
          },
          { type: "span", text: "How are disputes under this TOS handled?" },
          {
            type: "p",
            text: "A requirement of our TOS is that you must agree to resolve disputes arising under this TOS through direct binding arbitration between you and {platformName}. In arbitration, a neutral arbitrator or panel of arbitrators will hear the arguments of both parties and decide the dispute in a convenient and cost-effective manner. When these cases involve small clai",
          },
          {
            type: "p",
            text: "You should understand that this means neither you nor {platformName} have the opportunity to resolve certain disputes in court before a judge or jury. You will not be able to file a claim in a traditional trial, nor will you be able to participate in a court-based class action or similar process.",
          },
        ],
      },
    },
    {
      title: {
        "en-US": "TOS Terms and Conditions",
        "zh-CN": "TOS的条款和条件",
        "zh-HK": "TOS的條款和條件",
        "vi-VN": "TOS Terms and Conditions",
        "fr-FR": "TOS Terms and Conditions",
      },
      text: {
        "en-US": [
          { type: "h4", text: "1. Eligibility and registration" },
          {
            type: "p",
            text: "1.1 Before you use our website and services, you must ensure that such use complies with all laws, rules and regulations that apply to you. Your right to access the Site and Services will be revoked if your use is prohibited or if our regulations conflict with any applicable laws, rules or regulations. It is your responsibility to make these decisions before using the website and services.",
          },
          {
            type: "p",
            text: "1.2 The Site and Services are not directed to, and are not intended to be used by, anyone under the age of 18. By using the Site and Services, you represent and warrant to us that: (a) you are at least 18 years old; (b) you otherwise have sufficient legal consent, license and capacity to use in the applicable jurisdiction as determined by you Website and Services.",
          },
          {
            type: "p",
            text: '1.3 To access certain features of the Services and Website, you must register for an account ("Account"). When you register for your account, you may be required to provide us with some information about yourself, such as your name, email address and a valid payment method, and you may also voluntarily provide optional information about yourself. Account information and our use and disclosure of it are governed by the Privacy Policy and Data Processing Agreement.',
          },
          {
            type: "p",
            text: "1.4 We may, in our sole discretion, refuse to provide or continue to provide the Site and Services to any person or entity and change the eligibility criteria at any time, including if you fail to comply with the TOS. We reserve the right to deactivate, terminate, block access, disable the Services and/or delete any account or access to the Site and Services at any time in our sole discretion.",
          },
          { type: "h4", text: "2. Ownership of {platformName}" },
          {
            type: "p",
            text: '2.1 As between you and {platformName}, the website and services are owned and/or provided by {platformName}. names, logos, trademarks, trade dress, arrangements, visual interfaces, graphics, designs, compilations, information, data, computer code (including source or object code), products, software, services and all other elements of the website, and services ( "Material") The content we provide is protected by intellectual property and other laws. All materials contained in the Site and Services are the property of {platformName} or its third-party licensors. You may only use the Site and Services in the manner expressly authorized by us and set forth in this TOS. You shall comply with and maintain all intellectual property notices, information and restrictions contained in the Site and Services. We reserve all rights to the Site and Services not expressly granted by these Terms of Service.',
          },
          {
            type: "p",
            text: '2.2 If you choose to provide comments and suggestions ("Feedback") regarding issues or proposed changes or improvements to the Site and Services, you hereby grant us an unrestricted, permanent, irrevocable, non-exclusive, full payment Royalties - Free right to use Feedback in any way and for any purpose, including improving the Site and Services and creating other products and services.',
          },
          {
            type: "p",
            text: "2.3 You allow us to disclose you as a customer in our promotional and marketing materials, including by name, corporate logo and trademark.",
          },
          {
            type: "p",
            text: '2.4 Certain materials provided by {platformName} are licensed under one or more open source, Creative Commons or similar licenses (collectively, the "Open Source Licenses"). Nothing in this TOS prevents, restricts, or is intended to prevent or restrict your access to such materials under applicable open source licenses, or your use of such materials under those open source licenses.',
          },
          {
            type: "p",
            text: "2.5 Subject to your full and ongoing compliance with these TOS and our rights therein, {platformName} grants you a limited, non-exclusive, non-assignable, non-sublicensable, revocable license to access and use as described in website and/or services, and are governed by these Terms of Service.",
          },
          { type: "h4", text: "3. Service Content" },
          {
            type: "p",
            text: '3.1 Our Services allow you to provide us with source code, files, software, processes, interfaces, data, text, settings, media or other information for the Services to store, host or process (your "Service Content"). Service content includes listing information and vendor offerings (as defined in the {platformName} Marketplace Terms and Conditions).',
          },
          {
            type: "p",
            text: "3.2 By providing your Service Content to or through the Service, you grant {platformName} a license to host, store, transmit, display, perform, reproduce, modify and distribute your Service for the purpose of formatted display only, in accordance with these Terms of Service The content is provided to you.",
          },
          {
            type: "p",
            text: "3.3 As between you and {platformName}, you retain any copyright and other proprietary rights you may have in the Service Content.",
          },
          {
            type: "p",
            text: '3.4 If you resell the services under TOS to third parties, or your own products and services (bound with TOS services) are allowed to be used by third parties through public websites, applications, and interfaces, then some TOS services will allow Third parties accessing, using or sharing your Service Content, such other users are collectively referred to as your "End Users" for the purposes of this TOS. For clarity, "end user" in this document includes all such other users, whether they are intermediaries, end users of other end users, etc. It is your responsibility to ensure that your end users comply with this TOS.',
          },
          {
            type: "p",
            text: "3.5 You are solely responsible for the content of your Service, your end users and any activities of your end users, and you agree that {platformName} will not and will not in any way be responsible for the content of your service, your end users and/or your end users' Responsible for the activity. By providing your service content through the service, you confirm, represent and warrant that:",
          },
          {
            type: "p",
            text: "Your Service Content and your or your End User's use of Your Service Content will not violate this TOS (including the AUP) or any applicable laws, regulations, rules or rights of third parties;",
          },
          {
            type: "p",
            text: "You are solely responsible for the development, review, operation, maintenance, support and use of your Service Content, including when Your Service Content is provided by your end users;",
          },
          {
            type: "p",
            text: "Your Service Content and your or your end users' use of Your Service Content will not and will not: (i) infringe, violate or misappropriate any third party rights, including any copyright, trademark, patent, trade secret, moral rights , privacy, publicity or any other intellectual property or proprietary rights; (ii) defame, defame, defame or violate any other person’s privacy, publicity or other property rights; (iii) cause us to violate any law, regulation, the rules or the rights of third parties; and Except for certain services provided to you under this TOS or other express contract, you are solely responsible for the technical operation of the content of your services, including on behalf of your end users.",
          },
          {
            type: "p",
            text: "3.6 It is your responsibility to properly configure and use the Services and to take your own steps to maintain the appropriate security, protection and backup of your Service Content, which may include the use of encryption technology to protect Your Service Content from unauthorized access As well as routine archiving of your services. {platformName} makes no commitment to retain any saves or backups of your Service Content. You are solely responsible for the integrity, preservation, and backup of your Service content, whether or not your use of the Service includes {platformName} backup features or functionality, and to the fullest extent permitted by law, {platformName} is not responsible for any loss of data and may not use, or other consequences related to the above.",
          },
          {
            type: "p",
            text: "3.7 When purchasing the Services, you may specify the geographic area in which your Services content will be stored. You agree to store your service content in and transfer your service content to a geographic area of ​​your choice. Unless you and {platformName} agree otherwise in writing, you agree that {platformName} may, at its sole discretion, transfer and store your Service content in other geographic areas. You represent and warrant that such storage and transfer have been given your full consent and are permitted in accordance with applicable laws, regulations, rules and third party rights.",
          },
          { type: "h4", text: "4. Code of Conduct" },
          {
            type: "p",
            text: "4.1 You must use the Site and Services in accordance with our AUP, which is incorporated by reference. Therefore, any use of the Site and Services in violation of the AUP constitutes a violation of this TOS.",
          },
          {
            type: "p",
            text: "4.2 You are solely responsible for activities that occur on your account, whether such activities are performed by you, your employees, any third party (including your contractors or agents), your end users, your licensees or your conducted by the customer.",
          },
          {
            type: "p",
            text: "4.3 You are responsible for informing your employees, agents and others in connection with your use of the Site and Services of the terms of this TOS, including where the terms of the TOS bind them.",
          },
          {
            type: "p",
            text: "4.4 You shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or otherwise attempt to obtain any source code or underlying ideas of any part of the Website or Services (including without limitation any Application) or algorithms, unless such restrictions are expressly prohibited by applicable law to the limited extent; (ii) modify, translate or otherwise create derivative works of any portion of the Site or Services; (iii) copy, rent, rent, distribute or otherwise transfer any rights you acquire under this Agreement. You shall comply with all applicable local, state, national and international laws and regulations. If any part of the Site or Service is governed by an open source license, such open source license applies regardless of this section.",
          },
          {
            type: "p",
            text: "4.5 We reserve the right to access, read, store and disclose any information we reasonably deem necessary to (i) respond to any applicable law, regulation, legal process or governmental request; (ii) enforce these Terms of Service, including investigating possible violations of these Terms of Service; Terms of Service; (iii) detect, prevent or otherwise resolve fraud, security or technical issues; (iv) respond to user support requests; (v) protect the rights, property or safety of us, our users and the public. More information can be found in our Law Enforcement Guide.",
          },
          {
            type: "p",
            text: "4.6 As a bonus to early adopters of the Service, some users with older accounts receive free bandwidth promotions, subject to their account remaining active, in good standing and complying with this TOS (“Free Bandwidth Account”). Free bandwidth accounts will no longer receive free bandwidth if: (a) ownership of such accounts is transferred to a third party; (b) use of such free bandwidth accounts violates this TOS (including the AUP); or © this Such free bandwidth accounts are used for any of the following activities: (i) to run Torrents for download or torrent servers, TOR, or services that contain adult or pornographic content; (ii) to resell or otherwise provide such free bandwidth as a service to third parties; (iii) otherwise circumvent or attempt to circumvent the intended use of free bandwidth accounts by redistributing the benefits of free bandwidth to third parties; (iii) accounts that have been logged out by users, or users who have lost their login passwords but have not The account was retrieved from {platformName} in time, and it became an invalid account due to not logging in for many years.",
          },
          {
            type: "p",
            text: "4.7 You should use appropriate security protocols, such as setting strong passwords and access control mechanisms, protecting access to all logins and passwords, and verifying the trustworthiness of those who access information on trusted accounts. You are solely responsible for any unauthorized access to your Account and must notify {platformName} immediately upon becoming aware of any such unauthorized access.",
          },
          {
            type: "p",
            text: "4.8 If you become aware of any security incident or breach affecting the Website or Services, including unauthorized access to your account or account credentials, you shall notify {platformName} and shall assist the authorities or any investigation or legal action taken by {platformName} to investigate and correct the cause. A security incident or breach resulting from your account or your use of the Site and Services. Our data processing agreement contains further information about security incidents related to certain personal data.",
          },
          { type: "h4", text: "5. Payment and billing" },
          {
            type: "p",
            text: '5.1 We use a third party payment processor ("Payment Processor") to bill you through the payment account associated with your account (your "Billing Information"). In addition to this TOS, payment processing may be subject to the terms, conditions and policies of the payment processor. We are not responsible for the actions or omissions of payment processors. You agree that in accordance with our Pricing and Billing Policy, and you hereby authorize us and the applicable payment processor to charge all such payments to you',
          },
          {
            type: "p",
            text: "5.2 You must provide your account and billing information with current, complete and accurate information and must promptly update all such information in the event of changes (such as changes to billing address, credit card number or credit card expiration date). If your payment method is cancelled (for example, lost or stolen) or otherwise unavailable, you must notify us or our payment processor immediately. This information can be changed in your account settings.",
          },
          {
            type: "p",
            text: "5.3 By entering into these Terms of Service and using the Services, you agree to recurring billing and automatic billing by us or our payment processor using your payment method at the time of invoicing. If your method of payment or payment of fees is subject to other terms and conditions set forth in an order, invoice or otherwise, those other terms and conditions apply in addition to this TOS. You may also be charged an amount not exceeding your current balance at any time to verify the accuracy of your account information. We reserve the right to deactivate, terminate, block access, disable the Services and/or delete any account or access to the Site and Services at any time in our sole discretion, including payment methods for non-payment, late payment or failure to bill you.",
          },
          {
            type: "p",
            text: "5.4 We may offer Service Credits from time to time and any provision for such Credits is governed by the disclosures, terms and policies regarding Credits. You acknowledge and agree that all credits, whether previously offered or otherwise, may be withdrawn or prematurely expired by us at any time for any reason or no reason, including abuse, misrepresentation of account information, unauthorized transfers or conduct you illegally.",
          },
          { type: "h4", text: "6. Third-party services" },
          {
            type: "p",
            text: 'The websites and services may include links to third-party websites, services or other resources on the Internet, and third-party websites, services or other resources may also include links to our websites and services. When you access third-party resources on the Internet, you do so at your own risk. These third-party resources are not under our control, and to the fullest extent permitted by law, we are not responsible or liable for the content, functionality, accuracy, legality, appropriateness or any other aspect of such resources. The inclusion of any such link does not imply our endorsement or any association between us and any third party. To the fullest extent permitted by law, we disclaim any direct or indirect responsibility or liability for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such third-party resources. We also allow certain vendors to make Vendor Products available on the {platformName} Marketplace ("Vendor" and "Vendor Product" are defined in the {platformName} Marketplace Terms and Conditions). Use of any vendor product may require you to agree to certain terms and conditions directly with the vendor, and {platformName} may not be a party to such agreement. It is your responsibility to protect your system from risks that may be posed by third-party resources or vendor products described in this section, such as viruses, worms, Trojan horses, and other security risks.',
          },
          { type: "h4", text: "7. Confidentiality and Testing Services" },
          {
            type: "p",
            text: "7.1 If you receive or possess any non-public information from us that is designated as confidential or reasonably should be understood to be confidential given the nature of the information or the circumstances surrounding its disclosure, then such information is our “confidential information”. ”” and must be handled in accordance with this TOS. Confidential information includes, but is not limited to: (a) non-public information about our technology, customers, business plans, marketing and sales activities, financial, operational and other business information; (b) ) the existence and content of our discussions or negotiations with you regarding the use of your account and/or services. Confidential Information does not include any information that is: (i) publicly or publicly available without breaching this TOS; (ii) ) can be documented by evidence that you were aware of our notice at the time of receipt; (iii) received from a third party that did not obtain or disclose the information through an unlawful or tortious act or violation of our or a third party's rights;",
          },
          {
            type: "p",
            text: "7.2 You shall not use Confidential Information except as permitted by these Terms of Service or separately authorized by us in writing in connection with your use of the Services. You agree not to disclose Confidential Information during the term of this TOS, after you stop using the Services, and after this TOS no longer applies. You will take all reasonable steps to avoid disclosure or unauthorized use of Confidential Information, including at least the steps you take to protect your own Confidential Information of a similar nature.",
          },
          {
            type: "p",
            text: '7.3 We may offer "beta versions" or features of the Services (each referred to as "Beta Services")). Any production candidate or non-production version of the Service or other version of the Service designated as a beta or beta version is considered a beta service under this TOS. We will determine the availability, duration, features and components of each Beta Service at our sole discretion. If we allow you to use the Beta Services, you agree that the information we provide in connection with the Beta Services is confidential and, in addition to other requirements set forth in this TOS, you specifically agree not to: (i) use for benchmarking or performance test or publicly disseminate performance information or analysis of the Beta Services from any source related to the Services; (ii) modify or create derivative works of the Beta Services or remove any product identification, proprietary, copyright or other notices contained in the Beta Services; ( iii) allow any other individual to access or use the Testing Services. We will continue to provide any Beta Services at our sole discretion and may discontinue any Beta Services at any time.',
          },
          { type: "h4", text: "8. No warranty" },
          {
            type: "p",
            text: '8.1 The Site and Services, including but not limited to any content delivered as part of it, are provided on an "as is" and "as available" basis. {platformName} disclaims all warranties of any kind, express or implied, with respect to the Site and Services and all content associated therewith, including, but not limited to: (a) merchantability, fitness for a particular purpose, title, quiet enjoyment, or non-infringement; (b) any warranties arising in the course of dealing, use or trade; © any warranties or guarantees relating to availability, accuracy, error rates, system integrity or uninterrupted access. We do not warrant that: (i) the website or service will be secure or available at any particular time or location; (ii) any defects or errors will be corrected; (iii) any content on or available through the website or service or the software is free of viruses or other harmful components; (iv) the results of using the website or services will meet your requirements. Your use of the Site and Services is entirely at your own risk.',
          },
          {
            type: "p",
            text: "To the maximum extent permitted by law, we disclaim all liability for supplier products. Instead, if applicable, all limited warranties, disclaimers, limitations of liability, and other specific terms regarding Supplier's products can be found in the {platformName} Marketplace Terms and Conditions or (if applicable) in the agreement between you and the Supplier.",
          },
          {
            type: "p",
            text: "8.2 {platformName} is not responsible for the content you access through your use of the Website, Services or the content of other users of {platformName} or other third parties. To the extent permitted by applicable law, you release us from all liability related to such content. You acknowledge and agree that we make no representations about any content contained in or accessed through the Site or the content of other users of {platformName} or other third parties, and we are not responsible for the accuracy, quality, legality or other attributes of or Obligation to such content.",
          },
          {
            type: "p",
            text: "8.3 {platformName} does not disclaim any warranties or other rights that applicable law prohibits {platformName} from disclaiming.",
          },
          { type: "h4", text: "9. Limitation of Liability" },
          {
            type: "p",
            text: "9.1 To the fullest extent permitted by law, in no event shall we, whether based on warranties, contract, tort (including negligence), statute or any other legal theory, and whether we have been advised of the possibility of damages. To the fullest extent permitted by law, you agree that we are not responsible for any data that may be destroyed, lost or otherwise inaccessible, whether because of your failure to back up the data or for any other reason.",
          },
          {
            type: "p",
            text: "9.2 Each provision in these Terms of Service that sets forth the limitation of liability, disclaimer of warranty or exclusion of damages is intended and does distribute risk among the parties under these Terms of Service. This allocation is an essential element of the basis of the transaction between you and us. Each of these terms is severable and independent of all other terms of this TOS. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.",
          },
          { type: "h4", text: "10. Indemnity" },
          {
            type: "p",
            text: "To the fullest extent permitted by law, you shall be responsible for your use of the Site and Services, and you shall defend, indemnify and hold harmless us and our employees, officers, directors, agents, contractors and representatives from all Liability, Claims, and Expenses, Including Reasonable Attorneys' Fees and Costs Arising from or Related to Your Service Content, User Content, Your End Users, or Your Access to or Use of the Site and Services, including your breach of this TOS or applicable Law, willful misconduct, negligence, illegal activity, breach of security or data, unauthorized access or use of your account, or violation of third party rights, including any intellectual property, confidentiality, property or privacy rights. We reserve the right to the exclusive defense and control of any matter for which you are required to indemnify, without limiting your obligation to indemnify such matter, in which case you will use your best efforts to assist and cooperate with us in defending that matter at your own expense.",
          },
          { type: "h4", text: "11. Termination" },
          {
            type: "p",
            text: "While we would prefer to give earlier notice of termination, we reserve the right, in our sole discretion, to terminate your access to all or any part of the Site and/or Services at any time, with or without notice, effective immediately. Any such termination may result in the immediate forfeiture and destruction of data associated with your account. Unless otherwise provided in this Agreement or expressly agreed by the parties, any and all fees paid to us are non-refundable and any and all fees still owed to us at the time of termination shall be immediately due and payable. Upon termination, any and all rights granted to you by these Terms of Service will immediately cease and you must also immediately cease all use of the Site and/or Services.",
          },
          { type: "h4", text: "12. Miscellaneous Provisions" },
          {
            type: "p",
            text: `12.1 Updates and Modifications. {platformName} reserves the right to update, change or otherwise modify this TOS at any time in {platformName}'s sole discretion. If {platformName} updates this TOS, {platformName} may provide notice of such update, such as by sending an email notification to the email address listed in your account and/or updating the "last updated" date at the beginning of this TOS. Updates will take effect on the date specified in the notice. By continuing to access or use the Site or Services after the date specified in the notice or updated Terms of Service, you agree to accept and be bound by the updated Terms of Service and all terms contained therein. If you do not agree to the updated TOS,`,
          },
          {
            type: "p",
            text: '12.2 General. These Terms of Service, including all documents expressly incorporated herein by reference, constitute the entire agreement between the parties and supersede all prior and contemporaneous agreements, proposals or representations, written or oral, with respect to its subject matter. The use of section headings in this TOS is for convenience only and will not have any effect on the interpretation of any provision. Any use of the singular should reasonably be construed as if it also included the plural and vice versa. Any use of "including" or "for example" should be construed as "including but not limited to" if not expressly stated otherwise. If any part of this TOS is found to be invalid or unenforceable, the unenforceable part will be effective to the greatest possible extent and the remainder will remain in full force and effect.',
          },
          {
            type: "p",
            text: "12.3 Assignment. You may not assign, assign or delegate this TOS or any of your rights and obligations under the TOS, in whole or in part, by law or otherwise, without our prior written consent. We may assign, assign or delegate this TOS or our rights and obligations under this TOS without notice or consent. Subject to this section, this TOS will be binding on and inure to the benefit of the parties and their respective permitted successors and assigns, and any assignment or assignment in violation of this section will be void.",
          },
          {
            type: "p",
            text: "12.4 Consent to Electronic Communications. By using the Site and/or Services, you agree to receive certain electronic communications from us, as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communication practices. You agree that any notices, agreements, disclosures or other communications that we send you electronically will satisfy any legal communication requirements, including that such communications are in writing.",
          },
          {
            type: "p",
            text: "12.5 Relationship of the Parties. The parties are independent contractors. These Terms of Service do not create a partnership, franchise, joint venture, agency, trust or employment relationship between the parties. Neither party nor any of their respective affiliates is an agent of the other party and has no authority or authority to bind the other party for any purpose.",
          },
          {
            type: "p",
            text: "12.6 Force Majeure. If {platformName} is subject to any act or event beyond {platformName}'s reasonable control (including but not limited to acts of God, war, unrest or riot, strike, any action of a government entity, weather, quarantine, fire, flood, earthquake, explosion, utility or telecommunications outages, internet disruptions, epidemics, epidemics or any unforeseen change in circumstances, or any other cause beyond our reasonable control.",
          },
          {
            type: "p",
            text: "12.7 No Third Party Beneficiaries. This TOS does not create any third party beneficial rights to any person or entity that is not a party to this TOS.",
          },
          {
            type: "p",
            text: "12.8 Government Use. {platformName} provides the website and services, including related software and technology, for ultimate federal use only in accordance with the following: Government technical data and software rights associated with the website and services include only those rights customarily made available to the public, such as these Terms of Service.",
          },
          {
            type: "p",
            text: "Copyright 2020 {platformName}, LLC. all rights reserved. No part of the {platformName} website may be reproduced, modified or distributed in any form or by any means without the prior written permission of {platformName}.",
          },
        ],
        "zh-CN": [
          { type: "h4", text: "一、资格和注册" },
          {
            type: "p",
            text: "1.1 在您使用我们的网站和服务之前，您必须确保此类使用符合适用于您的所有法律、规则和法规。如果您的使用被禁止或我们的规定与任何适用的法律、规则或法规相冲突，您访问网站和服务的权利将被撤销。您有责任在使用网站和服务之前做出这些决定。",
          },
          {
            type: "p",
            text: "1.2 网站和服务不针对18周岁以下的任何人，也不打算供其使用。通过使用网站和服务，您向我们声明并保证：(a)您已年满18周岁；(b)您在其他方面拥有充分的法律同意、许可和能力，可以在您确定的适用司法管辖区使用网站和服务。",
          },
          {
            type: "p",
            text: "1.3 要访问服务和网站的某些功能，您必须注册一个帐户（“帐户”）。当您注册您的帐户时，您可能需要向我们提供一些关于您自己的信息，例如您的姓名、电子邮件地址和有效的付款方式，您还可以自愿提供有关您自己的可选信息。帐户信息以及我们对其的使用和披露均受隐私政策和数据处理协议的约束。",
          },
          {
            type: "p",
            text: "1.4 我们可以自行决定拒绝向任何个人或实体提供或继续提供网站和服务，并随时更改资格标准，包括如果您未能遵守TOS。我们保留随时自行决定停用、终止、阻止访问、禁用服务或删除任何帐户或访问网站和服务的权利。",
          },
          { type: "h4", text: "二、{platformName}的所有权" },
          {
            type: "p",
            text: "2.1 在您和{platformName}之间，网站和服务由{platformName}拥有和提供。名称、徽标、商标、商业外观、安排、视觉界面、图形、设计、编译、信息、数据、计算机代码（包括源代码或目标代码）、产品、软件、服务和网站的所有其他元素，以及服务（“材料”)我们提供的内容受知识产权和其他法律的保护。网站和服务中包含的所有材料均为{platformName}或其第三方许可方的财产。您只能按照我们明确授权并在本TOS中规定的方式使用网站和服务。您应遵守并维护网站和服务中包含的所有知识产权通知、信息和限制。我们保留本服务条款未明确授予的网站和服务的所有权利。",
          },
          {
            type: "p",
            text: "2.2 如果您选择就网站和服务的问题或提议的修改或改进提供意见和建议（“反馈”），那么您特此授予我们不受限制的、永久的、不可撤销的、非排他性的、全额支付的版税-以任何方式和任何目的利用反馈的自由权利，包括改进网站和服务以及创建其他产品和服务。",
          },
          {
            type: "p",
            text: "2.3 您允许我们在我们的促销和营销材料将您作为客户进行披露，包含以名称、企业徽标和通讯商标的方式对客户进行披露。",
          },
          {
            type: "p",
            text: "2.4 {platformName}提供的某些材料是根据一个或多个开源、知识共享或类似许可（统称为“开源许可”）获得许可的。本TOS中的任何内容均不阻止、限制或旨在阻止或限制您根据适用的开源许可证获取此类材料，或限制您根据这些开源许可证使用此类材料。",
          },
          {
            type: "p",
            text: "2.5 在您完全且持续遵守本TOS和我们在其中的权利的前提下，{platformName}授予您有限的、非排他性的、不可转让的、不可再许可的、可撤销的许可，以访问和使用中所述的网站和/或服务，以及受本服务条款约束。",
          },
          { type: "h4", text: "三、服务内容" },
          {
            type: "p",
            text: "3.1 我们的服务允许您向我们提供源代码、文件、软件、流程、接口、数据、文本、设置、媒体或其他信息以供服务存储、托管或处理（您的“服务内容”）。服务内容包括列表信息和供应商产品（定义见{platformName}市场条款和条件）。",
          },
          {
            type: "p",
            text: "3.2 根据本服务条款，通过向服务或通过服务提供您的服务内容，您授予{platformName}许可，以仅出于格式化显示的目的托管、存储、传输、显示、执行、复制、修改和分发您的服务内容为向您提供服务。",
          },
          {
            type: "p",
            text: "3.3 在您和{platformName}之间，您保留您在服务内容中可能拥有的任何版权和其他所有权。",
          },
          {
            type: "p",
            text: "3.4 如果您将TOS项下的服务转售给第三方，或者您自己的产品和服务（与TOS服务绑定）通过公众的网站、应用程序、界面允许第三方使用，那么TOS某些服务将允许第三方访问、使用或共享您的服务内容，就本TOS而言，此类其他用户统称为您的“最终用户”。为清楚起见，本文中的“最终用户”包括所有此类其他用户，无论他们是中间方、其他最终用户的最终用户等。您有责任确保您的最终用户遵守本TOS。",
          },
          {
            type: "p",
            text: "3.5 您对您的服务内容、最终用户和您的最终用户的任何活动负全部责任，并且您同意{platformName}不会也不会以任何方式对您的服务内容、最终用户和/或您的最终用户的活动负表现形式。通过服务提供您的服务内容，即表示您确认、声明并保证：",
          },
          {
            type: "p",
            text: "您的服务内容以及您或您的最终用户对您的服务内容的使用不会违反本TOS（包括AUP）或任何适用的法律、法规、规则或第三方权利；",
          },
          {
            type: "p",
            text: "您全权负责您的服务内容的开发、审核、操作、维护、支持和使用，包括您的服务内容由您的最终用户提供时；",
          },
          {
            type: "p",
            text: "您的服务内容以及您或您的最终用户对您的服务内容的使用不会也不会：(i)侵犯、违反或盗用任何第三方权利，包括任何版权、商标、专利、商业秘密、精神权利、隐私权、公开权或任何其他知识产权或专有权利；(ii)诽谤、诽谤、诽谤或侵犯任何其他人的隐私权、公开权或其他财产权；(iii)导致我们违反任何法律、法规、规则或第三方的权利；和除了根据本TOS或其他明示合同向您提供的特定服务外，您全权负责您的服务内容的技术运营，包括代表您的最终用户。",
          },
          {
            type: "p",
            text: "3.6 您有责任正确配置和使用服务，并采取您自己的步骤来维护您的服务内容的适当安全性、保护和备份，其中可能包括使用加密技术来保护您的服务内容免遭未经授权的访问以及对您的服务进行例行存档内容。{platformName}不承诺保留您的服务内容的任何保存或备份。您对您的服务内容的完整性、保存和备份全权负责，无论您对服务的使用是否包括{platformName}备份特性或功能，并且在法律允许的最大范围内，{platformName}对任何数据丢失不承担任何责任，不可用，或与上述相关的其他后果。",
          },
          {
            type: "p",
            text: "3.7 购买服务时，您可以指定存储您的服务内容的地理区域。您同意将您的服务内容存储在您选择的地理区域，并将您的服务内容转移到您选择的地理区域。除非您与{platformName}另有书面约定，否则您同意{platformName}可自行决定将您的服务内容转移和存储在其他地理区域。您声明并保证，此类存储和转移已得到您的充分同意，并且根据适用的法律、法规、规则和第三方权利是允许的。",
          },
          { type: "h4", text: "四、行为准则" },
          {
            type: "p",
            text: "4.1 您必须按照我们的AUP使用网站和服务，该AUP以引用方式并入。因此，任何违反AUP的网站和服务使用均构成对本TOS的违反。",
          },
          {
            type: "p",
            text: "4.2 您对您帐户上发生的活动负全部责任，无论这些活动是由您、您的员工、任何第三方（包括您的承包商或代理）、您的最终用户、您的被许可人还是您的客户进行的。",
          },
          {
            type: "p",
            text: "4.3 您有责任将本TOS的规定通知您的员工、代理人和其他与您使用网站和服务有关的人，包括TOS的条款对他们有约束力的地方。",
          },
          {
            type: "p",
            text: "4.4 您不得（直接或间接）：(i)破译、反编译、反汇编、逆向工程或以其他方式试图获取网站或服务（包括但不限于任何应用程序）任何部分的任何源代码或基础思想或算法，除非适用法律在有限范围内明确禁止此类限制；(ii)修改、翻译或以其他方式创建网站或服务任何部分的衍生作品；(iii)复制、出租、出租、分发或以其他方式转让您在本协议项下获得的任何权利。您应遵守所有适用的地方、州、国家和国际法律法规。如果网站或服务的任何部分受开源许可证约束，则无论本节如何，此类开源许可证均适用。",
          },
          {
            type: "p",
            text: "4.5 我们保留访问、阅读、保存和披露我们合理认为对(i)响应任何适用法律、法规、法律程序或政府要求所必需的任何信息的权利；(ii)执行本服务条款，包括调查可能违反本服务条款的行为；(iii)检测、预防或以其他方式解决欺诈、安全或技术问题；(iv)响应用户支持请求；(v)保护我们、我们的用户和公众的权利、财产或安全。更多信息可在我们的执法指南中找到。",
          },
          {
            type: "p",
            text: "4.6 作为对服务的早期采用者的奖励，一些具有较旧帐户的用户收到免费带宽促销，这取决于他们的帐户保持有效、信誉良好并遵守本TOS（“免费带宽帐户”）。如果出现以下情况，免费带宽帐户将不再获得免费带宽：(a)此类帐户的所有权转让给第三方；(b)此类免费带宽帐户的使用违反了本TOS（包括AUP）；或©此类免费带宽帐户用于以下任何活动：(i)运行Torrent以供下载或种子服务器、TOR或包含成人或色情内容的服务；(ii)将此类免费带宽转售或以其他方式作为服务提供给第三方；(iii)通过将免费带宽的好处重新分配给第三方，以其他方式规避或试图规避免费带宽帐户的预期用途；(iiii)已被用户注销的帐户、或用户遗失登陆密码但长期未向{platformName}及时找回、因长年未登录而成为无效帐户的。",
          },
          {
            type: "p",
            text: "4.7 您应使用适当的安全协议，例如设置强密码和访问控制机制，保护对所有登录名和密码的访问，以及验证受托帐户访问信息的人员的可信度。您对任何未经授权访问您的帐户负全部责任，并且必须在得知任何此类未经授权的访问后立即通知{platformName}。",
          },
          {
            type: "p",
            text: "4.8 如果您获悉任何影响网站或服务的安全事件或违规行为，包括未经授权访问您的帐户或帐户凭据，您应通知{platformName}，并应协助当局或{platformName}采取的任何调查或法律行动调查和纠正因您的帐户或您对网站和服务的使用造成的安全事件或违规行为。我们的数据处理协议包含有关与某些个人数据相关的安全事件的更多信息。",
          },
          { type: "h4", text: "五、付款和计费" },
          {
            type: "p",
            text: "5.1 我们使用第三方支付处理器（“支付处理器”）通过与您的账户（您的“账单信息”）关联的支付账户向您收费。除本TOS外，付款处理可能还需遵守付款处理方的条款、条件和政策。我们不对支付处理方的作为或不作为负责。您同意根据我们的定价和计费政策，并且您在此授权我们和适用的付款处理方将所有此类款项收取到您的帐户中指定或链接到您的帐户（您的“付款方式”）的付款方式。",
          },
          {
            type: "p",
            text: "5.2 您必须为您的账户和账单信息提供最新、完整和准确的信息，并且在发生变更（例如账单地址、信用卡号或信用卡到期日变更）时必须及时更新所有此类信息。如果您的付款方式被取消（例如，丢失或被盗）或因其他原因无法使用，您必须立即通知我们或我们的付款处理方。可以在您的帐户设置中更改此类信息。",
          },
          {
            type: "p",
            text: "5.3 签订本服务条款并使用服务，即表示您同意定期计费，并由我们或我们的支付处理商在开具发票时使用您的付款方式自动收取费用。如果您的付款方式或费用支付受订单、发票或其他内容中规定的其他条款和条件的约束，则除本TOS外，这些其他条款和条件也适用。您也可能随时被收取不超过您当前余额的金额，以验证您的帐户信息的准确性。我们保留随时自行决定停用、终止、阻止访问、禁用服务和/或删除任何帐户或访问网站和服务的权利，包括未付款、逾期付款或未能向您收取费用开票时的付款方式。",
          },
          {
            type: "p",
            text: "5.4 我们可能会不时提供服务积分，此类积分的任何规定均受有关积分的披露、条款和政策的约束。您承认并同意，所有信用额度，无论是以前提供的还是其他方式，都可以在任何时候以任何理由或没有理由被我们撤销或提前到期，包括滥用、虚假陈述账户信息、未经授权的转移或非法行为你。",
          },
          { type: "h4", text: "六、第三方服务" },
          {
            type: "p",
            text: "网站和服务可能包括指向第三方网站、服务或互联网上其他资源的链接，第三方网站、服务或其他资源也可能包括指向我们网站和服务的链接。当您访问Internet上的第三方资源时，您需要自行承担风险。这些第三方资源不受我们控制，并且在法律允许的最大范围内，我们对此类资源的内容、功能、准确性、合法性、适当性或任何其他方面不承担任何责任或义务。包含任何此类链接并不意味着我们的认可或我们与任何第三方之间的任何关联。在法律允许的最大范围内，我们不承担任何直接或间接的责任或义务，因使用或依赖任何此类第三方资源而造成或据称造成或与之相关的任何损害或损失。我们还允许某些供应商在{platformName}市场上提供可用的供应商产品（“供应商”和“供应商产品”在{platformName}市场条款和条件中定义）。使用任何供应商产品可能需要您直接与供应商同意某些条款和条件，{platformName}可能不是此类协议的一方。您有责任保护您的系统免受本节所述的第三方资源或供应商产品可能带来的风险，例如病毒、蠕虫、特洛伊木马和其他安全风险。",
          },
          { type: "h4", text: "七、保密和测试服务" },
          {
            type: "p",
            text: "7.1 如果您从我们那里收到或拥有任何被指定为机密的非公开信息，或者鉴于信息的性质或围绕其披露的情况，合理地应理解为机密信息，则此类信息是我们的“机密信息””，并且必须按照本TOS进行处理。机密信息包括但不限于：(a)关于我们的技术、客户、商业计划、营销和销售活动、财务、运营和其他商业信息的非公开信息；(b)我们与您就您的帐户和/或服务的使用进行的讨论或谈判的存在和内容。机密信息不包括以下任何信息：(i)在不违反本TOS的情况下公开或公开提供；(ii)可以通过文件证明您在收到我们的通知时已经知道；(iii)从没有通过不法或侵权行为或侵犯我们或第三方的权利获取或披露信息的第三方收到；",
          },
          {
            type: "p",
            text: "7.2 您不得使用机密信息，除非本服务条款允许或我们以书面形式单独授权与您使用服务有关。您同意在本TOS期限内、在您停止使用服务之后以及在本TOS不再适用之后不披露机密信息。您将采取一切合理措施避免披露或未经授权使用机密信息，至少包括您为保护自己的类似性质的机密信息而采取的措施。",
          },
          {
            type: "p",
            text: "7.3 我们可能会提供服务的“测试版”或功能（每一个都称为“测试版服务”））。服务的任何生产候选版本或非生产版本或指定为测试版或测试版的服务的其他版本均被视为本TOS下的测试版服务。我们将自行决定每个Beta服务的可用性、持续时间、功能和组件。如果我们允许您使用Beta服务，则您同意我们提供的与Beta服务相关的信息是机密信息，并且除了本TOS中规定的其他要求外，您特别同意不：(i)使用用于基准测试或性能测试或从与服务相关的任何来源公开传播性能信息或分析的Beta服务；(ii)修改或创建Beta服务的衍生作品或删除任何产品标识、专有、Beta服务中包含的版权或其他通知；(iii)允许任何其他个人访问或使用测试服务。我们将自行决定是否继续提供任何Beta服务，并可能随时停止提供任何Beta服务。",
          },
          { type: "h4", text: "八、无保修" },
          {
            type: "p",
            text: "8.1 网站和服务，包括但不限于作为其一部分交付的任何内容，均按“原样”和“可用”基础提供。{platformName}不承担与网站和服务以及与之相关的所有内容相关的任何类型的明示或暗示保证，包括但不限于：(a)对适销性、特定用途适用性、所有权、安静享受，或不侵权；(b)在交易、使用或贸易过程中产生的任何保证；©与可用性、准确性、错误率、系统完整性或不间断访问有关的任何保证或保证。我们不保证：(i)网站或服务在任何特定时间或地点是安全的或可用的；(ii)任何缺陷或错误将得到纠正；(iii)网站或服务上或通过网站或服务提供的任何内容或软件没有病毒或其他有害成分；(iv)使用网站或服务的结果将满足您的要求。您对网站和服务的使用完全由您自己承担风险。",
          },
          {
            type: "p",
            text: "在法律允许的最大范围内，我们对供应商产品不承担任何责任。相反，如果适用，有关供应商产品的所有有限保证、免责声明、责任限制和其他特定条款都可以在{platformName}市场条款和条件或（如果适用）您与供应商之间的协议中找到。",
          },
          {
            type: "p",
            text: "8.2 {platformName}不对您通过使用网站、服务访问的内容或{platformName}的其他用户或其他第三方的内容负责。在适用法律允许的范围内，您免除我们与此类内容相关的所有责任。您承认并同意，我们不对网站中包含或通过网站访问的任何内容或{platformName}或其他第三方的其他用户的内容作出任何陈述，并且我们对准确性、质量、合法性或其他属性不承担任何责任或义务这样的内容。",
          },
          {
            type: "p",
            text: "8.3 {platformName}不否认适用法律禁止{platformName}放弃的任何保证或其他权利。",
          },
          { type: "h4", text: "九、责任限制" },
          {
            type: "p",
            text: "9.1 在法律允许的最大范围内，在任何情况下，我们均不对您因或与您访问或使用或无法访问或使用网站和服务或网站和服务上的任何材料或内容有关，无论是基于保证、合同、侵权（包括疏忽）、法规或任何其他法律理论，以及我们是否被告知损害的可能性。在法律允许的最大范围内，您同意我们对任何可能被破坏、丢失或以其他方式无法访问的数据不承担任何责任，无论是因为您未能备份数据还是出于任何其他原因。",
          },
          {
            type: "p",
            text: "9.2 本服务条款中规定责任限制、保证免责声明或损害排除的每项条款旨在并确实在本服务条款下的各方之间分配风险。这种分配是您和我们之间交易基础的基本要素。这些条款中的每一项都是可分割的，并且独立于本TOS的所有其他条款。即使任何有限的补救措施未能达到其基本目的，本节中的限制也将适用。",
          },
          { type: "h4", text: "十、赔偿" },
          {
            type: "p",
            text: "在法律允许的最大范围内，您应对您对网站和服务的使用负责，并且您应为我们和我们的员工、高级职员、董事、代理人、承包商和代表辩护、赔偿并使我们免受所有责任、索赔，以及因您的服务内容、用户内容、您的最终用户或您访问或使用网站和服务而产生或与之相关的费用，包括合理的律师费和成本，包括您违反本TOS或适用法律、故意不当行为、疏忽、非法活动、违反安全或数据、未经授权访问或使用您的帐户，或侵犯第三方权利，包括任何知识产权、保密、财产或隐私权。我们保留对您需要赔偿的任何事项进行独家辩护和控制的权利，但不限制您对该事项的赔偿义务，在这种情况下，您将尽最大努力协助和配合我们为该事项辩护自费。",
          },
          { type: "h4", text: "十一、终止" },
          {
            type: "p",
            text: "虽然我们更愿意提前发出终止通知，但我们保留自行决定随时终止您访问网站和/或服务的全部或任何部分的权利，无论是否通知，立即生效。任何此类终止都可能导致与您的帐户相关的数据立即被没收和销毁。除非本协议另有规定或双方明确约定，否则支付给我们的任何和所有费用均不予退还，并且在终止时仍欠我们的任何和所有费用应立即到期应付。终止后，本服务条款授予您的任何和所有权利将立即终止，您还必须立即停止对网站和/或服务的所有使用。",
          },
          { type: "h4", text: "十二、杂项规定" },
          {
            type: "p",
            text: "12.1 更新和修改。{platformName}保留在任何时候由{platformName}自行决定更新、更改或以其他方式修改本TOS的权利。如果{platformName}更新本TOS，{platformName}可能会提供此类更新的通知，例如通过向您帐户中列出的电子邮件地址发送电子邮件通知和/或更新本TOS开头的“最后更新”日期。更新将在通知中指定的日期生效。在通知或更新服务条款中指定的日期之后继续访问或使用网站或服务，即表示您同意接受更新后的服务条款和其中包含的所有条款并受其约束。如果您不同意更新后的TOS，",
          },
          {
            type: "p",
            text: "12.2 一般。本服务条款，包括通过引用明确并入本文的所有文件，构成双方之间的完整协议，并取代所有先前和同时期关于其主题的书面或口头协议、建议或陈述。在本TOS中使用章节标题只是为了方便，不会对任何条款的解释产生任何影响。单数的任何使用都应被合理地解释为好像它也包括复数一样，反之亦然。如果没有明确说明，“包括”或“例如”的任何使用均应解释为“包括但不限于”。如果本TOS的任何部分被认定为无效或不可执行，则不可执行的部分将在最大可能的范围内生效，其余部分将保持完全有效。",
          },
          {
            type: "p",
            text: "12.3 分配。未经我们事先书面同意，您不得通过法律或其他方式全部或部分转让、转让或委托本TOS或您在TOS项下的任何权利和义务。我们可以在不通知或同意的情况下转让、转让或委托本TOS或我们在本TOS项下的权利和义务。根据本节，本TOS将对各方及其各自允许的继承人和受让人具有约束力并符合其利益，任何违反本节的转让或转让均无效。",
          },
          {
            type: "p",
            text: "12.4 同意电子通讯。通过使用网站和/或服务，您同意接收我们的某些电子通信，如我们的隐私政策中进一步描述的那样。请阅读我们的隐私政策以了解更多关于我们的电子通信实践的信息。您同意我们以电子方式发送给您的任何通知、协议、披露或其他通信将满足任何法律通信要求，包括这些通信是书面的。",
          },
          {
            type: "p",
            text: "12.5 双方的关系。各方为独立承包商。本服务条款不会在双方之间建立合伙、特许经营、合资、代理、信托或雇佣关系。任何一方或其各自的任何附属公司均不是另一方的代理人，出于任何目的或无权约束另一方。",
          },
          {
            type: "p",
            text: "12.6 不可抗力。如果{platformName}因发生超出{platformName}合理控制的任何行为或事件（包括但不限于天灾、战争、动乱或暴动、罢工、政府实体的任何行动、天气、检疫、火灾、洪水、地震、爆炸、公用事业或电信中断、互联网干扰、流行病、流行病或任何不可预见的情况变化，或超出我们合理控制范围的任何其他原因。",
          },
          {
            type: "p",
            text: "12.7 无第三方受益人。本TOS不会对非本TOS一方的任何个人或实体产生任何第三方受益权。",
          },
          {
            type: "p",
            text: "12.8 政府使用。{platformName}提供网站和服务，包括相关软件和技术，最终联邦政府最终使用仅按照以下规定：与网站和服务相关的政府技术数据和软件权利仅包括按照惯例向公众提供的权利，如本服务条款。",
          },
          {
            type: "p",
            text: "版权所有2020{platformName},LLC。版权所有。未经{platformName}事先书面许可，不得以任何形式或方式复制、修改或分发{platformName}网站的任何部分。",
          },
        ],
        "zh-HK": [
          { type: "h4", text: "一、資格和注册" },
          {
            type: "p",
            text: "1.1 在您使用我們的網站和服務之前，您必須確保此類使用符合適用於您的所有法律、規則和法規。 如果您的使用被禁止或我們的規定與任何適用的法律、規則或法規相衝突，您訪問網站和服務的權利將被撤銷。 您有責任在使用網站和服務之前做出這些决定。",
          },
          {
            type: "p",
            text: "1.2 網站和服務不針對18周歲以下的任何人，也不打算供其使用。 通過使用網站和服務，您向我們聲明並保證：（a）您已年滿18周歲； （b）您在其他方面擁有充分的法律同意、許可和能力，可以在您確定的適用司法管轄區使用網站和服務。",
          },
          {
            type: "p",
            text: "1.3 要訪問服務和網站的某些功能，您必須注册一個帳戶（“帳戶”）。 當您注册您的帳戶時，您可能需要向我們提供一些關於您自己的資訊，例如您的姓名、電子郵寄地址和有效的付款方式，您還可以自願提供有關您自己的可選資訊。 帳戶資訊以及我們對其的使用和披露均受隱私政策和資料處理協定的約束。",
          },
          {
            type: "p",
            text: "1.4 我們可以自行决定拒絕向任何個人或實體提供或繼續提供網站和服務，並隨時更改資格標準，包括如果您未能遵守TOS。 我們保留隨時自行决定停用、終止、封锁訪問、禁用服務或删除任何帳戶或訪問網站和服務的權利。",
          },
          { type: "h4", text: "二、{platformName}的所有權" },
          {
            type: "p",
            text: "2.1 在您和{platformName}之間，網站和服務由{platformName}擁有和提供。 名稱、徽標、商標、商業外觀、安排、視覺介面、圖形、設計、編譯、資訊、數據、電腦程式碼（包括原始程式碼或目標程式碼）、產品、軟件、服務和網站的所有其他元素，以及服務（“資料”）我們提供的內容受知識產權和其他法律的保護。 網站和服務中包含的所有資料均為{platformName}或其協力廠商許可方的財產。 您只能按照我們明確授權並在本TOS中規定的管道使用網站和服務。 您應遵守並維護網站和服務中包含的所有知識產權通知、資訊和限制。 我們保留本服務條款未明確授予的網站和服務的所有權利。",
          },
          {
            type: "p",
            text: "2.2 如果您選擇就網站和服務的問題或提議的修改或改進提供意見和建議（“迴響”），那麼您特此授予我們不受限制的、永久的、不可撤銷的、非排他性的、全額支付的版稅-以任何管道和任何目的利用迴響的自由權利，包括改進網站和服務以及創建其他產品和服務。",
          },
          {
            type: "p",
            text: "2.3 您允許我們在我們的促銷和行銷資料將您作為客戶進行披露，包含以名稱、企業徽標和通訊商標的管道對客戶進行披露。",
          },
          {
            type: "p",
            text: "2.4 {platformName}提供的某些資料是根據一個或多個開源、知識共享或類似許可（統稱為“開源許可”）獲得許可的。 本TOS中的任何內容均不封锁、限制或旨在封锁或限制您根據適用的開源許可證獲取此類資料，或限制您根據這些開源許可證使用此類資料。",
          },
          {
            type: "p",
            text: "2.5 在您完全且持續遵守本TOS和我們在其中的權利的前提下，{platformName}授予您有限的、非排他性的、不可轉讓的、不可再許可的、可撤銷的許可，以訪問和使用中所述的網站和/或服務，以及受本服務條款約束。",
          },
          { type: "h4", text: "三、服務內容" },
          {
            type: "p",
            text: "3.1 我們的服務允許您向我們提供原始程式碼、檔案、軟件、流程、介面、數據、文字、設定、媒體或其他資訊以供服務存儲、託管或處理（您的“服務內容”）。 服務內容包括清單資訊和供應商產品（定義見{platformName}市場條款和條件）。",
          },
          {
            type: "p",
            text: "3.2 根據本服務條款，通過向服務或通過服務提供您的服務內容，您授予{platformName}許可，以僅出於格式化顯示的目的託管、存儲、傳輸、顯示、執行、複製、修改和分發您的服務內容為向您提供服務。",
          },
          {
            type: "p",
            text: "3.3 在您和{platformName}之間，您保留您在服務內容中可能擁有的任何版權和其他所有權。",
          },
          {
            type: "p",
            text: "3.4 如果您將TOS項下的服務轉售給協力廠商，或者您自己的產品和服務（與TOS服務綁定）通過公眾的網站、應用程序、介面允許協力廠商使用，那麼TOS某些服務將允許協力廠商訪問、使用或共亯您的服務內容，就本TOS而言，此類其他用戶統稱為您的“最終用戶”。 為清楚起見，本文中的“最終用戶”包括所有此類其他用戶，無論他們是中間方、其他最終用戶的最終用戶等。 您有責任確保您的最終用戶遵守本TOS。",
          },
          {
            type: "p",
            text: "3.5 您對您的服務內容、最終用戶和您的最終用戶的任何活動負全部責任，並且您同意{platformName}不會也不會以任何管道對您的服務內容、最終用戶和/或您的最終用戶的活動負表現形式。 通過服務提供您的服務內容，即表示您確認、聲明並保證：",
          },
          {
            type: "p",
            text: "您的服務內容以及您或您的最終用戶對您的服務內容的使用不會違反本TOS（包括AUP）或任何適用的法律、法規、規則或協力廠商權利；",
          },
          {
            type: "p",
            text: "您全權負責您的服務內容的開發、稽核、操作、維護、支持和使用，包括您的服務內容由您的最終用戶提供時；",
          },
          {
            type: "p",
            text: "您的服務內容以及您或您的最終用戶對您的服務內容的使用不會也不會：（i）侵犯、違反或盜用任何協力廠商權利，包括任何版權、商標、專利、商業秘密、精神權利、隱私權、公開權或任何其他知識產權或專有權利；",
          },
          {
            type: "p",
            text: "3.6 您有責任正確配寘和使用服務，並採取您自己的步驟來維護您的服務內容的適當安全性、保護和備份，其中可能包括使用加密技術來保護您的服務內容免遭未經授權的訪問以及對您的服務進行例行存檔內容。 {platformName}不承諾保留您的服務內容的任何保存或備份。 您對您的服務內容的完整性、保存和備份全權負責，無論您對服務的使用是否包括{platformName}備份特性或功能，並且在法律允許的最大範圍內，{platformName}對任何資料丟失不承擔任何責任，不可用，或與上述相關的其他後果。",
          },
          {
            type: "p",
            text: "3.7 購買服務時，您可以指定存儲您的服務內容的地理區域。 您同意將您的服務內容存儲在您選擇的地理區域，並將您的服務內容轉移到您選擇的地理區域。 除非您與{platformName}另有書面約定，否則您同意{platformName}可自行决定將您的服務內容轉移和存儲在其他地理區域。 您聲明並保證，此類存儲和轉移已得到您的充分同意，並且根據適用的法律、法規、規則和協力廠商權利是允許的。",
          },
          { type: "h4", text: "四、行為準則" },
          {
            type: "p",
            text: "4.1 您必須按照我們的AUP使用網站和服務，該AUP以引用管道併入。 囙此，任何違反AUP的網站和服務使用均構成對本TOS的違反。",
          },
          {
            type: "p",
            text: "4.2 您對您帳戶上發生的活動負全部責任，無論這些活動是由您、您的員工、任何協力廠商（包括您的承包商或代理）、您的最終用戶、您的被許可人還是您的客戶進行的。",
          },
          {
            type: "p",
            text: "4.3 您有責任將本TOS的規定通知您的員工、代理人和其他與您使用網站和服務有關的人，包括TOS的條款對他們有約束力的地方。",
          },
          {
            type: "p",
            text: "4.4 您不得（直接或間接）：（i）破譯、反編譯、反彙編、逆向工程或以其他管道試圖獲取網站或服務（包括但不限於任何應用程序）任何部分的任何原始程式碼或基礎思想或算灋，除非適用法律在有限範圍內明確禁止此類限制； （ii）修改、翻譯或以其他管道創建網站或服務任何部分的衍生作品； （iii）複製、出租、出租、分發或以其他管道轉讓您在本協定項下獲得的任何權利。 您應遵守所有適用的地方、州、國家和國際法律法規。 如果網站或服務的任何部分受開源許可證約束，則無論本節如何，此類開源許可證均適用。",
          },
          {
            type: "p",
            text: "4.5 我們保留訪問、閱讀、保存和披露我們合理認為對（i）響應任何適用法律、法規、法律程式或政府要求所必需的任何資訊的權利； （ii）執行本服務條款，包括調查可能違反本服務條款的行為； （iii）檢測、預防或以其他管道解决欺詐、安全或科技問題； （iv）響應用戶支持請求； （v）保護我們、我們的用戶和公眾的權利、財產或安全。 更多資訊可在我們的執法指南中找到。",
          },
          {
            type: "p",
            text: "4.6 作為對服務的早期採用者的獎勵，一些具有較舊帳戶的用戶收到免費頻寬促銷，這取決於他們的帳戶保持有效、信譽良好並遵守本TOS（“免費頻寬帳戶”）。 如果出現以下情况，免費頻寬帳戶將不再獲得免費頻寬：（a）此類帳戶的所有權轉讓給協力廠商； （b）此類免費頻寬帳戶的使用違反了本TOS（包括AUP）； 或 © 此類免費頻寬帳戶用於以下任何活動：（i）運行Torrent以供下載或種子服務器、TOR或包含成人或色情內容的服務； （ii）將此類免費頻寬轉售或以其他管道作為服務提供給協力廠商； （iii）通過將免費頻寬的好處重新分配給協力廠商，以其他管道規避或試圖規避免費頻寬帳戶的預期用途； （iiii）已被用戶註銷的帳戶、或用戶遺失登入密碼但長期未向{platformName}及時找回、因長年未登錄而成為無效帳戶的。",
          },
          {
            type: "p",
            text: "4.7 您應使用適當的安全協定，例如設定强密碼和存取控制機制，保護對所有登錄名和密碼的訪問，以及驗證受託帳戶訪問資訊的人員的可信度。 您對任何未經授權訪問您的帳戶負全部責任，並且必須在得知任何此類未經授權的訪問後立即通知{platformName}。",
          },
          {
            type: "p",
            text: "4.8 如果您獲悉任何影響網站或服務的安全事件或違規行為，包括未經授權訪問您的帳戶或帳戶憑據，您應通知{platformName}，並應協助當局或{platformName}採取的任何調查或法律行動調查和糾正因您的帳戶或您對網站和服務的使用造成的安全事件或違規行為。 我們的資料處理協定包含有關與某些個人數據相關的安全事件的更多資訊。",
          },
          { type: "h4", text: "五、付款和計費" },
          {
            type: "p",
            text: "5.1 我們使用協力廠商支付處理器（“支付處理器”）通過與您的帳戶（您的“帳單資訊”）關聯的支付帳戶向您收費。 除本TOS外，付款處理可能還需遵守付款處理方的條款、條件和政策。 我們不對支付處理方的作為或不作為負責。 您同意根據我們的定價和計費政策，並且您在此授權我們和適用的付款處理方將所有此類款項收取到您的帳戶中指定或連結到您的帳戶（您的“付款方式”）的付款方式。",
          },
          {
            type: "p",
            text: "5.2 您必須為您的帳戶和帳單資訊提供最新、完整和準確的資訊，並且在發生變更（例如帳單地址、信用卡號或信用卡到期日變更）時必須及時更新所有此類資訊。 如果您的付款方式被取消（例如，遺失或被盜）或因其他原因無法使用，您必須立即通知我們或我們的付款處理方。 可以在您的帳戶設定中更改此類資訊。",
          },
          {
            type: "p",
            text: "5.3 簽訂本服務條款並使用服務，即表示您同意定期計費，並由我們或我們的支付處理商在開具發票時使用您的付款方式自動收取費用。 如果您的付款方式或費用支付受訂單、發票或其他內容中規定的其他條款和條件的約束，則除本TOS外，這些其他條款和條件也適用。 您也可能隨時被收取不超過您當前餘額的金額，以驗證您的帳戶資訊的準確性。 我們保留隨時自行决定停用、終止、封锁訪問、禁用服務和/或删除任何帳戶或訪問網站和服務的權利，包括未付款、逾期付款或未能向您收取費用開票時的付款方式。",
          },
          {
            type: "p",
            text: "5.4 我們可能會不時提供服務積分，此類積分的任何規定均受有關積分的披露、條款和政策的約束。 您承認並同意，所有信用額度，無論是以前提供的還是其他管道，都可以在任何時候以任何理由或沒有理由被我們撤銷或提前到期，包括濫用、虛假陳述帳戶資訊、未經授權的轉移或非法行為你。",
          },
          { type: "h4", text: "六、協力廠商服務" },
          {
            type: "p",
            text: "網站和服務可能包括指向協力廠商網站、服務或互聯網上其他資源的連結，協力廠商網站、服務或其他資源也可能包括指向我們網站和服務的連結。 當您訪問Internet上的協力廠商資源時，您需要自行承擔風險。 這些協力廠商資源不受我們控制，並且在法律允許的最大範圍內，我們對此類資源的內容、功能、準確性、合法性、適當性或任何其他方面不承擔任何責任或義務。 包含任何此類連結並不意味著我們的認可或我們與任何協力廠商之間的任何關聯。 在法律允許的最大範圍內，我們不承擔任何直接或間接的責任或義務，因使用或依賴任何此類協力廠商資源而造成或據稱造成或與之相關的任何損害或損失。 我們還允許某些供應商在{platformName}市場上提供可用的供應商產品（“供應商”和“供應商產品”在{platformName}市場條款和條件中定義）。 使用任何供應商產品可能需要您直接與供應商同意某些條款和條件，{platformName}可能不是此類協定的一方。 您有責任保護您的系統免受本節所述的協力廠商資源或供應商產品可能帶來的風險，例如病毒、蠕蟲、特洛伊木馬和其他安全風險。",
          },
          { type: "h4", text: "七、保密和測試服務" },
          {
            type: "p",
            text: "7.1 如果您從我們那裡收到或擁有任何被指定為機密的非公開資訊，或者鑒於資訊的性質或圍繞其披露的情况，合理地應理解為機密資訊，則此類資訊是我們的“機密資訊””，並且必須按照本TOS進行處理。 機密資訊包括但不限於：（a）關於我們的科技、客戶、商業計畫、行銷和銷售活動、財務、運營和其他商業資訊的非公開資訊； （b）我們與您就您的帳戶和/或服務的使用進行的討論或談判的存在和內容。 機密資訊不包括以下任何資訊：（i）在不違反本TOS的情况下公開或公開提供； （ii）可以通過檔案證明您在收到我們的通知時已經知道； （iii）從沒有通過不法或侵權行為或侵犯我們或協力廠商的權利獲取或披露資訊的協力廠商收到；",
          },
          {
            type: "p",
            text: "7.2 您不得使用機密資訊，除非本服務條款允許或我們以書面形式單獨授權與您使用服務有關。 您同意在本TOS期限內、在您停止使用服務之後以及在本TOS不再適用之後不披露機密資訊。 您將採取一切合理措施避免披露或未經授權使用機密資訊，至少包括您為保護自己的類似性質的機密資訊而採取的措施。",
          },
          {
            type: "p",
            text: "7.3 我們可能會提供服務的“測試版”或功能（每一個都稱為“測試版服務”））。 服務的任何生產候選版本或非生產版本或指定為測試版或測試版的服務的其他版本均被視為本TOS下的測試版服務。 我們將自行决定每個Beta服務的可用性、持續時間、功能和組件。 如果我們允許您使用Beta服務，則您同意我們提供的與Beta服務相關的資訊是機密資訊，並且除了本TOS中規定的其他要求外，您特別同意不：（i）使用用於基準測試或性能測試或從與服務相關的任何來源公開傳播效能資訊或分析的Beta服務； （ii）修改或創建Beta服務的衍生作品或删除任何產品標識、專有、Beta服務中包含的版權或其他通知； （iii）允許任何其他個人訪問或使用測試服務。 我們將自行决定是否繼續提供任何Beta服務，並可能隨時停止提供任何Beta服務。",
          },
          { type: "h4", text: "八、無保修" },
          {
            type: "p",
            text: "8.1 網站和服務，包括但不限於作為其一部分交付的任何內容，均按“原樣”和“可用”基礎提供。 {platformName}不承擔與網站和服務以及與之相關的所有內容相關的任何類型的明示或暗示保證，包括但不限於：（a）對適銷性、特定用途適用性、所有權、安靜享受，或不侵權； （b）在交易、使用或貿易過程中產生的任何保證； © 與可用性、準確性、錯誤率、系統完整性或不間斷訪問有關的任何保證或保證。 我們不保證：（i）網站或服務在任何特定時間或地點是安全的或可用的； （ii）任何缺陷或錯誤將得到糾正； （iii）網站或服務上或通過網站或服務提供的任何內容或軟件沒有病毒或其他有害成分； （iv）使用網站或服務的結果將滿足您的要求。 您對網站和服務的使用完全由您自己承擔風險。",
          },
          {
            type: "p",
            text: "在法律允許的最大範圍內，我們對供應商產品不承擔任何責任。 相反，如果適用，有關供應商產品的所有有限保證、免責聲明、責任限制和其他特定條款都可以在{platformName}市場條款和條件或（如果適用）您與供應商之間的協定中找到。",
          },
          {
            type: "p",
            text: "8.2 {platformName}不對您通過使用網站、服務訪問的內容或{platformName}的其他用戶或其他協力廠商的內容負責。 在適用法律允許的範圍內，您免除我們與此類內容相關的所有責任。 您承認並同意，我們不對網站中包含或通過網站訪問的任何內容或{platformName}或其他協力廠商的其他用戶的內容作出任何陳述，並且我們對準確性、質量、合法性或其他内容不承擔任何責任或義務這樣的內容。",
          },
          {
            type: "p",
            text: "8.3 {platformName}不否認適用法律禁止{platformName}放弃的任何保證或其他權利。",
          },
          { type: "h4", text: "九、責任限制" },
          {
            type: "p",
            text: "9.1 在法律允許的最大範圍內，在任何情况下，我們均不對您因或與您訪問或使用或無法訪問或使用網站和服務或網站和服務上的任何資料或內容有關，無論是基於保證、契约、侵權（包括疏忽）、法規或任何其他法律理論，以及我們是否被告知損害的可能性。 在法律允許的最大範圍內，您同意我們對任何可能被破壞、遺失或以其他管道無法訪問的數據不承擔任何責任，無論是因為您未能備份資料還是出於任何其他原因。",
          },
          {
            type: "p",
            text: "9.2 本服務條款中規定責任限制、保證免責聲明或損害排除的每項條款旨在並確實在本服務條款下的各方之間分配風險。 這種分配是您和我們之間交易基礎的基本要素。 這些條款中的每一項都是可分割的，並且獨立於本TOS的所有其他條款。 即使任何有限的補救措施未能達到其基本目的，本節中的限制也將適用。",
          },
          { type: "h4", text: "十、賠償" },
          {
            type: "p",
            text: "在法律允許的最大範圍內，您應對您對網站和服務的使用負責，並且您應為我們和我們的員工、高級職員、董事、代理人、承包商和代表辯護、賠償並使我們免受所有責任、索賠，以及因您的服務內容、用戶內容、您的最終用戶或您訪問或使用網站和服務而產生或與之相關的費用， 包括合理的律師費和成本，包括您違反本TOS或適用法律、故意不當行為、疏忽、非法活動、違反安全或數據、未經授權訪問或使用您的帳戶，或侵犯協力廠商權利，包括任何知識產權、保密、財產或隱私權。 我們保留對您需要賠償的任何事項進行獨家辯護和控制的權利，但不限制您對該事項的賠償義務，在這種情況下，您將盡最大努力協助和配合我們為該事項辯護自費。",
          },
          { type: "h4", text: "十一、終止" },
          {
            type: "p",
            text: "雖然我們更願意提前發出終止通知，但我們保留自行决定隨時終止您訪問網站和/或服務的全部或任何部分的權利，無論是否通知，立即生效。 任何此類終止都可能導致與您的帳戶相關的數據立即被沒收和銷毀。 除非本協定另有規定或雙方明確約定，否則支付給我們的任何和所有費用均不予退還，並且在終止時仍欠我們的任何和所有費用應立即到期應付。 終止後，本服務條款授予您的任何和所有權利將立即終止，您還必須立即停止對網站和/或服務的所有使用。",
          },
          { type: "h4", text: "十二、雜項規定" },
          {
            type: "p",
            text: "12.1 更新和修改。 {platformName}保留在任何時候由{platformName}自行决定更新、更改或以其他管道修改本TOS的權利。 如果{platformName}更新本TOS，{platformName}可能會提供此類更新的通知，例如通過向您帳戶中列出的電子郵寄地址發送電子郵件通知和/或更新本TOS開頭的“最後更新”日期。 更新將在通知中指定的日期生效。 在通知或更新服務條款中指定的日期之後繼續訪問或使用網站或服務，即表示您同意接受更新後的服務條款和其中包含的所有條款並受其約束。 如果您不同意更新後的TOS，",
          },
          {
            type: "p",
            text: "12.2 一般。 本服務條款，包括通過引用明確併入本文的所有檔案，構成雙方之間的完整協定，並取代所有先前和同時期關於其主題的書面或口頭協定、建議或陳述。 在本TOS中使用章節標題只是為了方便，不會對任何條款的解釋產生任何影響。 單數的任何使用都應被合理地解釋為好像它也包括複數一樣，反之亦然。 如果沒有明確說明，“包括”或“例如”的任何使用均應解釋為“包括但不限於”。 如果本TOS的任何部分被認定為無效或不可執行，則不可執行的部分將在最大可能的範圍內生效，其餘部分將保持完全有效。",
          },
          {
            type: "p",
            text: "12.3 分配。 未經我們事先書面同意，您不得通過法律或其他管道全部或部分轉讓、轉讓或委託本TOS或您在TOS項下的任何權利和義務。 我們可以在不通知或同意的情况下轉讓、轉讓或委託本TOS或我們在本TOS項下的權利和義務。 根據本節，本TOS將對各方及其各自允許的繼承人和受讓人具有約束力並符合其利益，任何違反本節的轉讓或轉讓均無效。",
          },
          {
            type: "p",
            text: "12.4 同意電子通訊。 通過使用網站和/或服務，您同意接收我們的某些電子通信，如我們的隱私政策中進一步描述的那樣。 請閱讀我們的隱私政策以瞭解更多關於我們的電子通信實踐的資訊。 您同意我們以電子管道發送給您的任何通知、協定、披露或其他通信將滿足任何法律通信要求，包括這些通信是書面的。",
          },
          {
            type: "p",
            text: "12.5 雙方的關係。 各方為獨立承包商。 本服務條款不會在雙方之間建立合夥、特許經營、合資、代理、信託或雇傭關係。 任何一方或其各自的任何附屬公司均不是另一方的代理人，出於任何目的或無權約束另一方。",
          },
          {
            type: "p",
            text: "12.6 不可抗力。 如果{platformName}因發生超出{platformName}合理控制的任何行為或事件（包括但不限於天灾、戰爭、動亂或暴動、罷工、政府實體的任何行動、天氣、檢疫、火灾、洪水、地震、爆炸、公用事業或電信中斷、互聯網干擾、流行病、流行病或任何不可預見的情况變化，或超出我們合理控制範圍的任何其他原因。",
          },
          {
            type: "p",
            text: "12.7 無協力廠商受益人。 本TOS不會對非本TOS一方的任何個人或實體產生任何協力廠商受益權。",
          },
          {
            type: "p",
            text: "12.8 政府使用。 {platformName}提供網站和服務，包括相關軟體和科技，最終聯邦政府最終使用僅按照以下規定：與網站和服務相關的政府科技數據和軟件權利僅包括按照慣例向公眾提供的權利，如本服務條款。",
          },
          {
            type: "p",
            text: "版權所有2020{platformName}，LLC。 版權所有。 未經{platformName}事先書面許可，不得以任何形式或管道複製、修改或分發{platformName}網站的任何部分。",
          },
        ],
        "vi-VN": [
          { type: "h4", text: "1. Eligibility and registration" },
          {
            type: "p",
            text: "1.1 Before you use our website and services, you must ensure that such use complies with all laws, rules and regulations that apply to you. Your right to access the Site and Services will be revoked if your use is prohibited or if our regulations conflict with any applicable laws, rules or regulations. It is your responsibility to make these decisions before using the website and services.",
          },
          {
            type: "p",
            text: "1.2 The Site and Services are not directed to, and are not intended to be used by, anyone under the age of 18. By using the Site and Services, you represent and warrant to us that: (a) you are at least 18 years old; (b) you otherwise have sufficient legal consent, license and capacity to use in the applicable jurisdiction as determined by you Website and Services.",
          },
          {
            type: "p",
            text: '1.3 To access certain features of the Services and Website, you must register for an account ("Account"). When you register for your account, you may be required to provide us with some information about yourself, such as your name, email address and a valid payment method, and you may also voluntarily provide optional information about yourself. Account information and our use and disclosure of it are governed by the Privacy Policy and Data Processing Agreement.',
          },
          {
            type: "p",
            text: "1.4 We may, in our sole discretion, refuse to provide or continue to provide the Site and Services to any person or entity and change the eligibility criteria at any time, including if you fail to comply with the TOS. We reserve the right to deactivate, terminate, block access, disable the Services and/or delete any account or access to the Site and Services at any time in our sole discretion.",
          },
          { type: "h4", text: "2. Ownership of {platformName}" },
          {
            type: "p",
            text: '2.1 As between you and {platformName}, the website and services are owned and/or provided by {platformName}. names, logos, trademarks, trade dress, arrangements, visual interfaces, graphics, designs, compilations, information, data, computer code (including source or object code), products, software, services and all other elements of the website, and services ( "Material") The content we provide is protected by intellectual property and other laws. All materials contained in the Site and Services are the property of {platformName} or its third-party licensors. You may only use the Site and Services in the manner expressly authorized by us and set forth in this TOS. You shall comply with and maintain all intellectual property notices, information and restrictions contained in the Site and Services. We reserve all rights to the Site and Services not expressly granted by these Terms of Service.',
          },
          {
            type: "p",
            text: '2.2 If you choose to provide comments and suggestions ("Feedback") regarding issues or proposed changes or improvements to the Site and Services, you hereby grant us an unrestricted, permanent, irrevocable, non-exclusive, full payment Royalties - Free right to use Feedback in any way and for any purpose, including improving the Site and Services and creating other products and services.',
          },
          {
            type: "p",
            text: "2.3 You allow us to disclose you as a customer in our promotional and marketing materials, including by name, corporate logo and trademark.",
          },
          {
            type: "p",
            text: '2.4 Certain materials provided by {platformName} are licensed under one or more open source, Creative Commons or similar licenses (collectively, the "Open Source Licenses"). Nothing in this TOS prevents, restricts, or is intended to prevent or restrict your access to such materials under applicable open source licenses, or your use of such materials under those open source licenses.',
          },
          {
            type: "p",
            text: "2.5 Subject to your full and ongoing compliance with these TOS and our rights therein, {platformName} grants you a limited, non-exclusive, non-assignable, non-sublicensable, revocable license to access and use as described in website and/or services, and are governed by these Terms of Service.",
          },
          { type: "h4", text: "3. Service Content" },
          {
            type: "p",
            text: '3.1 Our Services allow you to provide us with source code, files, software, processes, interfaces, data, text, settings, media or other information for the Services to store, host or process (your "Service Content"). Service content includes listing information and vendor offerings (as defined in the {platformName} Marketplace Terms and Conditions).',
          },
          {
            type: "p",
            text: "3.2 By providing your Service Content to or through the Service, you grant {platformName} a license to host, store, transmit, display, perform, reproduce, modify and distribute your Service for the purpose of formatted display only, in accordance with these Terms of Service The content is provided to you.",
          },
          {
            type: "p",
            text: "3.3 As between you and {platformName}, you retain any copyright and other proprietary rights you may have in the Service Content.",
          },
          {
            type: "p",
            text: '3.4 If you resell the services under TOS to third parties, or your own products and services (bound with TOS services) are allowed to be used by third parties through public websites, applications, and interfaces, then some TOS services will allow Third parties accessing, using or sharing your Service Content, such other users are collectively referred to as your "End Users" for the purposes of this TOS. For clarity, "end user" in this document includes all such other users, whether they are intermediaries, end users of other end users, etc. It is your responsibility to ensure that your end users comply with this TOS.',
          },
          {
            type: "p",
            text: "3.5 You are solely responsible for the content of your Service, your end users and any activities of your end users, and you agree that {platformName} will not and will not in any way be responsible for the content of your service, your end users and/or your end users' Responsible for the activity. By providing your service content through the service, you confirm, represent and warrant that:",
          },
          {
            type: "p",
            text: "Your Service Content and your or your End User's use of Your Service Content will not violate this TOS (including the AUP) or any applicable laws, regulations, rules or rights of third parties;",
          },
          {
            type: "p",
            text: "You are solely responsible for the development, review, operation, maintenance, support and use of your Service Content, including when Your Service Content is provided by your end users;",
          },
          {
            type: "p",
            text: "Your Service Content and your or your end users' use of Your Service Content will not and will not: (i) infringe, violate or misappropriate any third party rights, including any copyright, trademark, patent, trade secret, moral rights , privacy, publicity or any other intellectual property or proprietary rights; (ii) defame, defame, defame or violate any other person’s privacy, publicity or other property rights; (iii) cause us to violate any law, regulation, the rules or the rights of third parties; and Except for certain services provided to you under this TOS or other express contract, you are solely responsible for the technical operation of the content of your services, including on behalf of your end users.",
          },
          {
            type: "p",
            text: "3.6 It is your responsibility to properly configure and use the Services and to take your own steps to maintain the appropriate security, protection and backup of your Service Content, which may include the use of encryption technology to protect Your Service Content from unauthorized access As well as routine archiving of your services. {platformName} makes no commitment to retain any saves or backups of your Service Content. You are solely responsible for the integrity, preservation, and backup of your Service content, whether or not your use of the Service includes {platformName} backup features or functionality, and to the fullest extent permitted by law, {platformName} is not responsible for any loss of data and may not use, or other consequences related to the above.",
          },
          {
            type: "p",
            text: "3.7 When purchasing the Services, you may specify the geographic area in which your Services content will be stored. You agree to store your service content in and transfer your service content to a geographic area of ​​your choice. Unless you and {platformName} agree otherwise in writing, you agree that {platformName} may, at its sole discretion, transfer and store your Service content in other geographic areas. You represent and warrant that such storage and transfer have been given your full consent and are permitted in accordance with applicable laws, regulations, rules and third party rights.",
          },
          { type: "h4", text: "4. Code of Conduct" },
          {
            type: "p",
            text: "4.1 You must use the Site and Services in accordance with our AUP, which is incorporated by reference. Therefore, any use of the Site and Services in violation of the AUP constitutes a violation of this TOS.",
          },
          {
            type: "p",
            text: "4.2 You are solely responsible for activities that occur on your account, whether such activities are performed by you, your employees, any third party (including your contractors or agents), your end users, your licensees or your conducted by the customer.",
          },
          {
            type: "p",
            text: "4.3 You are responsible for informing your employees, agents and others in connection with your use of the Site and Services of the terms of this TOS, including where the terms of the TOS bind them.",
          },
          {
            type: "p",
            text: "4.4 You shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or otherwise attempt to obtain any source code or underlying ideas of any part of the Website or Services (including without limitation any Application) or algorithms, unless such restrictions are expressly prohibited by applicable law to the limited extent; (ii) modify, translate or otherwise create derivative works of any portion of the Site or Services; (iii) copy, rent, rent, distribute or otherwise transfer any rights you acquire under this Agreement. You shall comply with all applicable local, state, national and international laws and regulations. If any part of the Site or Service is governed by an open source license, such open source license applies regardless of this section.",
          },
          {
            type: "p",
            text: "4.5 We reserve the right to access, read, store and disclose any information we reasonably deem necessary to (i) respond to any applicable law, regulation, legal process or governmental request; (ii) enforce these Terms of Service, including investigating possible violations of these Terms of Service; Terms of Service; (iii) detect, prevent or otherwise resolve fraud, security or technical issues; (iv) respond to user support requests; (v) protect the rights, property or safety of us, our users and the public. More information can be found in our Law Enforcement Guide.",
          },
          {
            type: "p",
            text: "4.6 As a bonus to early adopters of the Service, some users with older accounts receive free bandwidth promotions, subject to their account remaining active, in good standing and complying with this TOS (“Free Bandwidth Account”). Free bandwidth accounts will no longer receive free bandwidth if: (a) ownership of such accounts is transferred to a third party; (b) use of such free bandwidth accounts violates this TOS (including the AUP); or © this Such free bandwidth accounts are used for any of the following activities: (i) to run Torrents for download or torrent servers, TOR, or services that contain adult or pornographic content; (ii) to resell or otherwise provide such free bandwidth as a service to third parties; (iii) otherwise circumvent or attempt to circumvent the intended use of free bandwidth accounts by redistributing the benefits of free bandwidth to third parties; (iii) accounts that have been logged out by users, or users who have lost their login passwords but have not The account was retrieved from {platformName} in time, and it became an invalid account due to not logging in for many years.",
          },
          {
            type: "p",
            text: "4.7 You should use appropriate security protocols, such as setting strong passwords and access control mechanisms, protecting access to all logins and passwords, and verifying the trustworthiness of those who access information on trusted accounts. You are solely responsible for any unauthorized access to your Account and must notify {platformName} immediately upon becoming aware of any such unauthorized access.",
          },
          {
            type: "p",
            text: "4.8 If you become aware of any security incident or breach affecting the Website or Services, including unauthorized access to your account or account credentials, you shall notify {platformName} and shall assist the authorities or any investigation or legal action taken by {platformName} to investigate and correct the cause. A security incident or breach resulting from your account or your use of the Site and Services. Our data processing agreement contains further information about security incidents related to certain personal data.",
          },
          { type: "h4", text: "5. Payment and billing" },
          {
            type: "p",
            text: '5.1 We use a third party payment processor ("Payment Processor") to bill you through the payment account associated with your account (your "Billing Information"). In addition to this TOS, payment processing may be subject to the terms, conditions and policies of the payment processor. We are not responsible for the actions or omissions of payment processors. You agree that in accordance with our Pricing and Billing Policy, and you hereby authorize us and the applicable payment processor to charge all such payments to you',
          },
          {
            type: "p",
            text: "5.2 You must provide your account and billing information with current, complete and accurate information and must promptly update all such information in the event of changes (such as changes to billing address, credit card number or credit card expiration date). If your payment method is cancelled (for example, lost or stolen) or otherwise unavailable, you must notify us or our payment processor immediately. This information can be changed in your account settings.",
          },
          {
            type: "p",
            text: "5.3 By entering into these Terms of Service and using the Services, you agree to recurring billing and automatic billing by us or our payment processor using your payment method at the time of invoicing. If your method of payment or payment of fees is subject to other terms and conditions set forth in an order, invoice or otherwise, those other terms and conditions apply in addition to this TOS. You may also be charged an amount not exceeding your current balance at any time to verify the accuracy of your account information. We reserve the right to deactivate, terminate, block access, disable the Services and/or delete any account or access to the Site and Services at any time in our sole discretion, including payment methods for non-payment, late payment or failure to bill you.",
          },
          {
            type: "p",
            text: "5.4 We may offer Service Credits from time to time and any provision for such Credits is governed by the disclosures, terms and policies regarding Credits. You acknowledge and agree that all credits, whether previously offered or otherwise, may be withdrawn or prematurely expired by us at any time for any reason or no reason, including abuse, misrepresentation of account information, unauthorized transfers or conduct you illegally.",
          },
          { type: "h4", text: "6. Third-party services" },
          {
            type: "p",
            text: 'The websites and services may include links to third-party websites, services or other resources on the Internet, and third-party websites, services or other resources may also include links to our websites and services. When you access third-party resources on the Internet, you do so at your own risk. These third-party resources are not under our control, and to the fullest extent permitted by law, we are not responsible or liable for the content, functionality, accuracy, legality, appropriateness or any other aspect of such resources. The inclusion of any such link does not imply our endorsement or any association between us and any third party. To the fullest extent permitted by law, we disclaim any direct or indirect responsibility or liability for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such third-party resources. We also allow certain vendors to make Vendor Products available on the {platformName} Marketplace ("Vendor" and "Vendor Product" are defined in the {platformName} Marketplace Terms and Conditions). Use of any vendor product may require you to agree to certain terms and conditions directly with the vendor, and {platformName} may not be a party to such agreement. It is your responsibility to protect your system from risks that may be posed by third-party resources or vendor products described in this section, such as viruses, worms, Trojan horses, and other security risks.',
          },
          { type: "h4", text: "7. Confidentiality and Testing Services" },
          {
            type: "p",
            text: "7.1 If you receive or possess any non-public information from us that is designated as confidential or reasonably should be understood to be confidential given the nature of the information or the circumstances surrounding its disclosure, then such information is our “confidential information”. ”” and must be handled in accordance with this TOS. Confidential information includes, but is not limited to: (a) non-public information about our technology, customers, business plans, marketing and sales activities, financial, operational and other business information; (b) ) the existence and content of our discussions or negotiations with you regarding the use of your account and/or services. Confidential Information does not include any information that is: (i) publicly or publicly available without breaching this TOS; (ii) ) can be documented by evidence that you were aware of our notice at the time of receipt; (iii) received from a third party that did not obtain or disclose the information through an unlawful or tortious act or violation of our or a third party's rights;",
          },
          {
            type: "p",
            text: "7.2 You shall not use Confidential Information except as permitted by these Terms of Service or separately authorized by us in writing in connection with your use of the Services. You agree not to disclose Confidential Information during the term of this TOS, after you stop using the Services, and after this TOS no longer applies. You will take all reasonable steps to avoid disclosure or unauthorized use of Confidential Information, including at least the steps you take to protect your own Confidential Information of a similar nature.",
          },
          {
            type: "p",
            text: '7.3 We may offer "beta versions" or features of the Services (each referred to as "Beta Services")). Any production candidate or non-production version of the Service or other version of the Service designated as a beta or beta version is considered a beta service under this TOS. We will determine the availability, duration, features and components of each Beta Service at our sole discretion. If we allow you to use the Beta Services, you agree that the information we provide in connection with the Beta Services is confidential and, in addition to other requirements set forth in this TOS, you specifically agree not to: (i) use for benchmarking or performance test or publicly disseminate performance information or analysis of the Beta Services from any source related to the Services; (ii) modify or create derivative works of the Beta Services or remove any product identification, proprietary, copyright or other notices contained in the Beta Services; ( iii) allow any other individual to access or use the Testing Services. We will continue to provide any Beta Services at our sole discretion and may discontinue any Beta Services at any time.',
          },
          { type: "h4", text: "8. No warranty" },
          {
            type: "p",
            text: '8.1 The Site and Services, including but not limited to any content delivered as part of it, are provided on an "as is" and "as available" basis. {platformName} disclaims all warranties of any kind, express or implied, with respect to the Site and Services and all content associated therewith, including, but not limited to: (a) merchantability, fitness for a particular purpose, title, quiet enjoyment, or non-infringement; (b) any warranties arising in the course of dealing, use or trade; © any warranties or guarantees relating to availability, accuracy, error rates, system integrity or uninterrupted access. We do not warrant that: (i) the website or service will be secure or available at any particular time or location; (ii) any defects or errors will be corrected; (iii) any content on or available through the website or service or the software is free of viruses or other harmful components; (iv) the results of using the website or services will meet your requirements. Your use of the Site and Services is entirely at your own risk.',
          },
          {
            type: "p",
            text: "To the maximum extent permitted by law, we disclaim all liability for supplier products. Instead, if applicable, all limited warranties, disclaimers, limitations of liability, and other specific terms regarding Supplier's products can be found in the {platformName} Marketplace Terms and Conditions or (if applicable) in the agreement between you and the Supplier.",
          },
          {
            type: "p",
            text: "8.2 {platformName} is not responsible for the content you access through your use of the Website, Services or the content of other users of {platformName} or other third parties. To the extent permitted by applicable law, you release us from all liability related to such content. You acknowledge and agree that we make no representations about any content contained in or accessed through the Site or the content of other users of {platformName} or other third parties, and we are not responsible for the accuracy, quality, legality or other attributes of or Obligation to such content.",
          },
          {
            type: "p",
            text: "8.3 {platformName} does not disclaim any warranties or other rights that applicable law prohibits {platformName} from disclaiming.",
          },
          { type: "h4", text: "9. Limitation of Liability" },
          {
            type: "p",
            text: "9.1 To the fullest extent permitted by law, in no event shall we, whether based on warranties, contract, tort (including negligence), statute or any other legal theory, and whether we have been advised of the possibility of damages. To the fullest extent permitted by law, you agree that we are not responsible for any data that may be destroyed, lost or otherwise inaccessible, whether because of your failure to back up the data or for any other reason.",
          },
          {
            type: "p",
            text: "9.2 Each provision in these Terms of Service that sets forth the limitation of liability, disclaimer of warranty or exclusion of damages is intended and does distribute risk among the parties under these Terms of Service. This allocation is an essential element of the basis of the transaction between you and us. Each of these terms is severable and independent of all other terms of this TOS. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.",
          },
          { type: "h4", text: "10. Indemnity" },
          {
            type: "p",
            text: "To the fullest extent permitted by law, you shall be responsible for your use of the Site and Services, and you shall defend, indemnify and hold harmless us and our employees, officers, directors, agents, contractors and representatives from all Liability, Claims, and Expenses, Including Reasonable Attorneys' Fees and Costs Arising from or Related to Your Service Content, User Content, Your End Users, or Your Access to or Use of the Site and Services, including your breach of this TOS or applicable Law, willful misconduct, negligence, illegal activity, breach of security or data, unauthorized access or use of your account, or violation of third party rights, including any intellectual property, confidentiality, property or privacy rights. We reserve the right to the exclusive defense and control of any matter for which you are required to indemnify, without limiting your obligation to indemnify such matter, in which case you will use your best efforts to assist and cooperate with us in defending that matter at your own expense.",
          },
          { type: "h4", text: "11. Termination" },
          {
            type: "p",
            text: "While we would prefer to give earlier notice of termination, we reserve the right, in our sole discretion, to terminate your access to all or any part of the Site and/or Services at any time, with or without notice, effective immediately. Any such termination may result in the immediate forfeiture and destruction of data associated with your account. Unless otherwise provided in this Agreement or expressly agreed by the parties, any and all fees paid to us are non-refundable and any and all fees still owed to us at the time of termination shall be immediately due and payable. Upon termination, any and all rights granted to you by these Terms of Service will immediately cease and you must also immediately cease all use of the Site and/or Services.",
          },
          { type: "h4", text: "12. Miscellaneous Provisions" },
          {
            type: "p",
            text: `12.1 Updates and Modifications. {platformName} reserves the right to update, change or otherwise modify this TOS at any time in {platformName}'s sole discretion. If {platformName} updates this TOS, {platformName} may provide notice of such update, such as by sending an email notification to the email address listed in your account and/or updating the "last updated" date at the beginning of this TOS. Updates will take effect on the date specified in the notice. By continuing to access or use the Site or Services after the date specified in the notice or updated Terms of Service, you agree to accept and be bound by the updated Terms of Service and all terms contained therein. If you do not agree to the updated TOS,`,
          },
          {
            type: "p",
            text: '12.2 General. These Terms of Service, including all documents expressly incorporated herein by reference, constitute the entire agreement between the parties and supersede all prior and contemporaneous agreements, proposals or representations, written or oral, with respect to its subject matter. The use of section headings in this TOS is for convenience only and will not have any effect on the interpretation of any provision. Any use of the singular should reasonably be construed as if it also included the plural and vice versa. Any use of "including" or "for example" should be construed as "including but not limited to" if not expressly stated otherwise. If any part of this TOS is found to be invalid or unenforceable, the unenforceable part will be effective to the greatest possible extent and the remainder will remain in full force and effect.',
          },
          {
            type: "p",
            text: "12.3 Assignment. You may not assign, assign or delegate this TOS or any of your rights and obligations under the TOS, in whole or in part, by law or otherwise, without our prior written consent. We may assign, assign or delegate this TOS or our rights and obligations under this TOS without notice or consent. Subject to this section, this TOS will be binding on and inure to the benefit of the parties and their respective permitted successors and assigns, and any assignment or assignment in violation of this section will be void.",
          },
          {
            type: "p",
            text: "12.4 Consent to Electronic Communications. By using the Site and/or Services, you agree to receive certain electronic communications from us, as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communication practices. You agree that any notices, agreements, disclosures or other communications that we send you electronically will satisfy any legal communication requirements, including that such communications are in writing.",
          },
          {
            type: "p",
            text: "12.5 Relationship of the Parties. The parties are independent contractors. These Terms of Service do not create a partnership, franchise, joint venture, agency, trust or employment relationship between the parties. Neither party nor any of their respective affiliates is an agent of the other party and has no authority or authority to bind the other party for any purpose.",
          },
          {
            type: "p",
            text: "12.6 Force Majeure. If {platformName} is subject to any act or event beyond {platformName}'s reasonable control (including but not limited to acts of God, war, unrest or riot, strike, any action of a government entity, weather, quarantine, fire, flood, earthquake, explosion, utility or telecommunications outages, internet disruptions, epidemics, epidemics or any unforeseen change in circumstances, or any other cause beyond our reasonable control.",
          },
          {
            type: "p",
            text: "12.7 No Third Party Beneficiaries. This TOS does not create any third party beneficial rights to any person or entity that is not a party to this TOS.",
          },
          {
            type: "p",
            text: "12.8 Government Use. {platformName} provides the website and services, including related software and technology, for ultimate federal use only in accordance with the following: Government technical data and software rights associated with the website and services include only those rights customarily made available to the public, such as these Terms of Service.",
          },
          {
            type: "p",
            text: "Copyright 2020 {platformName}, LLC. all rights reserved. No part of the {platformName} website may be reproduced, modified or distributed in any form or by any means without the prior written permission of {platformName}.",
          },
        ],
        "fr-FR": [
          { type: "h4", text: "1. Eligibility and registration" },
          {
            type: "p",
            text: "1.1 Before you use our website and services, you must ensure that such use complies with all laws, rules and regulations that apply to you. Your right to access the Site and Services will be revoked if your use is prohibited or if our regulations conflict with any applicable laws, rules or regulations. It is your responsibility to make these decisions before using the website and services.",
          },
          {
            type: "p",
            text: "1.2 The Site and Services are not directed to, and are not intended to be used by, anyone under the age of 18. By using the Site and Services, you represent and warrant to us that: (a) you are at least 18 years old; (b) you otherwise have sufficient legal consent, license and capacity to use in the applicable jurisdiction as determined by you Website and Services.",
          },
          {
            type: "p",
            text: '1.3 To access certain features of the Services and Website, you must register for an account ("Account"). When you register for your account, you may be required to provide us with some information about yourself, such as your name, email address and a valid payment method, and you may also voluntarily provide optional information about yourself. Account information and our use and disclosure of it are governed by the Privacy Policy and Data Processing Agreement.',
          },
          {
            type: "p",
            text: "1.4 We may, in our sole discretion, refuse to provide or continue to provide the Site and Services to any person or entity and change the eligibility criteria at any time, including if you fail to comply with the TOS. We reserve the right to deactivate, terminate, block access, disable the Services and/or delete any account or access to the Site and Services at any time in our sole discretion.",
          },
          { type: "h4", text: "2. Ownership of {platformName}" },
          {
            type: "p",
            text: '2.1 As between you and {platformName}, the website and services are owned and/or provided by {platformName}. names, logos, trademarks, trade dress, arrangements, visual interfaces, graphics, designs, compilations, information, data, computer code (including source or object code), products, software, services and all other elements of the website, and services ( "Material") The content we provide is protected by intellectual property and other laws. All materials contained in the Site and Services are the property of {platformName} or its third-party licensors. You may only use the Site and Services in the manner expressly authorized by us and set forth in this TOS. You shall comply with and maintain all intellectual property notices, information and restrictions contained in the Site and Services. We reserve all rights to the Site and Services not expressly granted by these Terms of Service.',
          },
          {
            type: "p",
            text: '2.2 If you choose to provide comments and suggestions ("Feedback") regarding issues or proposed changes or improvements to the Site and Services, you hereby grant us an unrestricted, permanent, irrevocable, non-exclusive, full payment Royalties - Free right to use Feedback in any way and for any purpose, including improving the Site and Services and creating other products and services.',
          },
          {
            type: "p",
            text: "2.3 You allow us to disclose you as a customer in our promotional and marketing materials, including by name, corporate logo and trademark.",
          },
          {
            type: "p",
            text: '2.4 Certain materials provided by {platformName} are licensed under one or more open source, Creative Commons or similar licenses (collectively, the "Open Source Licenses"). Nothing in this TOS prevents, restricts, or is intended to prevent or restrict your access to such materials under applicable open source licenses, or your use of such materials under those open source licenses.',
          },
          {
            type: "p",
            text: "2.5 Subject to your full and ongoing compliance with these TOS and our rights therein, {platformName} grants you a limited, non-exclusive, non-assignable, non-sublicensable, revocable license to access and use as described in website and/or services, and are governed by these Terms of Service.",
          },
          { type: "h4", text: "3. Service Content" },
          {
            type: "p",
            text: '3.1 Our Services allow you to provide us with source code, files, software, processes, interfaces, data, text, settings, media or other information for the Services to store, host or process (your "Service Content"). Service content includes listing information and vendor offerings (as defined in the {platformName} Marketplace Terms and Conditions).',
          },
          {
            type: "p",
            text: "3.2 By providing your Service Content to or through the Service, you grant {platformName} a license to host, store, transmit, display, perform, reproduce, modify and distribute your Service for the purpose of formatted display only, in accordance with these Terms of Service The content is provided to you.",
          },
          {
            type: "p",
            text: "3.3 As between you and {platformName}, you retain any copyright and other proprietary rights you may have in the Service Content.",
          },
          {
            type: "p",
            text: '3.4 If you resell the services under TOS to third parties, or your own products and services (bound with TOS services) are allowed to be used by third parties through public websites, applications, and interfaces, then some TOS services will allow Third parties accessing, using or sharing your Service Content, such other users are collectively referred to as your "End Users" for the purposes of this TOS. For clarity, "end user" in this document includes all such other users, whether they are intermediaries, end users of other end users, etc. It is your responsibility to ensure that your end users comply with this TOS.',
          },
          {
            type: "p",
            text: "3.5 You are solely responsible for the content of your Service, your end users and any activities of your end users, and you agree that {platformName} will not and will not in any way be responsible for the content of your service, your end users and/or your end users' Responsible for the activity. By providing your service content through the service, you confirm, represent and warrant that:",
          },
          {
            type: "p",
            text: "Your Service Content and your or your End User's use of Your Service Content will not violate this TOS (including the AUP) or any applicable laws, regulations, rules or rights of third parties;",
          },
          {
            type: "p",
            text: "You are solely responsible for the development, review, operation, maintenance, support and use of your Service Content, including when Your Service Content is provided by your end users;",
          },
          {
            type: "p",
            text: "Your Service Content and your or your end users' use of Your Service Content will not and will not: (i) infringe, violate or misappropriate any third party rights, including any copyright, trademark, patent, trade secret, moral rights , privacy, publicity or any other intellectual property or proprietary rights; (ii) defame, defame, defame or violate any other person’s privacy, publicity or other property rights; (iii) cause us to violate any law, regulation, the rules or the rights of third parties; and Except for certain services provided to you under this TOS or other express contract, you are solely responsible for the technical operation of the content of your services, including on behalf of your end users.",
          },
          {
            type: "p",
            text: "3.6 It is your responsibility to properly configure and use the Services and to take your own steps to maintain the appropriate security, protection and backup of your Service Content, which may include the use of encryption technology to protect Your Service Content from unauthorized access As well as routine archiving of your services. {platformName} makes no commitment to retain any saves or backups of your Service Content. You are solely responsible for the integrity, preservation, and backup of your Service content, whether or not your use of the Service includes {platformName} backup features or functionality, and to the fullest extent permitted by law, {platformName} is not responsible for any loss of data and may not use, or other consequences related to the above.",
          },
          {
            type: "p",
            text: "3.7 When purchasing the Services, you may specify the geographic area in which your Services content will be stored. You agree to store your service content in and transfer your service content to a geographic area of ​​your choice. Unless you and {platformName} agree otherwise in writing, you agree that {platformName} may, at its sole discretion, transfer and store your Service content in other geographic areas. You represent and warrant that such storage and transfer have been given your full consent and are permitted in accordance with applicable laws, regulations, rules and third party rights.",
          },
          { type: "h4", text: "4. Code of Conduct" },
          {
            type: "p",
            text: "4.1 You must use the Site and Services in accordance with our AUP, which is incorporated by reference. Therefore, any use of the Site and Services in violation of the AUP constitutes a violation of this TOS.",
          },
          {
            type: "p",
            text: "4.2 You are solely responsible for activities that occur on your account, whether such activities are performed by you, your employees, any third party (including your contractors or agents), your end users, your licensees or your conducted by the customer.",
          },
          {
            type: "p",
            text: "4.3 You are responsible for informing your employees, agents and others in connection with your use of the Site and Services of the terms of this TOS, including where the terms of the TOS bind them.",
          },
          {
            type: "p",
            text: "4.4 You shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or otherwise attempt to obtain any source code or underlying ideas of any part of the Website or Services (including without limitation any Application) or algorithms, unless such restrictions are expressly prohibited by applicable law to the limited extent; (ii) modify, translate or otherwise create derivative works of any portion of the Site or Services; (iii) copy, rent, rent, distribute or otherwise transfer any rights you acquire under this Agreement. You shall comply with all applicable local, state, national and international laws and regulations. If any part of the Site or Service is governed by an open source license, such open source license applies regardless of this section.",
          },
          {
            type: "p",
            text: "4.5 We reserve the right to access, read, store and disclose any information we reasonably deem necessary to (i) respond to any applicable law, regulation, legal process or governmental request; (ii) enforce these Terms of Service, including investigating possible violations of these Terms of Service; Terms of Service; (iii) detect, prevent or otherwise resolve fraud, security or technical issues; (iv) respond to user support requests; (v) protect the rights, property or safety of us, our users and the public. More information can be found in our Law Enforcement Guide.",
          },
          {
            type: "p",
            text: "4.6 As a bonus to early adopters of the Service, some users with older accounts receive free bandwidth promotions, subject to their account remaining active, in good standing and complying with this TOS (“Free Bandwidth Account”). Free bandwidth accounts will no longer receive free bandwidth if: (a) ownership of such accounts is transferred to a third party; (b) use of such free bandwidth accounts violates this TOS (including the AUP); or © this Such free bandwidth accounts are used for any of the following activities: (i) to run Torrents for download or torrent servers, TOR, or services that contain adult or pornographic content; (ii) to resell or otherwise provide such free bandwidth as a service to third parties; (iii) otherwise circumvent or attempt to circumvent the intended use of free bandwidth accounts by redistributing the benefits of free bandwidth to third parties; (iii) accounts that have been logged out by users, or users who have lost their login passwords but have not The account was retrieved from {platformName} in time, and it became an invalid account due to not logging in for many years.",
          },
          {
            type: "p",
            text: "4.7 You should use appropriate security protocols, such as setting strong passwords and access control mechanisms, protecting access to all logins and passwords, and verifying the trustworthiness of those who access information on trusted accounts. You are solely responsible for any unauthorized access to your Account and must notify {platformName} immediately upon becoming aware of any such unauthorized access.",
          },
          {
            type: "p",
            text: "4.8 If you become aware of any security incident or breach affecting the Website or Services, including unauthorized access to your account or account credentials, you shall notify {platformName} and shall assist the authorities or any investigation or legal action taken by {platformName} to investigate and correct the cause. A security incident or breach resulting from your account or your use of the Site and Services. Our data processing agreement contains further information about security incidents related to certain personal data.",
          },
          { type: "h4", text: "5. Payment and billing" },
          {
            type: "p",
            text: '5.1 We use a third party payment processor ("Payment Processor") to bill you through the payment account associated with your account (your "Billing Information"). In addition to this TOS, payment processing may be subject to the terms, conditions and policies of the payment processor. We are not responsible for the actions or omissions of payment processors. You agree that in accordance with our Pricing and Billing Policy, and you hereby authorize us and the applicable payment processor to charge all such payments to you',
          },
          {
            type: "p",
            text: "5.2 You must provide your account and billing information with current, complete and accurate information and must promptly update all such information in the event of changes (such as changes to billing address, credit card number or credit card expiration date). If your payment method is cancelled (for example, lost or stolen) or otherwise unavailable, you must notify us or our payment processor immediately. This information can be changed in your account settings.",
          },
          {
            type: "p",
            text: "5.3 By entering into these Terms of Service and using the Services, you agree to recurring billing and automatic billing by us or our payment processor using your payment method at the time of invoicing. If your method of payment or payment of fees is subject to other terms and conditions set forth in an order, invoice or otherwise, those other terms and conditions apply in addition to this TOS. You may also be charged an amount not exceeding your current balance at any time to verify the accuracy of your account information. We reserve the right to deactivate, terminate, block access, disable the Services and/or delete any account or access to the Site and Services at any time in our sole discretion, including payment methods for non-payment, late payment or failure to bill you.",
          },
          {
            type: "p",
            text: "5.4 We may offer Service Credits from time to time and any provision for such Credits is governed by the disclosures, terms and policies regarding Credits. You acknowledge and agree that all credits, whether previously offered or otherwise, may be withdrawn or prematurely expired by us at any time for any reason or no reason, including abuse, misrepresentation of account information, unauthorized transfers or conduct you illegally.",
          },
          { type: "h4", text: "6. Third-party services" },
          {
            type: "p",
            text: 'The websites and services may include links to third-party websites, services or other resources on the Internet, and third-party websites, services or other resources may also include links to our websites and services. When you access third-party resources on the Internet, you do so at your own risk. These third-party resources are not under our control, and to the fullest extent permitted by law, we are not responsible or liable for the content, functionality, accuracy, legality, appropriateness or any other aspect of such resources. The inclusion of any such link does not imply our endorsement or any association between us and any third party. To the fullest extent permitted by law, we disclaim any direct or indirect responsibility or liability for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such third-party resources. We also allow certain vendors to make Vendor Products available on the {platformName} Marketplace ("Vendor" and "Vendor Product" are defined in the {platformName} Marketplace Terms and Conditions). Use of any vendor product may require you to agree to certain terms and conditions directly with the vendor, and {platformName} may not be a party to such agreement. It is your responsibility to protect your system from risks that may be posed by third-party resources or vendor products described in this section, such as viruses, worms, Trojan horses, and other security risks.',
          },
          { type: "h4", text: "7. Confidentiality and Testing Services" },
          {
            type: "p",
            text: "7.1 If you receive or possess any non-public information from us that is designated as confidential or reasonably should be understood to be confidential given the nature of the information or the circumstances surrounding its disclosure, then such information is our “confidential information”. ”” and must be handled in accordance with this TOS. Confidential information includes, but is not limited to: (a) non-public information about our technology, customers, business plans, marketing and sales activities, financial, operational and other business information; (b) ) the existence and content of our discussions or negotiations with you regarding the use of your account and/or services. Confidential Information does not include any information that is: (i) publicly or publicly available without breaching this TOS; (ii) ) can be documented by evidence that you were aware of our notice at the time of receipt; (iii) received from a third party that did not obtain or disclose the information through an unlawful or tortious act or violation of our or a third party's rights;",
          },
          {
            type: "p",
            text: "7.2 You shall not use Confidential Information except as permitted by these Terms of Service or separately authorized by us in writing in connection with your use of the Services. You agree not to disclose Confidential Information during the term of this TOS, after you stop using the Services, and after this TOS no longer applies. You will take all reasonable steps to avoid disclosure or unauthorized use of Confidential Information, including at least the steps you take to protect your own Confidential Information of a similar nature.",
          },
          {
            type: "p",
            text: '7.3 We may offer "beta versions" or features of the Services (each referred to as "Beta Services")). Any production candidate or non-production version of the Service or other version of the Service designated as a beta or beta version is considered a beta service under this TOS. We will determine the availability, duration, features and components of each Beta Service at our sole discretion. If we allow you to use the Beta Services, you agree that the information we provide in connection with the Beta Services is confidential and, in addition to other requirements set forth in this TOS, you specifically agree not to: (i) use for benchmarking or performance test or publicly disseminate performance information or analysis of the Beta Services from any source related to the Services; (ii) modify or create derivative works of the Beta Services or remove any product identification, proprietary, copyright or other notices contained in the Beta Services; ( iii) allow any other individual to access or use the Testing Services. We will continue to provide any Beta Services at our sole discretion and may discontinue any Beta Services at any time.',
          },
          { type: "h4", text: "8. No warranty" },
          {
            type: "p",
            text: '8.1 The Site and Services, including but not limited to any content delivered as part of it, are provided on an "as is" and "as available" basis. {platformName} disclaims all warranties of any kind, express or implied, with respect to the Site and Services and all content associated therewith, including, but not limited to: (a) merchantability, fitness for a particular purpose, title, quiet enjoyment, or non-infringement; (b) any warranties arising in the course of dealing, use or trade; © any warranties or guarantees relating to availability, accuracy, error rates, system integrity or uninterrupted access. We do not warrant that: (i) the website or service will be secure or available at any particular time or location; (ii) any defects or errors will be corrected; (iii) any content on or available through the website or service or the software is free of viruses or other harmful components; (iv) the results of using the website or services will meet your requirements. Your use of the Site and Services is entirely at your own risk.',
          },
          {
            type: "p",
            text: "To the maximum extent permitted by law, we disclaim all liability for supplier products. Instead, if applicable, all limited warranties, disclaimers, limitations of liability, and other specific terms regarding Supplier's products can be found in the {platformName} Marketplace Terms and Conditions or (if applicable) in the agreement between you and the Supplier.",
          },
          {
            type: "p",
            text: "8.2 {platformName} is not responsible for the content you access through your use of the Website, Services or the content of other users of {platformName} or other third parties. To the extent permitted by applicable law, you release us from all liability related to such content. You acknowledge and agree that we make no representations about any content contained in or accessed through the Site or the content of other users of {platformName} or other third parties, and we are not responsible for the accuracy, quality, legality or other attributes of or Obligation to such content.",
          },
          {
            type: "p",
            text: "8.3 {platformName} does not disclaim any warranties or other rights that applicable law prohibits {platformName} from disclaiming.",
          },
          { type: "h4", text: "9. Limitation of Liability" },
          {
            type: "p",
            text: "9.1 To the fullest extent permitted by law, in no event shall we, whether based on warranties, contract, tort (including negligence), statute or any other legal theory, and whether we have been advised of the possibility of damages. To the fullest extent permitted by law, you agree that we are not responsible for any data that may be destroyed, lost or otherwise inaccessible, whether because of your failure to back up the data or for any other reason.",
          },
          {
            type: "p",
            text: "9.2 Each provision in these Terms of Service that sets forth the limitation of liability, disclaimer of warranty or exclusion of damages is intended and does distribute risk among the parties under these Terms of Service. This allocation is an essential element of the basis of the transaction between you and us. Each of these terms is severable and independent of all other terms of this TOS. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.",
          },
          { type: "h4", text: "10. Indemnity" },
          {
            type: "p",
            text: "To the fullest extent permitted by law, you shall be responsible for your use of the Site and Services, and you shall defend, indemnify and hold harmless us and our employees, officers, directors, agents, contractors and representatives from all Liability, Claims, and Expenses, Including Reasonable Attorneys' Fees and Costs Arising from or Related to Your Service Content, User Content, Your End Users, or Your Access to or Use of the Site and Services, including your breach of this TOS or applicable Law, willful misconduct, negligence, illegal activity, breach of security or data, unauthorized access or use of your account, or violation of third party rights, including any intellectual property, confidentiality, property or privacy rights. We reserve the right to the exclusive defense and control of any matter for which you are required to indemnify, without limiting your obligation to indemnify such matter, in which case you will use your best efforts to assist and cooperate with us in defending that matter at your own expense.",
          },
          { type: "h4", text: "11. Termination" },
          {
            type: "p",
            text: "While we would prefer to give earlier notice of termination, we reserve the right, in our sole discretion, to terminate your access to all or any part of the Site and/or Services at any time, with or without notice, effective immediately. Any such termination may result in the immediate forfeiture and destruction of data associated with your account. Unless otherwise provided in this Agreement or expressly agreed by the parties, any and all fees paid to us are non-refundable and any and all fees still owed to us at the time of termination shall be immediately due and payable. Upon termination, any and all rights granted to you by these Terms of Service will immediately cease and you must also immediately cease all use of the Site and/or Services.",
          },
          { type: "h4", text: "12. Miscellaneous Provisions" },
          {
            type: "p",
            text: `12.1 Updates and Modifications. {platformName} reserves the right to update, change or otherwise modify this TOS at any time in {platformName}'s sole discretion. If {platformName} updates this TOS, {platformName} may provide notice of such update, such as by sending an email notification to the email address listed in your account and/or updating the "last updated" date at the beginning of this TOS. Updates will take effect on the date specified in the notice. By continuing to access or use the Site or Services after the date specified in the notice or updated Terms of Service, you agree to accept and be bound by the updated Terms of Service and all terms contained therein. If you do not agree to the updated TOS,`,
          },
          {
            type: "p",
            text: '12.2 General. These Terms of Service, including all documents expressly incorporated herein by reference, constitute the entire agreement between the parties and supersede all prior and contemporaneous agreements, proposals or representations, written or oral, with respect to its subject matter. The use of section headings in this TOS is for convenience only and will not have any effect on the interpretation of any provision. Any use of the singular should reasonably be construed as if it also included the plural and vice versa. Any use of "including" or "for example" should be construed as "including but not limited to" if not expressly stated otherwise. If any part of this TOS is found to be invalid or unenforceable, the unenforceable part will be effective to the greatest possible extent and the remainder will remain in full force and effect.',
          },
          {
            type: "p",
            text: "12.3 Assignment. You may not assign, assign or delegate this TOS or any of your rights and obligations under the TOS, in whole or in part, by law or otherwise, without our prior written consent. We may assign, assign or delegate this TOS or our rights and obligations under this TOS without notice or consent. Subject to this section, this TOS will be binding on and inure to the benefit of the parties and their respective permitted successors and assigns, and any assignment or assignment in violation of this section will be void.",
          },
          {
            type: "p",
            text: "12.4 Consent to Electronic Communications. By using the Site and/or Services, you agree to receive certain electronic communications from us, as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communication practices. You agree that any notices, agreements, disclosures or other communications that we send you electronically will satisfy any legal communication requirements, including that such communications are in writing.",
          },
          {
            type: "p",
            text: "12.5 Relationship of the Parties. The parties are independent contractors. These Terms of Service do not create a partnership, franchise, joint venture, agency, trust or employment relationship between the parties. Neither party nor any of their respective affiliates is an agent of the other party and has no authority or authority to bind the other party for any purpose.",
          },
          {
            type: "p",
            text: "12.6 Force Majeure. If {platformName} is subject to any act or event beyond {platformName}'s reasonable control (including but not limited to acts of God, war, unrest or riot, strike, any action of a government entity, weather, quarantine, fire, flood, earthquake, explosion, utility or telecommunications outages, internet disruptions, epidemics, epidemics or any unforeseen change in circumstances, or any other cause beyond our reasonable control.",
          },
          {
            type: "p",
            text: "12.7 No Third Party Beneficiaries. This TOS does not create any third party beneficial rights to any person or entity that is not a party to this TOS.",
          },
          {
            type: "p",
            text: "12.8 Government Use. {platformName} provides the website and services, including related software and technology, for ultimate federal use only in accordance with the following: Government technical data and software rights associated with the website and services include only those rights customarily made available to the public, such as these Terms of Service.",
          },
          {
            type: "p",
            text: "Copyright 2020 {platformName}, LLC. all rights reserved. No part of the {platformName} website may be reproduced, modified or distributed in any form or by any means without the prior written permission of {platformName}.",
          },
        ],
      },
    },
  ],
};
