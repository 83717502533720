import { Divider } from "antd";
import type { CSSProperties } from "react";
import { useRef } from "react";
import React, { useMemo, useState } from "react";
import { useIntl, injectIntl } from "react-intl";
import CheckItem from "./CheckItem";
import "./index.less";
type CheckFunction = (value: string) => boolean;

type rule = {
  reg: RegExp | CheckFunction;
  text: string | React.ReactNode;
  required?: boolean;
  errorReg?: RegExp | CheckFunction;
  errorText?: string | React.ReactNode;
};

interface VerifyPwdProps {
  value: string;
  rules: rule[];
  visible: boolean;
  style?: CSSProperties;
  checkCount?: number; // 需要通过的条件数量
}

const Verify: React.FC<VerifyPwdProps> = (props) => {
  const intl = useIntl();
  const ref = useRef<any>();
  const { visible, value = "", rules, style, checkCount = 0 } = props;
  const [aggregate, setAggregate] = useState(false);

  const { list: checklist, errorList } = useMemo(() => {
    let count = 0;
    let hasRequiredFail = false;
    const errorTexts: any[] = [];
    const list: {
      required?: boolean;
      validated: boolean;
      text: string | React.ReactNode;
      error?: boolean;
    }[] = [];
    rules.forEach((item) => {
      const validated =
        typeof item.reg === "function" ? item.reg(value) : item.reg.test(value);
      let error = false;
      if (item.errorReg) {
        error =
          typeof item.errorReg === "function"
            ? item.errorReg(value)
            : item.errorReg?.test(value);
      }

      if (item.required === true && !validated) {
        hasRequiredFail = true;
      }
      if (validated) {
        count++;
      }
      if (error) {
        count = -999;
        if (item?.errorText) {
          errorTexts.push(item?.errorText);
        }
      }
      list.push({
        validated,
        text: item.text,
        error,
        required: item.required,
      });
    });
    setAggregate(count >= checkCount && !hasRequiredFail);
    return { list, errorList: errorTexts };
  }, [checkCount, rules, value]);

  return (
    <div
      style={style}
      className={`kpy-verify ${visible ? "kpy-verify-show" : ""}`}
    >
      <div ref={ref}>
        <CheckItem
          validated={aggregate}
          error={checklist.some((item: any) => item.error)}
          text={
            <>
              {intl.formatMessage({ id: "register.from.password.checkRule" })}
              {intl.formatMessage({ id: "component.verify.checkRule.unit" })}
            </>
          }
        />
        <Divider style={{ margin: "5px 0" }} dashed />
        {checklist
          .filter((item) => item.required)
          .map((v, i: number) => {
            return (
              <CheckItem
                validated={v.validated}
                text={v.text}
                key={i}
                error={v.error}
              />
            );
          })}
      </div>
      {errorList.length > 0 && (
        <div
          style={{ width: ref.current?.clientWidth }}
          className="kpy-verify-error-list"
        >
          <Divider style={{ margin: "5px 0" }} />
          {errorList.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Verify;
