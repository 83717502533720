import footer from "./Footer/ja-JP.json";
import header from "./Header/ja-JP.json";
import customer from "./customer/ja-JP.json";
import support from "./Support/ja-JP.json";
import bottom from "./Bottom/ja-JP.json";
import nodeMap from "./NodeMap/ja-JP.json";
import Accordion from "./Accordion/ja-JP.json";
export default {
  ...footer,
  ...header,
  ...customer,
  ...support,
  ...bottom,
  ...nodeMap,
  ...Accordion,
};
