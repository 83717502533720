import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { useMemo } from "react";
import "./index.less";
interface CheckItemProps {
  validated: boolean;
  text: string | React.ReactNode;
  error?: boolean;
}

const CheckItem = (props: CheckItemProps) => {
  const { validated, text, error } = props;
  const logo = useMemo(() => {
    if (error) {
      return <CloseCircleFilled className="error" style={{ fontSize: 16 }} />;
    }
    if (validated) {
      return <CheckCircleFilled style={{ color: "#52c41a", fontSize: 16 }} />;
    }
    return <CheckCircleFilled style={{ color: "#C6B5BD", fontSize: 16 }} />;
  }, [error, validated]);
  return (
    <div>
      {logo}
      <span
        style={{
          marginLeft: "10px",
        }}
      >
        {text}
      </span>
    </div>
  );
};
export default CheckItem;
