import { get } from "../get";
import { post } from "../post";
import { put } from "../put";
import { commonApi, userApi } from "../urlconfig";

// 公共信息
export function websiteinfo_api(path?: string) {
  return get(`${path ? path : "/"}${commonApi}/website/info.do`);
}
// 公共信息
export function getAccount_api(path?: string) {
  return get(`${path ? path : "/"}userApi/user/getUserInfoNew.do`);
  // user/getUserInfoNew.do
}
// 登出
export function getLogout(params?: any) {
  return get(`/${userApi}/logout.do`, params);
}
// 重置密码
export const checkUsernameAndPassword = (data: {
  userPwd: string;
  userName: string;
}) => {
  return post("/userApi/portal/checkUserNameAndPassWord.do", data);
};

// 重置密码
export const resetPassword = (data: {
  newUserPwd: string;
  oldUserPwd: string;
  salt: string;
}) => {
  return post("/userApi/user/v2/updateUserPwd.do", data);
};

// 获取工单列表
export const fetchRecharge = (params: any) => {
  return get("/api/payment-app/v1/recharge/self", params);
};

export function checkCaptcha(data: any): Promise<any> {
  return post("/api/support/v1/captcha/checkCaptcha.do", data);
}

export function getAppKey(params: any): Promise<any> {
  return get("/api/support/v1/captcha/getAppKey.do", params);
}

// 官网订阅邮箱
export const putWebsiteSubscribe_api = (data: {
  email: string;
  domainId: number;
}) => {
  return put(
    `/userApi/portal/website/subscribe?email=${data?.email}&domainId=${data?.domainId}`,
    data
  );
};
