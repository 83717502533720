import footer from "./Footer/zh-CN.json";
import header from "./Header/zh-CN.json";
import customer from "./customer/zh-CN.json";
import support from "./Support/zh-CN.json";
import bottom from "./Bottom/zh-CN.json";
import nodeMap from "./NodeMap/zh-CN.json";
import Accordion from "./Accordion/zh-CN.json";
export default {
  ...footer,
  ...header,
  ...customer,
  ...support,
  ...bottom,
  ...nodeMap,
  ...Accordion,
};
