// 邮箱正则
export const emailPattern =
  /^[\da-zA-Z]+([\-\.\_]?[\da-zA-Z])*@[\da-zA-Z]+([\-\.]?[\da-zA-Z])*([\.]?[a-zA-Z]{1,4})$/;

export const mobilePattern =
  /(nokia|iphone|android|ipad|motorola|^mot\-|softbank|foma|docomo|kddi|up\.browser|up\.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam\-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte\-|longcos|pantech|gionee|^sie\-|portalmmm|jig\s browser|hiptop|^ucweb|^benq|haier|^lct|opera\s*mobi|opera\*mini|320x320|240x320|176x220)/i;

// 密码正则 至少包含大小写字母、数字、特殊符号的8-20位三种及以上组合
export const pwdPattern =
  /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z_\W]+$)(?![a-z0-9]+$)(?![a-z_\W]+$)(?![0-9_\W]+$)[a-zA-Z0-9_\W]{8,20}$/;
// 不包含非法字符或中文(\\，/，:，*，?，"，<，>，|)
// eslint-disable-next-line no-useless-escape
export const chartPattern = /^[^\\\/\:\*\?"\<\>\|\u4e00-\u9fa5]*$/;
/**
 *校验密码是否合格
 *
 * @param {*} text
 * @returns
 */
export function checkPwd(text: string) {
  let lowerCase = false;
  let digit = false;
  let upperCase = false;
  let otherChars = false;
  let blankChars = false;
  const specStr = "()`~!@#$*-+={}[]:;,.?/";
  const charAry = text.split("");
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < charAry.length; i++) {
    // 包含小写字母
    if (/^[a-z]$/.exec(charAry[i])) {
      lowerCase = true;
      // eslint-disable-next-line no-continue
      continue;
    }
    // 包含大写字母
    if (/^[A-Z]$/.exec(charAry[i])) {
      upperCase = true;
      // eslint-disable-next-line no-continue
      continue;
    }
    // 包含数字或特殊字符
    if (/^[0-9]$/.exec(charAry[i])) {
      digit = true;
      // eslint-disable-next-line no-continue
      continue;
    }
    if (specStr.indexOf(charAry[i]) > -1) {
      continue;
    }
    otherChars = true;
    if (charAry[i] === " ") {
      blankChars = true;
    }
  }
  if (
    otherChars ||
    !(lowerCase && upperCase && digit) ||
    text.length < 8 ||
    text.length > 30 ||
    blankChars
  ) {
    return false;
  }
  return true;
}
