import component from "./components/ru-RU";
import home from "./home/ru-RU.json";
import aboutUs from "./aboutUs/ru-RU.json";
import termsService from "./termsService/ru-RU.json";
import product from "./Product/ru-RU.json";
import privacyPolicy from "./privacyPolicy/ru-RU.json";
import content from "./content/ru-RU.json";
import contentDetail from "./contentDetail/ru-RU.json";
import advert from "./advert/ru-RU.json";
import advertNew from "./advertNew/ru-RU.json";
import speed from "./speed/ru-RU.json";
import southeastaisa from "./southeastasia/ru-RU.json";
import cocreate from "./cocreate/ru-RU.json";
import contentData1 from "@/models/privacyPolicy/data";
import contentData2 from "@/models/termsService/data";
import { nodeMapData } from "@/utils/nodeData";

const privacyPolicyData: any = {};
const termsServiceData: any = {};
const nodeData: any = {};

contentData1?.content?.forEach((v: any, i: number) => {
  privacyPolicyData[`pages.privacyPolicy.content.title${i + 1}`] =
    v.title?.["en-US"];
  v?.text?.["en-US"]?.forEach((m: any, j: number) => {
    if (typeof m === "string") {
      privacyPolicyData[`pages.privacyPolicy.content.text${i + 1}-${j + 1}`] =
        m;
    } else {
      m.forEach((n: string, k: number) => {
        privacyPolicyData[
          `pages.privacyPolicy.content.text${i + 1}-${j + 1}-${k + 1}`
        ] = n.indexOf("●") === -1 ? n : n.slice(1, n.length);
      });
    }
  });
});

contentData2.content.forEach((v: any, i: number) => {
  termsServiceData[`pages.termsService.content.title${i + 1}`] =
    v.title?.["en-US"];
  v?.text?.["en-US"]?.forEach((m: any, j: number) => {
    termsServiceData[`pages.termsService.content.text${i + 1}-${j + 1}`] =
      m.text;
  });
});

nodeMapData?.forEach((v: any) => {
  v?.introc?.["en-US"]?.forEach((m: any, j: number) => {
    nodeData[`pages.speed.${v.text}.introc${j}`] = m;
  });
});

export default {
  "hello.title": "Добро пожаловать",
  "hello.message": "Привет, мир!",
  "404.subTitle": "Извините, страница, которую вы посетили, не существует.",
  "404.Back": "Вернуться на главную",
  "404.BackCocreate": "Вернуться к плану совместного творчества",
  "500.subTitle": "Извините, что - то не так.",
  ...component,
  ...home,
  ...aboutUs,
  ...termsService,
  ...product,
  ...privacyPolicy,
  ...content,
  ...contentDetail,
  ...advert,
  ...advertNew,
  ...speed,
  ...southeastaisa,
  ...cocreate,
  ...privacyPolicyData,
  ...termsServiceData,
  ...nodeData,
};
